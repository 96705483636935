import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style

Vue.use(VueRouter);

import appLayout from '@/layout/Index';
import EmptyLayout from '@/layout/Empty';

// 静态路由
export const constantRoutes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/',
        redirect: '/dashboard',
        component: appLayout,
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('@/views/Index.vue'),
                meta: {
                    title: '首页'
                }
            },
            {
                path: 'personal',
                component: EmptyLayout,
                redirect: '/personal/setting',
                meta: {
                    title: '个人中心',
                    breadcrumb: false
                },
                children: [
                    {
                        path: 'setting',
                        name: 'personalSetting',
                        component: () => import('@/views/personal/Setting'),
                        meta: {
                            title: '个人设置'
                        }
                    },
                    {
                        path: 'edit/password',
                        name: 'personalEditPassword',
                        component: () => import('@/views/personal/edit.password'),
                        meta: {
                            title: '修改密码'
                        }
                    }
                ]
            },
            {
                path: 'reload',
                name: 'reload',
                component: () => import('@/views/Reload')
            }
        ]
    }
];

// import systemRoutes from './modules/system';
import member from './modules/member';
import student from './modules/student';
import course from './modules/course';
import exam from './modules/exam';
import datum from './modules/datum';
import finance from './modules/finance';
import business from './modules/business';
import article from './modules/article';
import invoice from './modules/invoice';
import points from './modules/points';
import order from './modules/order';
import lottery from './modules/lottery';
import qrCode from './modules/qrCode';

// 当 children 不为空的主导航只有一项时，则隐藏
export const asyncRoutes = [
    {
        meta: {
            title: '功能',
            icon: 'sidebar-default'
        },
        children: [            
            member,
            // student,
            order,
            course,
            invoice,
            exam,
            // datum,
            // finance,
            // business,
            points,
            lottery,
            qrCode,
            article,
            // systemRoutes
        ]
    }
];

// 404
export const notFountRouter = [{
    path: '*',
    component: () => import('@/views/404'),
    meta: {
        title: '404',
        sidebar: false
    }
}];

const router = new VueRouter({
    mode: 'history',
    routes: constantRoutes
});

// 解决路由在 push/replace 了相同地址报错的问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
};

/**
 * @ 重置路由
 * 细节见: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
 */
export const resetRouter = () => {
    router.matcher = new VueRouter({
        routes: constantRoutes
    }).matcher;
};

router.beforeEach(async(to, from, next) => {
    NProgress.start();
    // console.log(store.getters['user/isLogin'], store.state.permission.isGenerate);
    // 已经登录，但还没根据权限动态挂载路由
    if (store.getters['user/isLogin'] && !store.state.permission.isGenerate) {
        // 从router/index.js中导出
        resetRouter();

        // 生成用户路由
        const accessRoutes = await store.dispatch('permission/generateRoutes', {
            asyncRoutes, 
            currentPath: to.path
        });
        
        accessRoutes.push(...notFountRouter);
        // console.log('111: ', accessRoutes);
        // 动态添加路由
        accessRoutes.forEach(route => {
            router.addRoute(route);
        });
        next({ ...to, replace: true });
    }

    // 设置网页 title
    to.meta.title && store.commit('app/setTitle', to.meta.title);

    if (store.getters['user/isLogin']) {
        if (to.name) {
            if (to.matched.length !== 0) {
                // 如果已登录状态下，进入登录页会强制跳转到控制台页面
                if (to.name === 'login') {
                    next({
                        name: 'dashboard',
                        replace: true
                    });
                } else if (!store.state.app.enableDashboard && to.name === 'dashboard') {
                    // 如果未开启控制台页面，则默认进入侧边栏导航第一个模块，但如果侧边栏导航没有模块，则还是进入控制台页面
                    if (store.getters['permission/sidebarRoutes'].length > 0) {
                        next({
                            name: store.getters['permission/sidebarRoutes'][0].name,
                            replace: true
                        });
                    }
                }
            } else {
                // 如果是通过 name 跳转，并且 name 对应的路由没有权限时，需要做这步处理，手动指向到 404 页面
                next({
                    path: '/404'
                });
            }
        }
    } else {
        if (to.name !== 'Login') {
            next({
                name: 'Login',
                query: {
                    redirect: to.fullPath
                }
            });
        }
    }

    next();
});

router.afterEach(() => {
    NProgress.done();
});

export default router;
