/**
 * 考试管理 api 定义
 */

import $http from '@/plugins/axios';

// 获取考题列表
export const getExamListApi = (params) => {
    return $http({
        url: '/admin/getExamQuestionList.html',
        method: 'GET',
        params
    });
};

// 获取考题分类列表
export const getExamCatApi = (params) => {
    return $http({
        url: '/admin/getQuestionCatList.html',
        method: 'GET',
        params
    });
};

// 添加考题
export const addExamQuestionApi = (data) => {
    return $http({
        url: '/admin/addExamQuestion.html',
        method: 'POST',
        data
    });
};

// 编辑考题
export const editExamQuestionApi = (data) => {
    return $http({
        url: '/admin/editExamQuestion.html',
        method: 'POST',
        data
    });
};

// 删除考题
export const deleteExamQuestionApi = (params) => {
    return $http({
        url: '/admin/delExamQuestion.html',
        method: 'GET',
        params
    });
};

// 获取答案列表
export const getExamAnswerListApi = (params) => {
    return $http({
        url: '/admin/getExamAnswerList.html',
        method: 'GET',
        params
    });
};

// 添加答案
export const saveExamAnswerApi = (data) => {
    return $http({
        url: '/admin/saveExamAnswer.html',
        method: 'POST',
        data
    });
};


// 获取用户评测列表
export const getUserRevaluationListApi = (params) => {
    return $http({
        url: '/admin/getUserTrainList.html',
        method: 'GET',
        params
    });
};

// 上传excel
export const importExamExcelApi = (data) => {
    return $http({
        url: '/admin/uploadQuestion.html',
        method: 'POST',
        data
    });
};