/**
 * 积分管理 api 定义
 */

import $http from '@/plugins/axios';

// 获取积分列表
export const getPointsListApi = (params) => {
    return $http({
        url: '/admin/getUserPointsList.html',
        method: 'GET',
        params
    });
};

// 获取积分详情
export const getPointsDetailApi = (params) => {
    return $http({
        url: '/admin/getUserPointsDetailList.html',
        method: 'GET',
        params
    });
};