import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Axios from './plugins/axios';

// 引入element ui
import './plugins/element';

// 引入全局样式
import './assets/styles/globals.scss';

// 引入svg
import './plugins/svg';

// 自动全局注册components中的组件
import './components/autoRegister';

// 全局指令
import directives from './directives';

// 引入cookie
import cookies from 'vue-cookies';

import { parseTime } from '@/utils';

// 全局引入axios
Vue.prototype.$axios = Axios;

// 引入vue-meta，在App.vue中设置
import VueMeta from 'vue-meta';
Vue.use(VueMeta);

Vue
    .use(directives)
    .use(cookies);

Vue.config.productionTip = false;

Vue.prototype.parseTime = parseTime;

Vue.prototype.$eventBus = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
