<template>
    <div id="appLayout">
        <div id="appLayoutMaster" :style="{'--real-sidebar-width': realSidebarWidth}">
            <!-- / header BEGIN / -->
            <header id="appHeader" v-if="$store.state.app.showHeader">
                <LayoutHeader />
            </header>
            <!-- / header END / -->

            <div id="appWrapper">
                <!-- / side BEGIN / -->                
                <LayoutSide />
                <!-- / side END / -->

                <div id="appAsideMask"></div>
                
                <!-- / main BEGIN / -->
                <div id="appMain">
                    <Topbar :class="{'shadow': scrollTop}" />
                    <div class="app-main__content">
                        <transition name="main" mode="out-in">
                            <keep-alive v-if="isRouterAlive" :include="$store.state.keepAlive.list">
                                <router-view :key="$route.path" />
                            </keep-alive>
                        </transition>
                    </div>
                    <Copyright v-if="$store.state.app.showCopyright" style="margin-top:20px;" />
                </div>
                <!-- / main END / -->
            </div>

            <el-backtop :right="20" :bottom="20" title="回到顶部" />
        </div>

        <!-- / 引入全局组件（全屏、搜索、设置...） / -->
        <Search />

        <ThemeSetting />
    </div>
</template>

<script>
import scssVariables from '@/assets/styles/resources/variables.scss';

import LayoutHeader from './components/LayoutHeader';
import LayoutSide from './components/LayoutSide';
import Topbar from './components/Topbar';
import Search from './components/Search';
import ThemeSetting from './components/ThemeSetting';

export default {
    name: 'appLayout',

    provide() {
        return {
            reload: this.reload
        };
    },

    components: {
        LayoutHeader,
        LayoutSide,
        Topbar,
        Search,
        ThemeSetting
    },

    computed: {
        variables() {
            return scssVariables;
        },

        realSidebarWidth() {
            let realWidth = 0;

            if (!this.$store.state.app.showHeader && (this.$store.state.permission.routes.length > 1 || this.$store.state.app.alwaysShowMainSidebar)) {
                realWidth = parseInt(scssVariables.g_main_sidebar_width);
            }

            if (this.$store.state.app.sidebarCollapse) {
                realWidth += 64;
            } else {
                realWidth += parseInt(scssVariables.g_sub_sidebar_width);
            }

            return `${realWidth}px`;
        }
    },

    data() {
        return {
            isRouterAlive: true,
            routePath: '',
            scrollTop: 0
        };
    },

    methods: {
        reload(type = 1) {
            if (type === 1) {
                this.isRouterAlive = false;
                this.$nextTick(() => (this.isRouterAlive = true));
            } else {
                this.$router.push({
                    name: 'reload'
                });
            }
        },

        onScroll() {
            this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        }
    },

    mounted() {
        window.addEventListener('scroll', this.onScroll);
    }
};
</script>

<style lang="scss" scoped>
#appLayout {
    height: 100%;
}

#appLayoutMaster {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    transition: all 0.2s;
}

#appHeader {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    height: $g-header-height;
    background-color: $g-header-bg;
    color: $g-header-menu-color;

    ::v-deep {
        .app-logo {
            position: relative;
            width: inherit;
            height: inherit;
            padding: inherit;
            background-color: inherit;
            .name {
                font-size: 24px;
                letter-spacing: 1px;
            }
        }

        .app-toolbar {
            padding: 0;
            .user {
                color: #fff;
            }
        }
    }
}

#appWrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

#appAsideMask {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000, $alpha: 0.5);
    backdrop-filter: blur(2px);
    transition: all 0.2s;
    transform: translateZ(0);
    opacity: 0;
    visibility: hidden;
}

#appMain {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    margin-left: $g-sidebar-width;
    background-color: #f5f7f9;
    box-shadow: 1px 0 0 0 darken($g-main-bg, 10);
    transition: 0.3s;
    .app-main__content {
        height: 100%;
        flex: auto;
        position: relative;
        padding: $g-topbar-height 0 0;
        overflow: hidden;
    }
}

#appHeader + #appWrapper {
    padding-top: $g-header-height;
    ::v-deep #appAside {
        top: $g-header-height;
        .app-logo {
            display: none;
        }
        .el-menu {
            padding-top: 0;
        }
    }
    #appMain {
        .app-main__header {
            top: $g-header-height;
            ::v-deep .app-toolbar {
                display: none;
            }
        }
    } 
}

// 主内容区动画
.main-enter-active {
    transition: all 0.2s;
}
.main-leave-active {
    transition: all 0.15s;
}
.main-enter {
    opacity: 0;
    margin-left: -20px;
}
.main-leave-to {
    opacity: 0;
    margin-left: 20px;
}
</style>
