<template>
    <Layout title="抽奖设置">
        <el-card class="custom-card__outer" shadow="never">
            <div class="el-card__header-flex" slot="header">
                <el-form :model="queryForm" inline size="small">
                    <el-form-item>
                        <el-input v-model="queryForm.prizeName" placeholder="请输入名称" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleQuery">查询</el-button>
                    </el-form-item>
                </el-form>

                <el-button type="primary" size="small" icon="el-icon-plus" @click="handleAdd">添加抽奖活动</el-button>
            </div>

            <el-table v-loading="loading" :data="tableData" style="width: 100%" size="large">
                <el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
                <el-table-column prop="prizeName" label="抽奖活动主题"></el-table-column>
                <el-table-column label="活动有效期">                    
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.effectiveType === 0">长期有效</el-tag>
                        <el-link type="primary" v-else>
                            {{ parseTime(scope.row.effectiveStartTime) }} - {{ parseTime(scope.row.effectiveEndTime) }}
                        </el-link>
                    </template>
                </el-table-column>
                <el-table-column label="中奖概率" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.probability }}%
                    </template>
                </el-table-column>
                <el-table-column prop="note" label="备注"></el-table-column>
                <el-table-column fixed="right" label="操作" width="300" align="center">
                    <template slot-scope="scope">
                        <el-button 
                            type="primary" 
                            icon="el-icon-edit" 
                            size="mini" 
                            @click.native="handlePrizeSetting(scope.row)"
                        >设置奖项</el-button>
                        <el-button 
                            type="primary" 
                            icon="el-icon-edit" 
                            size="mini" 
                            @click.native="handleEdit(scope.row)"
                        >编辑</el-button>
                        <el-button 
                            type="danger" 
                            icon="el-icon-delete" 
                            size="mini" 
                            @click.native="handleDelete(scope.row)"
                        >删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="el-card__footer">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :total="totalCount"
                    @current-change="handleCurrentPageChange"
                ></el-pagination> 
            </div>
        </el-card>

        <!-- 抽奖活动 -->
        <ModifyPrize v-if="isLoadPrizeDrawer" @updateFn="getData" />

        <!-- 抽奖活动 -->
        <ModifyPrizeItem v-if="isLoadPrizeSettingItemDrawer" @updateFn="getData" />
    </Layout>
</template>

<script>
import ModifyPrize from './components/ModifyPrize.vue';
import ModifyPrizeItem from './components/ModifyPrizeItem.vue';
import { api_getPrizeList, api_deletePrize } from '@/api/lottery';
import { parseTime } from '@/utils';

export default {
    data() {
        return {
            loading: true,

            queryForm: {
                prizeName: '',
                pageSize: 10,
                currentPage: 1
            },
            
            tableData: [],
            totalCount: 1,

            isLoadPrizeDrawer: false,
            isLoadPrizeSettingItemDrawer: false
        };
    },

    components: {
        ModifyPrize,
        ModifyPrizeItem
    },

    methods: {
        getData(params, isClearData) {
            this.loading = true;

            api_getPrizeList(this.queryForm).then(res => {
                this.loading = false;

                if (res.code === 0) {
                    const { allNum, prizeList } = res.data;

                    this.tableData = prizeList;
                    this.totalCount = allNum;
                } else {
                    this.loading = false;
                    this.$message({
                        type: 'danger',
                        message: res.msg
                    });
                }
            });
        },

        // 分页
        handleCurrentPageChange(e) {
            this.queryForm.currentPage = e;
            this.getData();
        },

        // 查询
        handleQuery() {
            this.getData();
        },

        // 添加
        handleAdd() {
            this.isLoadPrizeDrawer = true;
            setTimeout(() => {
                this.$eventBus.$emit('OPEN_PRIZE_DRAWER');
            });
        },

        // 编辑
        handleEdit(rowData) {
            this.isLoadPrizeDrawer = true;
            setTimeout(() => {
                this.$eventBus.$emit('OPEN_PRIZE_DRAWER', rowData);
            });
        },

        // 删除
        handleDelete(rowData) {
            const { prizeId } = rowData;

            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                api_deletePrize({ prizeId }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '操作成功！'
                    });
                    this.getData();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },

        // 设置奖项
        handlePrizeSetting(rowData) {
            this.isLoadPrizeSettingItemDrawer = true;
            setTimeout(() => {
                this.$eventBus.$emit('OPEN_PRIZE_ITEM_SETTING_DRAWER', rowData);
            });
        }
    },

    mounted() {
        this.getData();
    }
};
</script>

<style lang="scss" scoped>
.custom-card__inner {
    .el-card__header-flex {
        ::v-deep {
            .el-button {
                margin-right: 10px;
            }
        }
    }
    .flex {
        display: flex;
        align-items: center;
    }
}
</style>
