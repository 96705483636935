<template>
    <el-upload
        class="avatar-uploader"
        action="#"
        :show-file-list="false"
        :http-request="httpUpload"
        :before-upload="beforeAvatarUpload"
    >
        <img v-if="imgUrl" :src="imgUrl" class="avatar" />
        <span class="el-icon" v-else>
            <i class="el-icon-plus"></i>
        </span>
    </el-upload>
</template>

<script>
import { getCourseCoverUploadParam } from '@/api/course';

export default {
    name: 'UploadImage',

    props: {
        imgSrc: {
            type: String
        },
        handleSuccess: {
            type: Function
        }
    },

    data() {
        return {
            imgUrl: '',
            uploadLookUrl:"",
            uploadBaseLookUrl:""
        };
    },

    watch: {
        imgSrc(val) {
            this.imgUrl = val;
        }
    },

    mounted() {
        this.imgUrl = this.imgSrc;
    },

    methods: {
        // 图片上传之前
        beforeAvatarUpload(rawFile) {
            const imgTypes = ['image/jpeg', 'image/png', 'image/jpg'];

            if (!imgTypes.includes(rawFile.type)) {
                // ElMessage.error('Avatar picture must be JPG format!')
                this.$message({
                    type: 'danger',
                    message: '请上传PNG、JPG图片'
                });

                return false;
            }
            return true;
        },

        // 自定义上传图片
        httpUpload(params) {
            this.loadingIns = this.$loading({
                text: '图片上传中...',
                background: 'rgba(0, 0, 0, .2)'
            });


            getCourseCoverUploadParam({ fileName: params.file.name }).then(res => {
                const resData = res.data;
                console.log(resData);
                // 获取参数成功后，调用腾讯云接口
                // https://1-1317373106.cos.ap-chengdu.myqcloud.com
                this.uploadLookUrl = resData.LookUrl;
                this.uploadBaseLookUrl = resData.BaseLookUrl;

                fetch(resData.UploadUrl, {
                    method: 'PUT',
                    body: params.file,
                    headers: {
                        'Host': resData.Host,
                        'Authorization': resData.Authorization
                    }
                }).catch(res => {
                    this.$message({
                        type: 'success',
                        message: `上传失败`
                    });
                    this.loadingIns.close();
                }).then(res => {
                    this.$emit('handleSuccess', {
                        url: this.uploadLookUrl, 
                        uploadBaseLookUrl: this.uploadBaseLookUrl, 
                        ...resData
                    });
                    this.loadingIns.close();
                });
            }).catch(e => {
                this.loadingIns.close();
            });
        }
    }
};
</script>
<style scoped lang="scss">
img {
    max-width: 100%;
}

.avatar-uploader {
    ::v-deep .el-upload {
        border: 1px dashed #8c939d;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: all .3s;
        &:hover {
            border-color: #66b1ff;
        }
    }
}

.el-icon {
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
}
</style>
