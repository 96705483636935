/**
 * @ 用户相关
 */

import { 
    loginApi,
    getUserInfoApi
    // api_getMenus 
} from '@/api/user';
import { deepClone } from '@/utils';
import cookies from 'vue-cookies';

export default {
    namespaced: true,

    state: {
        userInfo: {},
        token: localStorage.token || '',
        expires: localStorage.expires || '',
        permissions: [
            'index:add',
            'index:edit'
        ]
    },

    mutations: {
        setUserData(state, data) {
            const { token, expires, userInfo } = data;
            // console.log('aa: ', userName, avatar, token, expire);
            localStorage.setItem('userInfo', userInfo);
            localStorage.setItem('__token', token);
            localStorage.setItem('expires', expires);
            state.userInfo = userInfo;
            state.token = token;
            state.expires = expires;
        },

        // 保存用户路由
        setUserRoutes(state, userRoutes) {
            state.userRoutes = deepClone(userRoutes);
        },

        removeUserData(state) {
            localStorage.removeItem('userInfo');
            localStorage.removeItem('__token');
            localStorage.removeItem('expires');
            state.userInfo = '';
            state.token = '';
            state.expires = '';
        },

        setPermissions(state, permissions) {
            state.permissions = permissions;
        }
    },

    actions: {
        login({ commit }, data) {
            return new Promise((resolve, reject) => {
                // 通过 mock 进行登录
                loginApi(data).then(res => {
                    if (res.code === 0) {
                        // 将token写入cookie
                        const { token } = res.data;                        
                        cookies.set('token', token);

                        commit('setUserData', res.data);
                        resolve();
                    }
                }).catch(error => {
                    reject(error);
                });
            });
        },

        // 获取用户权限列表
        getPermission({ commit }) {
            /* return new Promise((resolve, reject) => {
                // TODO 先写死，后期调用接口
                const permissionMenu = {
                    menus: [
                        {
                            code: 'user',
                            children: [ 
                                { code: 'user:index' } 
                            ] 
                        },
                        {
                            code: 'menu',
                            children: [ 
                                { code: 'menu:index' } 
                            ] 
                        },
                        {
                            code: 'role',
                            children: [ 
                                { code: 'role:index' } 
                            ] 
                        },
                        {
                            code: 'logs',
                            children: [ 
                                { code: 'logs:index' } 
                            ] 
                        },
                        {
                            code: 'learnByPicture',
                            children: [
                                { code: 'learnByPicture:index' },
                                { code: 'learnByPicture:modify' }
                            ]
                        }
                    ],
                    permissions: [
                        'index:add',
                        'index:delet'
                    ]
                };

                const { menus, permissions } = permissionMenu;
                commit('setPermissions', permissions);
                commit('setUserRoutes', menus);

                resolve(menus);
            });
 */
            return new Promise((resolve, reject) => {
                getUserInfoApi().then(res => {
                    // console.log(res);
                    if (res.code === 0) {
                        const { menus, permissions } = res.data;
                        commit('setPermissions', permissions);
                        commit('setUserRoutes', menus);

                        resolve(menus);
                    } else {
                        reject(res);
                    }
                }).catch(error => {
                    reject(error);
                });
            });
        },
    
        logout({ commit }) {
            commit('removeUserData');
            commit('permission/invalidRoutes', null, { root: true });
        }
    },

    getters: {
        isLogin: state => {
            let loginState = false;
            // console.log('state.token: ', state.token);
            if (state.token) {
                const timeStamp = Date.parse(new Date());
                // console.log(timeStamp, state.expires * 10000000);
                if (timeStamp < state.expires * 100000000) {
                    loginState = true;
                }
            }
            return loginState;
        }
    }
};
