<template>
    <Layout title="学员信息">    
        <template #extend>
            <el-button @click="handleReturn">返回上一页</el-button>
        </template>    

        <el-card class="custom-card__outer">
            <el-tabs v-model="activeName" @tab-click="handleTabClick">
                <el-tab-pane label="基本信息" name="first">
                    <Basic />
                </el-tab-pane>
                <el-tab-pane label="关联账号" name="second">
                    <Relative />
                </el-tab-pane>
                <el-tab-pane label="本人创建账号" name="third">本人创建账号</el-tab-pane>
                <el-tab-pane label="培训记录" name="fourth">
                    <Records />
                </el-tab-pane>
                <el-tab-pane label="证书" name="five">
                    <Certificate />
                </el-tab-pane>
            </el-tabs>
        </el-card>

    </Layout>
</template>

<script>
import Basic from './components/Basic.vue';
import Relative from './components/Relative.vue';
import Records from './components/Records.vue';
import Certificate from './components/Certificate.vue';

export default {
    components: {
        Basic,
        Relative,
        Records,
        Certificate
    },

    data() {
        return {
            activeName: 'first'
        };
    },

    mounted() {
        
    },

    methods: {
        handleReturn() {
            this.$router.go(-1);
        },

        handleTabClick() {

        }
    }
};
</script>
