/** 
 * @ 封装axios，对外提供单独的get和post方法
 * @ author: leihao
 * @ createTime: 2021/07/21
 */

// import store from '@/store';
import axios from 'axios';

import { requestHeadersTokenKey, localTokenKey } from '@/setting';
import router from '@/router';
import store from '@/store';

// 引入element
import { Message } from 'element-ui';
 
/**
 * @ 创建一个axios实例
 */
const service = axios.create({
    // url = api url + request url
    baseURL: process.env.VUE_APP_API_BASEURL, 

    // 当跨域请求时发送cookie
    withCredentials: true, 

    // 请求超时时间(单位毫秒)
    timeout: 5000 
});

/**
 * @ 全局设置 - post请求头
 */
// service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

/**
 * @ 请求拦截器 - 在发送请求之前
 */
service.interceptors.request.use(config => {
    // 为所有的请求添加token
    const _token = localStorage.getItem(localTokenKey);
    // console.log('token: ', _token);
    // console.log('request config: ', config.data);
    if (_token) {
        config.headers[requestHeadersTokenKey] = _token;
    }

    // 所有接口统一添加时间戳，防止后端校验网络攻击
    // const data = config.data;
    // config.data = Object.assign({}, data, {
    //     t: +new Date()
    // });
    
    return config;
}, error => {
    // 处理请求错误
    console.log(error); // for debug
    return Promise.reject(error);
});

/**
 * @ 响应拦截器 - 统一规范返回的数据
 * @ 通过自定义代码确定请求状态
 * @ 您还可以通过HTTP状态码来判断状态
 */
let interceptorsLock = false; // 拦截器锁，防止弹出多个对话框
service.interceptors.response.use(response => {
    // 获取统一返回结果
    const res = response.data;
    
    // 提取code，做判断
    const { code } = res;

    // 如果已经锁止，则直接走异常
    if (interceptorsLock) {
        return Promise.reject(res);
    }

    // 如果code为200，表示成功，则直接放回数据。
    if (code === 0) {
        return res;
    } else {    
        switch (code) {
            case 10001: // 未登陆
                interceptorsLock = true;
                Message({
                    type: 'error',
                    title: '提示',
                    showClose: false,
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    message: '当前用户登入信息已失效，请重新登入再操作',
                    onClose: (action, instance, done) => {   
                        store.commit('user/removeUserData'); // 清楚本地信息          
                        router.replace({ name: 'Login' });
                        interceptorsLock = false;
                    }
                });
                break;

            default:
                Message({
                    message: res.msg || 'Error',
                    type: 'error',
                    duration: 5 * 1000
                });
                break;
        }

        // 返回错误 走 catch 
        return Promise.reject(res);
        // return Promise.reject(new Error(res.msg || 'Error'));
        // return res;
    }
}, error => {
    console.log('err' + error); // for debug
    Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
    });
    return Promise.reject(error);
});
 
/**
 * @ 对外暴露方法
 */
export default service;
