<template>
    <div>
        <el-form :model="form" :rules="rules" ref="formRef" class="basic-form">        
            <el-descriptions class="margin-top" :column="3" border>
                <el-descriptions-item label="姓名">
                    <el-form-item>
                        <el-input v-model="form.name" />
                    </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="性别">
                    <el-form-item>
                        <el-select v-model="form.sex">
                            <el-option label="男" :value="1" />
                            <el-option label="女" :value="2" />
                        </el-select>
                    </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="出生日期">
                    <el-date-picker
                        v-model="form.birthday"
                        type="date"
                        placeholder="选择日期" 
                    />
                </el-descriptions-item>   
                <el-descriptions-item label="身份证号">
                    <el-form-item>
                        <el-input v-model="form.idNo" />
                    </el-form-item> 
                </el-descriptions-item>
                <el-descriptions-item label="手机号码">                
                    <el-form-item>
                        <el-input v-model="form.contact" />
                    </el-form-item>  
                </el-descriptions-item>
                <el-descriptions-item label="昵称">
                    <el-form-item>
                        <el-input v-model="form.nick" />
                    </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="单位名称" :span="2">
                    <el-form-item>
                        <el-input v-model="form.companyName" />
                    </el-form-item>  
                </el-descriptions-item>
                <el-descriptions-item label="账号状态">
                    <el-form-item>
                        <el-switch
                            v-model="form.status"
                            active-text="禁用"
                            inactive-text="启用"
                        />
                    </el-form-item>                 
                </el-descriptions-item> 
                <el-descriptions-item label="单位分类" :span="2">
                    <el-form-item>
                        <el-input v-model="form.companyTypes" />
                    </el-form-item>                    
                </el-descriptions-item>
                <el-descriptions-item label="组织机构代码">
                    <el-form-item>
                        <el-input v-model="form.orgNo" />
                    </el-form-item>                 
                </el-descriptions-item> 
                <el-descriptions-item label="头像">
                    <el-upload
                        class="cus-uploader"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                    >
                        <img v-if="form.avatar" :src="form.avatar" class="thumb">
                        <i v-else class="el-icon-plus uploader-icon"></i>
                    </el-upload>
                </el-descriptions-item>
                <el-descriptions-item label="身份证头像面">
                    <el-upload
                        class="cus-uploader"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                    >
                        <img v-if="form.idNoPic1" :src="form.idNoPic1" class="thumb">
                        <i v-else class="el-icon-plus uploader-icon"></i>
                    </el-upload>
                </el-descriptions-item>
                <el-descriptions-item label="身份证国微面">
                    <el-upload
                        class="cus-uploader id-no"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                    >
                        <img v-if="form.idNoPic2" :src="form.idNoPic2" class="thumb">
                        <i v-else class="el-icon-plus uploader-icon"></i>
                    </el-upload>
                </el-descriptions-item>
                <el-descriptions-item label="个人简介" :span="3">
                    <el-form-item>
                        <el-input type="textarea" rows="5" v-model="form.resume" placeholder="请填写个人简介" />
                    </el-form-item>                 
                </el-descriptions-item> 
            </el-descriptions>
        </el-form>

        <el-card class="custom-card__inner" shadow="never" style="margin-top:20px;">
            <template #header>
                <i class="el-icon-tickets"></i>
                <span class="title">发票信息</span>
            </template>
            <el-row :gutter="20" >
                <el-col :span="12">
                    <el-descriptions title="公司发票" :column="3" direction="vertical" border>
                        <el-descriptions-item label="发票抬头">xxx</el-descriptions-item>
                        <el-descriptions-item label="税号">xxx</el-descriptions-item>
                        <el-descriptions-item label="公司电话">xxx</el-descriptions-item>
                        <el-descriptions-item label="公司地址">xxx</el-descriptions-item>
                        <el-descriptions-item label="开户银行">xxx</el-descriptions-item>
                        <el-descriptions-item label="银行卡号">xxx</el-descriptions-item>
                        <el-descriptions-item label="邮箱">xxx</el-descriptions-item>
                    </el-descriptions>
                </el-col>
                <el-col :span="12">
                    <el-descriptions title="个人发票" :column="3" direction="vertical" border>
                        <el-descriptions-item label="发票抬头">xxx</el-descriptions-item>
                        <el-descriptions-item label="税号">xxx</el-descriptions-item>
                        <el-descriptions-item label="公司电话">xxx</el-descriptions-item>
                        <el-descriptions-item label="公司地址">xxx</el-descriptions-item>
                        <el-descriptions-item label="开户银行">xxx</el-descriptions-item>
                        <el-descriptions-item label="银行卡号">xxx</el-descriptions-item>
                        <el-descriptions-item label="邮箱">xxx</el-descriptions-item>
                    </el-descriptions>
                </el-col>
            </el-row>
        </el-card>

        <FixedActionBar>
            <el-button type="default" @click="handleCancel('formRef')">取消</el-button>
            <el-button type="primary" :loading="loading" :disabled="disabled" @click="handleConfirm('formRef')">确定</el-button>
        </FixedActionBar>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                name: '张天虎',
                nick: 'aaa',
                sex: 1,
                companyName: '四川劳研科技有限公司',
                companyAddress: '四川劳研科技有限公司',
                classNo: '0201',
                orgNo: '91510400MA6211753G',
                companyTypes: ['运输', '仓储', '科研', '农林', '公共服务业'],
                contact: '18982330585',
                position: '',
                entryTime: 2,
                orderNum: 10,
                birthday: '180-03-15',
                intoTime: '1999-12-11',
                idNo: '513401197209110837',
                avatar: require('../../../assets/images/logo.png'),
                idNoPic1: '',
                idNoPic2: '',
                status: true,
                resume: ''
            },

            rules: {

            },

            loading: false,
            disabled: false
        };
    },

    mounted() {
        
    },

    methods: {
        // 头像
        handleAvatarSuccess(res, file) {
            form.avatar = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },

        // 取消
        handleCancel(formName) {
            this.$refs[formName].resetFields();
            this.closeDialog();
        },

        // 确定
        handleConfirm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.confirmLoading = true;
                    this.confirmDisabled = true;

                    this.closeDialog();
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.basic-form {
    ::v-deep.el-form-item {
        margin-bottom: 0;
    }
}

.cus-uploader {
    ::v-deep.el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &:hover {
            border-color: #409EFF;
        }
    }

    .uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .thumb {
        width: 178px;
        height: 178px;
        display: block;
    }
}
</style>