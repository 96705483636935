<template>
    <Layout title="考题列表">        
        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-search"></i>
                <span class="title">搜索条件</span>
            </template>
            <el-form :model="queryForm" inline ref="queryFormRef" size="small">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="queryForm.name" />
                </el-form-item>
                <el-form-item label="题目类型" prop="typeId">
                    <el-select v-model="queryForm.typeId">
                        <el-option label="请选择" value="" />
                        <el-option label="单选题" :value="0" />
                        <el-option label="多选题" :value="1" />
                        <el-option label="判断题" :value="2" />
                    </el-select>
                </el-form-item>
                <el-form-item label="题目分类" prop="catId">
                    <el-select v-model="queryForm.catId" placeholder="请选择题目分类">
                        <el-option label="请选择" value="" />
                        <el-option 
                            v-for="item in examCatList"
                            :key="item.id"
                            :label="item.name" 
                            :value="item.id" 
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="发布状态" prop="status">
                    <el-select v-model="queryForm.status">
                        <el-option label="请选择" value="" />
                        <el-option label="完善中" :value="0" />
                        <el-option label="已发布" :value="1" />
                        <el-option label="删除" :value="2" />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleQuery">确定</el-button>
                    <el-button type="default" @click="handleReset('queryFormRef')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-s-grid"></i>
                <span class="title">数据列表</span>
                <el-button-group>
                    <el-button type="success" size="small" icon="el-icon-plus" @click="handleAddQuestion">添加题目</el-button>
                    <el-button type="primary" size="small" icon="el-icon-upload2" @click="handleImportQuestion">考题导入</el-button>
                </el-button-group>
            </template>

            <el-table :data="tableData" v-loading="loading">
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column prop="title" label="题目名称" width="300"></el-table-column>      
                <el-table-column label="题目类型" align="center">
                    <template slot-scope="scope">
                        {{ ['单选题', '多选题', '判断题'][scope.row.typeId] }}
                    </template>
                </el-table-column>          
                <el-table-column label="题目分类" align="center">
                    <template slot-scope="scope">
                        {{ catList[scope.row.catId] && catList[scope.row.catId].name }}
                    </template>
                </el-table-column>
                <el-table-column label="发布状态" align="center">
                    <template slot-scope="scope">
                        <el-tag>{{ ['完善中', '已发布', '删除'][scope.row.status] }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" align="center">
                    <template slot-scope="scope">
                        {{ parseTime(scope.row.createTime) }}
                    </template>
                </el-table-column>         
                <el-table-column fixed="right" label="操作" width="270" align="center">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button type="primary" size="mini" icon="el-icon-view" @click.native="handleEdit(scope.row)">编辑</el-button>
                            <el-button type="primary" size="mini" icon="el-icon-setting" @click.native="handleManageAnswer(scope.row)">答案管理</el-button>
                            <el-button type="danger" size="mini" icon="el-icon-delete" @click.native="handleRemove(scope.row)">删除</el-button>                                               
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>

            <div class="el-card__footer">
                <div></div>
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :page-count="totalPage"
                    @current-change="handleCurrentPageChange"
                ></el-pagination> 
            </div>
        </el-card>

        <!-- 题目 -->
        <Question v-if="isLoadQuestionDrawer" :examCatList="catList" @updateFn="getData" />

        <!-- 答案 -->
        <Answer v-if="isLoadAnswerDrawer" @updateFn="getData" />

        <!-- 导入题目 -->
        <ImportQuestion v-if="isLoadImportQuestion" @updateFn="getData" />
    </Layout>
</template>

<script>
import { getExamListApi, getExamCatApi, deleteExamQuestionApi } from '@/api/exam';
import Question from './components/Question.vue';
import Answer from './components/Answer.vue';
import ImportQuestion from './components/Import.vue';

export default {
    components: {
        Question,
        Answer,
        ImportQuestion
    },

    data() {
        return {
            queryForm: {
                pageSize: 10,
                currentPage: 1,
                name: '',
                typeId: '',
                catId: '',
                status: ''
            },

            loading: false,

            tableData: [],
            totalPage: 1,

            examCatList: [],

            isLoadQuestionDrawer: false,
            isLoadAnswerDrawer: false,
            isLoadImportQuestion: false
        };
    },

    computed: {
        catList() {
            return this.examCatList;
        }
    },

    methods: {
        // 获取数据
        getData() {
            this.loading = true;
            getExamListApi(this.queryForm).then(res => {
                this.loading = false;
                if (res.data) {
                    const { allNum, listData } = res.data;

                    this.tableData = listData;
                    this.totalPage = Math.ceil(allNum / this.queryForm.pageSize);
                }
            }).catch(() => {
                this.loading = false;
            });
        },

        // 获取考题分类
        getExamCatList() {
            getExamCatApi().then(res => {
                if (res.data) {
                    const resData = res.data;
                    for (let k in resData) {
                        this.examCatList.push({
                            id: Number(k),
                            name: resData[k]
                        });
                    }
                }
            });
        },

        // 分页
        handleCurrentPageChange(e) {
            this.queryForm.currentPage = e;
            this.getData();
        },

        // 搜索
        handleQuery() {
            this.getData();
        },

        // 重置
        handleReset(formName) {
            console.log(formName);
            this.$refs[formName].resetFields();
        },

        // 添加题目
        handleAddQuestion() {
            this.isLoadQuestionDrawer = true;
            setTimeout(() => {
                this.$eventBus.$emit('OPEN_QUESTION_DRAWER');
            });
        },

        // 导入题目
        handleImportQuestion() {
            this.isLoadImportQuestion = true;
            setTimeout(() => {
                this.$eventBus.$emit('OPEN_IMPORT_QUESTION_DIALOG');
            });
        },

        // 答案管理
        handleManageAnswer(rowData) {
            this.isLoadAnswerDrawer = true;
            setTimeout(() => {
                this.$eventBus.$emit('OPEN_ANSWER_DRAWER', rowData);
            });
        },

        // 编辑
        handleEdit(rowData) {
            this.isLoadQuestionDrawer = true;
            setTimeout(() => {
                this.$eventBus.$emit('OPEN_QUESTION_DRAWER', rowData);
            });
        },

        // 删除
        handleRemove(rowData) {
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const { questionId } = rowData;
                deleteExamQuestionApi({ questionId }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    }); 

                    this.getData();
                });                
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        }
    },

    mounted() {
        this.getData();
        this.getExamCatList();
    }
};
</script>

<style lang="scss" scoped>
.custom-card__outer {
    .custom-toolbar {
        display: flex;
        align-items: center;
        ::v-deep .el-button {
            margin-right: 20px;
            margin-left: 0;
        }
        .info {
            font-size: 30px;
            color: #f56c6c;
        }
    }
}
</style>
