/**
 * @ 路由配置
 * @ 会员管理
 * @ author: LeiHao
 * @ createTime: 2023/06/18
 */
import Layout from '@/layout/Index';
 
export default {
    path: '/member',
    component: Layout,
    name: 'member',
    meta: {
        title: '用户管理',
        icon: 'sidebar-member',
        permissionCode: 'member'
    },
    children: [
        {
            path: '',
            component: () => import('@/views/member/Index'),
            name: 'member-index',
            meta: { 
                title: '用户列表', 
                permissionCode: 'member:index'
            }
        }
    ]
};
