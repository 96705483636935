/**
 * @ 产品 && 设备相关
 */

import $http from '@/plugins/axios';

export default {
    namespaced: true,

    state: {
        // 客户端列表
        clientDataList: []
    },

    mutations: {
        setClientData(state, data) {
            state.clientDataList = data;
        }
    },

    actions: {
        getClientData({ commit }) {
            return new Promise((resolve, reject) => {
                $http.get('/wt-auth/client/list').then(res => {    
                    if (res.code === 0) {
                        const { list } = res.data;

                        commit('setClientData', list);

                        resolve(res);
                    } else {
                        reject(res);
                    }
                }); 
            });
        } 
    }
};
