/**
 * @ 路由配置
 * @ 课程管理
 * @ author: LeiHao
 * @ createTime: 2023/06/18
 */
import Layout from '@/layout/Index';
 
export default {
    path: '/course',
    component: Layout,
    name: 'course',
    meta: {
        title: '课程管理',
        icon: 'sidebar-course',
        permissionCode: 'course'
    },
    children: [
        {
            path: '',
            component: () => import('@/views/course/Index'),
            name: 'course-index',
            meta: { 
                title: '课程列表', 
                permissionCode: 'course:index'
            }
        },
        {
            path: 'modify',
            component: () => import('@/views/course/Modify'),
            name: 'course-modify',
            meta: { 
                title: '课程信息', 
                permissionCode: 'course:modify',
                sidebar: false,
                activeMenu: '/course'
            }
        },
        {
            path: 'exam',
            component: () => import('@/views/course/Exam'),
            name: 'course-exam',
            meta: { 
                title: '考题设置', 
                permissionCode: 'course:exam',
                sidebar: false,
                activeMenu: '/course'
            }
        },
        {
            path: 'userCourse',
            component: () => import('@/views/course/userCourse'),
            name: 'course-userCourse',
            meta: { 
                title: '用户课程', 
                permissionCode: 'course:userCourse'
            }
        },
    ]
};
