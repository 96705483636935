<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
import { siteTitle } from '@/setting';

export default {
    metaInfo() {
        return {
            title: this.$store.state.app.title,
            titleTemplate: title => {
                return `${title} - ${siteTitle}`;
            }
        };
    }
};
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}
</style>
