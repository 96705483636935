<template>
    <div class="login-layout">
        <div class="login-layout__logo">
            <img src="../assets/images/logo.png" width="72" height="72" class="img" />
        </div>

        <div class="login-layout__wrapper">
            <div class="login-layout__title">{{ title }}服务系统</div>
            <div class="login-layout__body">
                <div class="login-layout__banner">
                    <img src="../assets/images/login-banner.jpg" width="304" height="415" />
                </div>
                <div class="login-layout__form">
                    <h4 class="login-form__name">用户登陆</h4>

                    <div class="login-form__field">
                        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="0" size="large">
                            <el-form-item label="" prop="userName">
                                <el-input 
                                    v-model.trim="ruleForm.userName" 
                                    placeholder="手机号" 
                                    @keyup.enter.native="onSubmit('ruleForm')"
                                >
                                    <i slot="prefix" class="el-input__icon el-icon-user"></i>
                                </el-input>            
                            </el-form-item>
                            <el-form-item label="" prop="passWord">
                                <el-input 
                                    type="passWord" 
                                    v-model.trim="ruleForm.passWord" 
                                    autocomplete="off" 
                                    placeholder="密码" 
                                    @keyup.enter.native="onSubmit('ruleForm')"
                                >
                                    <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                                </el-input>
                            </el-form-item>
                            <!-- / 验证码 / -->
                            <!-- <el-form-item label="" prop="captcha">
                                <el-col :span="11">
                                    <el-input 
                                        type="text" 
                                        v-model.trim="ruleForm.captcha" 
                                        autocomplete="off" 
                                        placeholder="验证码" 
                                        @keyup.enter.native="onSubmit('ruleForm')"
                                    >
                                        <i slot="prefix" class="el-input__icon el-icon-s-opportunity"></i>
                                    </el-input>
                                </el-col>
                                <el-col :span="1">&nbsp;</el-col>
                                <el-col :span="12">
                                    <img :src="captchaUrl" width="160" height="40" alt="验证码" @click="getCaptcha" class="form-captch__img" />
                                </el-col>
                            </el-form-item> -->
                            <el-form-item>
                                <el-button 
                                    size="large"
                                    class="login-form__action" 
                                    type="primary" 
                                    @click="onSubmit('ruleForm')" 
                                    :loading="loading"
                                >登录</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>

        <div class="login-layout__footer">
            Copyrights © {{ $store.state.app.copyrightDates }} {{ $store.state.app.copyrightCompany }} 版权所有，并保留所有权利。
            <br />
            <a :href="$store.state.app.copyrightWebsite" target="_blank" :title="$store.state.app.copyrightIcp" v-if="$store.state.app.copyrightIcp">
                ICP备案证书号：{{$store.state.app.copyrightIcp}}
            </a>
        </div>
    </div>
</template>

<script>
import { getUUID } from '@/utils';
import { siteTitle } from '@/setting';
// import md5 from 'js-md5';

export default {
    name: 'Login',

    data() {
        return {
            title: siteTitle,
            captchaUrl: '',
            ruleForm: {
                userName: '', /* skadmin */
                passWord: '', /* skadmin */
                captcha: ''
            },
            rules: {
                userName: [
                    { required: true, message: '请输入账号', trigger: 'blur' }
                ],
                passWord: [
                    { required: true, message: '请输入密码', trigger: 'blur' }, 
                    { min: 5, max: 32, message: '长度在 6 到 32 个字符', trigger: 'blur' }
                ],
                captcha: [
                    { 
                        required: true,
                        validator(rule, value, callback) {
                            if (value === '') {
                                callback(new Error('请输入验证码'));
                            } else if (value.length !== 4) {
                                callback(new Error('验证码长度不合法!'));
                            } else {
                                callback();
                            }
                        }, 
                        trigger: 'blur' 
                    }
                ]
            },

            loading: false,
            redirect: undefined,
            otherQuery: {}
        };
    },
    
    watch: {
        $route: {
            handler(route) {
                const query = route.query;
                if (query) {
                    this.redirect = query.redirect;
                    this.otherQuery = this.getOtherQuery(query);
                }
            },
            immediate: true
        }
    },

    methods: {
        // 生成验证码
        getCaptcha() {
            this.ruleForm.uuid = getUUID();
            this.captchaUrl = `${process.env.VUE_APP_API_ROOT}/service/captcha?clientId=C0001&uuid=${this.ruleForm.uuid}`;
        },

        // 提交登录
        onSubmit(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.loading = true;

                    // 调用vuex中的actions
                    const {
                        userName,
                        captcha,
                    } = this.ruleForm;

                    const params = {
                        userName,
                        captcha,
                        passWord: this.ruleForm.passWord,
                    };
                    this.$store.dispatch('user/login', params).then(() => {
                        this.loading = false;
                        // 登录成功后进行页面跳转
                        this.$router.replace({ name: 'dashboard' });
                    }).catch(() => {
                        // this.getCaptcha();
                        this.loading = false;
                    });
                } else {
                    return false;
                }
            });
        },

        getOtherQuery(query) {
            return Object.keys(query).reduce((acc, cur) => {
                if (cur !== 'redirect') {
                    acc[cur] = query[cur];
                }
                return acc;
            }, {});
        },

        // 页面跳转
        __redirect() {
            const { query } = this.$route;

            // 登录成功后默认跳转到首页
            let redirectUrl = '/';

            // 如果url携带了redirect参数，登录成功后跳转到redirect指定页面
            if (query.redirect && query.redirect !== '/home') {
                redirectUrl = query.redirect;
            }

            this.$router.replace(redirectUrl);
        }
    },

    created() {
        // this.getCaptcha();
    }
};
</script>

<style lang="scss" scoped>
.login-layout {
    height: 100vh;
    background: url('~@/assets/images/background.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &__logo {
        position: absolute;
        left: 40px;
        top: 20px;
        display: flex;
        align-items: center;
        .img {
            margin-right: 10px;
        }
        .text {
            font-size: 24px;
            font-weight: 600;
            color: #fff;
        }
    }

    &__wrapper {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    &__title {
        color: #fff;
        text-align: center;
        font-size: 40px;
        font-weight: bold;
        letter-spacing: 2px;
        font-family: PangMenZhengDao;
    }
    &__body {
        height: 415px;
        background-color: #fff;
        display: flex;
        border-radius: 10px;
        margin-top: 30px;
    }
    &__banner {
        width: 304px;
        height: 415px;
        img {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            display: block;
        }
    }

    &__form {
        flex: none;
        width: 360px;
        padding: 44px 80px;
        box-sizing: content-box;
        ::v-deep .el-input__icon {
            font-size: 18px;
            font-weight: bold;
        }
        ::v-deep .el-icon-circle-check {
            color: rgb(10, 238, 59);
        }
    }

    .login-form__name {
        margin: 0;
        font-size: 20px;
    }

    .login-form__field {
        margin-top: 30px;
    }

    .login-form__action {
        margin-top: 25px;
        width: 100%;
        font-size: 18px;
        letter-spacing: 0.5em;
        background-color: #0d70cd;
        border-color: #0d70cd;
        border-radius: 30px;
    }
    .form-captch__img {
        display: block;
        cursor: pointer;
    }

    &__footer {
        line-height: 180%;
        text-align: center;
        font-size: 12px;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 15px;
        color: rgba(255, 255, 255, .65);
        a {
            color: rgba(255, 255, 255, .45);
            text-decoration: none;
            &:hover {
                color: #409EFF;
            }
        }
    }    
}
</style>
