/**
 * course.js
 * 课程相关缓存
 */

import { getOptionsApi } from '@/api/course';

export default {
    namespaced: true,

    state: {
        optionsData: {}
    },

    mutations: {
        // 设置数据
        setCourseOptions(state, data) {
            state.optionsData = data;
        }
    },

    actions: {
        getCourseOptions({ commit }) {
            getOptionsApi().then(res => {
                if (res.data) {
                    commit('setCourseOptions', res.data);
                }
            });
        }
    }
};
