/**
 * app 公用接口
 * author: LeiHao
 * createTime: 2023/06/24
 */

import $http from '@/plugins/axios';

// 登录
export const loginApi = (data) => {
    return $http({
        url: '/admin/login.html', 
        method: 'POST',
        data
    });
};

// 退出登录
export const logoutApi = (params) => {
    return $http({
        url: '/admin/logout.html', 
        method: 'GET',
        params
    });
};

// 获取用户权限菜单
export const getUserInfoApi = (params) => {
    return $http({
        url: '/admin/getAdminMenu.html', 
        method: 'GET',
        params
    });
};

// 获取用户列表
export const getUserListApi = (params) => {
    return $http({
        url: '/admin/adminList.html', 
        method: 'GET',
        params
    });
};

// 添加用户
export const addUserApi = (data) => {
    return $http({
        url: '/admin/addAdmin.html', 
        method: 'POST',
        data
    });
};

