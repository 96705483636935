<template>
    <Layout title="密码管理" content="定期修改密码可以提高帐号安全性噢~">
        <page-main>
            <el-row>
                <el-col :md="24" :lg="12">
                    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
                        <el-form-item label="原密码" prop="passWord">
                            <el-input v-model="form.passWord" type="password" placeholder="请输入原密码" />
                        </el-form-item>
                        <el-form-item label="新密码" prop="newPassword">
                            <el-input v-model="form.newPassword" type="password" placeholder="请输入原密码" />
                        </el-form-item>
                        <el-form-item label="确认新密码" prop="checkPassword">
                            <el-input v-model="form.checkPassword" type="password" placeholder="请输入原密码" />
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </page-main>
        <fixed-action-bar>
            <el-button type="primary" @click="onSubmit">提交</el-button>
        </fixed-action-bar>
    </Layout>
</template>

<script>
export default {
    data() {
        const validatePassword = (rule, value, callback) => {
            if (value !== this.form.newPassword) {
                callback(new Error('请确认新密码'));
            } else {
                callback();
            }
        };
        return {
            form: {
                passWord: '',
                newPassword: '',
                checkPassword: ''
            },
            rules: {
                passWord: [
                    { required: true, message: '请输入原密码', trigger: 'blur' }
                ],
                newPassword: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { min: 6, max: 18, trigger: 'blur', message: '密码长度为6到18位' }
                ],
                checkPassword: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { validator: validatePassword }
                ]
            }
        };
    },
    methods: {
        onSubmit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    const { _id } = this.$store.state.user.userInfo;

                    this.$axios.post('/admin/users/updatePassWord', Object.assign({}, this.form, { _id })).then(res => {
                        if (res.code === 0) {
                            this.$message({
                                type: 'success',
                                message: '修改成功，请重新登录',
                                duration: 1500,
                                onClose: () => {
                                    this.$store.dispatch('user/logout').then(() => {
                                        this.$router.replace('/login');
                                    });
                                }
                            });
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.message
                            });
                        }                        
                    });
                }
            });
        }
    }
};
</script>
