<template>
    <el-dialog
        title="试题导入"
        :visible.sync="dialogVisible"
        width="600px"
        :append-to-body="true"
        :modal-append-to-body="true"
        :close-on-press-escape="false"
        :wrapperClosable="false"
        class="custom-dialog"
    >
        <el-upload
            class="drag-upload"
            drag
            action
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="httpUpload"
        >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传excel文件，且不超过500kb</div>
        </el-upload>

        <div style="margin-top:20px">
            <el-alert title="excel内容格式如下图：" type="success" :closable="false" />
            <img src="../../../assets//images/exam-demo.png" width="100%" style="margin-top: 10px;" />
        </div>
    </el-dialog>
</template>

<script>
import { importExamExcelApi } from '@/api/exam';

export default {
    name: 'ImportQuestion',

    prop: {
        updateFn: {
            type: Function
        }
    },

    data() {
        return {
            dialogVisible: true,

            confirmLoading: false,
            confirmDisabled: false
        };
    },

    mounted() {
        this.$eventBus.$on('OPEN_IMPORT_QUESTION_DIALOG', () => {
            this.openDialog();
        });
    },

    methods: {
        openDialog() {
            this.dialogVisible = true;
        },

        closeDialog() {
            this.dialogVisible = false;
        },

        // 上传之前
        beforeUpload(rawFile) {
            // console.log('rawFile: ', rawFile);
            const FileExt = rawFile.name.replace(/.+\./, "");
            if (['xls', 'xlsx', 'xlt'].indexOf(FileExt.toLowerCase()) === -1) {
                this.$message({
                    type: 'danger',
                    message: '请上传xls、xlsx、xlt格式的文件'
                });
                return false;
            }

            return true;
        },

        // 自定义上传
        httpUpload(params) {
            this.loadingIns = this.$loading({
                text: '文件上传中...',
                background: 'rgba(0, 0, 0, .2)'
            });

            let formData = new FormData();
            // 向 formData 对象中添加文件
            formData.append('file', params.file);

            importExamExcelApi(formData).then(res => {
                this.loadingIns.close();

                const { isSuccess, allError } = res.data;
                if (isSuccess === 1) {
                    this.$message({
                        type: 'success',
                        message: '上传成功'
                    });
                    this.dialogVisible = false;

                    this.$emit('updateFn');
                } else {
                    const msg = [];
                    for (let i = 0, ii = allError.length; i < ii; i++) {
                        msg.push(`<div style="line-height:20px;">${allError[i]}</div>`);
                    }

                    this.$alert(msg.join(''), '出错了！', {
                        dangerouslyUseHTMLString: true
                    });
                }
            }).catch(e => {
                this.loadingIns.close();
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-dialog {
    .drag-upload {    
        ::v-deep.el-upload {
            display: block;
        }
        ::v-deep.el-upload-dragger {
            width: 100%;
            height: 200px;
        }
    }

    .el-drawer__footer {
        margin-top: 20px;
        text-align: center;
    }
}
</style>
