/**
 * @ 路由配置
 * @ 二维码管理
 * @ author: LeiHao
 * @ createTime: 2024/06/13
 */
import Layout from '@/layout/Index';
 
export default {
    path: '/qrCode',
    component: Layout,
    name: 'lottery',
    meta: {
        title: '二维码管理',
        icon: 'sidebar-qrcode',
        permissionCode: 'qrCode'
    },
    children: [
        {
            path: '',
            component: () => import('@/views/qrCode/Index.vue'),
            name: 'qrCode-list',
            meta: { 
                title: '二维码管理', 
                permissionCode: 'qrCode:index',
                sidebar: false
            }
        }
    ]
};
