<template>
    <el-drawer
        title="抽奖设置"
        :visible.sync="dialogVisible"
        size="500px"
        :append-to-body="true"
        :modal-append-to-body="true"
        :close-on-press-escape="false"
        :wrapperClosable="false"
        class="custom-drawer course-attachment"
    >
        <el-form :model="form" :rules="rules" label-width="100px" ref="formRef">
            <el-form-item label="课程名称">
                <el-link type="primary">{{ form.name }}</el-link>
            </el-form-item>
            <el-form-item label="抽奖列表" prop="prizeId">                    
                <el-select v-model="form.prizeId" placeholder="请选择抽奖活动">
                    <el-option 
                        v-for="item in prizeList"
                        :key="item.prizeId"
                        :label="item.prizeName" 
                        :value="item.prizeId" 
                    />
                </el-select>
            </el-form-item>
        </el-form>

        <div class="el-drawer__footer">
            <el-button @click="handleCancel">取 消</el-button>
            <el-button type="primary" :loading="confirmLoading" :disabled="confirmDisabled" @click="handleConfirm('formRef')">确 定</el-button>
        </div>
    </el-drawer>
</template>

<script>
import { api_getPrizeList, api_setPrize } from '@/api/course';

export default {
    name: 'SetPrizeModify',

    data() {
        return {
            dialogVisible: true,

            loading: false,
            prizeList: [{}],

            courseId: null,
            form: {
                prizeId: ''
            },
            rules: {
                prizeId: [{ required: true, message: '请选择抽奖活动', trigger: 'change' }]
            },

            confirmLoading: false,
            confirmDisabled: false
        };
    },

    mounted() {
        this.$eventBus.$on('OPEN_SET_PRIZE_DIALOG', rowData => {
            const { courseId, prizeId, name } = rowData;
            this.form.name = name;
            this.courseId = courseId;
            this.form.prizeId = prizeId;

            this.openDialog();
        });
    },

    methods: {
        getPrizeList() {
            api_getPrizeList().then(res => {
                if (res.code === 0) {
                    const { listData } = res.data;
                    this.prizeList = listData;
                }
            });            
        },

        openDialog() {
            this.dialogVisible = true;

            this.getPrizeList();
        },

        closeDialog() {
            this.dialogVisible = false;
            this.form = {
                prizeId: '', 
            };
        },

        // 取消
        handleCancel() {
            this.closeDialog();
        },

        // 确定
        handleConfirm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.confirmLoading = true;
                    this.confirmDisabled = true;

                    const { prizeId } = this.form;

                    const payload = {
                        courseId: this.courseId,
                        prizeId, 
                    };

                    api_setPrize(payload).then(res => {
                        this.confirmLoading = false;
                        this.confirmDisabled = false;

                        this.$message({
                            type: 'success',
                            message: '操作成功！'
                        });

                        this.closeDialog();
                    }).catch(() => {
                        this.confirmLoading = false;
                        this.confirmDisabled = false;
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
};
</script>

