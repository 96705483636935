<template>
    <el-drawer
        :title="drawerTitle"
        :visible.sync="drawerVisible"
        :size="660"
        :append-to-body="true"
        :modal-append-to-body="true"
        :close-on-press-escape="false"
        :wrapperClosable="false"
        :destroy-on-close="true"
        class="custom-drawer"
    >
        <h4 style="font-size:16px;font-weight:400;display:flex;align-items:center;">
            抽奖活动名称：
            <el-link type="danger" :underline="false" style="font-size:16px;font-weight:600">{{ form.prizeName }}</el-link>
        </h4>

        <el-table :data="dataList" v-loading="loading">       
            <el-table-column prop="name" label="奖项名称" min-width="120"></el-table-column>        
            <el-table-column prop="money" label="金额" align="center"></el-table-column>  
            <el-table-column prop="num" label="数量" align="center"></el-table-column>
            <el-table-column prop="explain" label="备注信息" min-width="120" align="center"></el-table-column>  
            <el-table-column fixed="right" label="操作" width="120" align="center">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-button 
                            type="primary" 
                            icon="el-icon-edit" 
                            size="mini" 
                            @click.native="handleEditItem(scope.row)"
                        ></el-button>
                        <el-button 
                            type="danger" 
                            icon="el-icon-delete" 
                            size="mini" 
                            @click.native="handleDeleteItem(scope.row)"
                        ></el-button>
                    </el-button-group>                                             
                </template>
            </el-table-column>
        </el-table>

        <div class="course-attachment__group">
            <el-form 
                :model="form" 
                :rules="rules" 
                ref="formRef"  
                label-suffix="："
                label-width="120px"
            >
                <el-form-item label="奖项名称" prop="name">
                    <el-input v-model="form.name" />
                </el-form-item>
                <el-form-item label="金额" prop="money">
                    <el-input v-model="form.money" />
                </el-form-item>
                <el-form-item label="数量" prop="num">
                    <el-input-number v-model="form.num" :min="1" :max="1000000" />
                </el-form-item>
                <el-form-item label="备注信息">
                    <el-input v-model="form.explain" type="textarea" />
                </el-form-item>
            </el-form>
        </div>

        <div class="el-drawer__footer">
            <el-button @click="handleCancel('formRef')">取 消</el-button>
            <el-button type="primary" :loading="submitLoading" :disabled="submitDisabled" @click="handleConfirm('formRef')">确 定</el-button>
        </div>
    </el-drawer>
</template>

<script>
import { api_getPrizeItemList, api_setPrize, api_updatePrizeSetting, api_deletePrizeSetting } from '@/api/lottery';

export default {
    name: 'ModifyPrizeItem',

    props: {
        updateFn: {
            type: Function
        }
    },

    data() {
        return {
            drawerTitle: '奖项设置',
            drawerVisible: true,

            loading: false,
            dataList: [],

            form: {
                num: 0,
                name: '', // 名称
                money: '', // 面额
                explain: '' // 说明
            },
            rules: {
                name: [{ required: true, message: '请输入奖项名称', trigger: 'blur' }],
                money: [{ required: true, message: '请输入奖项金额', trigger: 'blur' }],
                num: [{ required: true, message: '请输入数量', trigger: 'blur' }]
            },

            submitLoading: false,
            submitDisabled: false
        };
    },

    mounted() {
        this.$eventBus.$on('OPEN_PRIZE_ITEM_SETTING_DRAWER', rowData => {
            if (rowData) {
                const { prizeId, prizeName} = rowData;
                this.form.prizeId = prizeId;
                this.form.prizeName = prizeName;
            }

            this.openDialog();
            this.getData();
        });
    },

    methods: {
        // 获取奖项列表
        getData() {
            this.loading = true;

            const payload = {
                prizeId: this.form.prizeId,
                pageSize: 10,
                currentPage: 1
            };

            api_getPrizeItemList(payload).then(res => {
                this.loading = false;

                if (res.code === 0) {
                    this.dataList = res.data;
                } else {
                    this.loading = false;
                }
            });
        },

        openDialog() {            
            this.drawerVisible = true;
        },

        closeDialog() {
            this.drawerVisible = false;
        },

        // 编辑奖项
        handleEditItem(rowData) {
            this.form = rowData;
        },

        // 删除奖项
        handleDeleteItem(rowData) {
            const { prizeId, prizeSettingId } = rowData;

            api_deletePrizeSetting({ prizeId, prizeSettingId }).then(res => {
                this.$message({
                    type: 'success',
                    message: '操作成功！'
                });

                this.getData();
            });
        },

        // 取消
        handleCancel(formName) {
            this.closeDialog();
        },

        resetForm() {
            this.form.num = 0;
            this.form.name = '';
            this.form.money = '';
            this.form.explain = '';
        },

        // 确定
        handleConfirm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitLoading = true;
                    this.submitDisabled = true;

                    const payload = this.form;
                    payload.money = Number(this.form.money);
                    // console.log(payload, this.form);return;
                    if (this.form.prizeSettingId) {
                        api_updatePrizeSetting(payload).then(() => {
                            this.submitLoading = false;
                            this.submitDisabled = false;

                            this.$message({
                                type: 'success',
                                message: '操作成功！'
                            });

                            this.resetForm();
                            this.getData();
                        }).catch(() => {
                            this.submitLoading = false;
                            this.submitDisabled = false;
                        });
                    } else {
                        api_setPrize(payload).then(res => {
                            this.submitLoading = false;
                            this.submitDisabled = false;

                            this.$message({
                                type: 'success',
                                message: '操作成功！'
                            });

                            this.resetForm();
                            this.getData();
                        }).catch(() => {
                            this.submitLoading = false;
                            this.submitDisabled = false;
                        });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.course-attachment {
    &__group {
        border: dashed 1px #409EFF;
        padding: 15px;
        margin-top: 20px;
    }
    &__cell {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .label {
            width: 80px;
            text-align: right;
            font-size: 14px;
            margin-right: 12px;
            display: flex;
            align-items: center;
            .char {
                color: red;
            }
        }
        .content {
            flex: 1;
        }
    }

    ::v-deep .el-form-item__label {
        &::before {
            display: none;
        }        
    }
}
</style>
