/**
 * @ 路由配置
 * @ 外链
 * @ author: LeiHao
 * @ createTime: 2023/08/15
 */
import Layout from '@/layout/Index';

export default {
    path: '/order',
    component: Layout,
    name: 'order',
    meta: {
        title: '订单管理',
        icon: 'sidebar-order',
        permissionCode: 'order'
    },
    children: [
        {
            path: '',
            component: () => import('@/views/order/Index'),
            name: 'order-index',
            meta: { 
                title: '订单列表', 
                permissionCode: 'order:index'
            }
        },
        {
            path: 'detail',
            component: () => import('@/views/order/Detail'),
            name: 'order-detail',
            meta: { 
                title: '订单详情', 
                permissionCode: 'order:detail',
                sidebar: false,
                activeMenu: '/order'
            }
        }
    ]
};