<template>
    <div>
        <template v-if="dataList && dataList.length">
            <div v-for="item in dataList" :key="item.userJobId" style="margin-bottom:20px;">
                <el-descriptions :title="item.companyName" :column="2" border>
                    <el-descriptions-item label="工作单位">{{ item.companyName }}</el-descriptions-item>
                    <el-descriptions-item label="岗位名称">{{ item.jobTitle }}</el-descriptions-item>
                    <el-descriptions-item label="在职时间">{{ parseTime(item.startTime, '{y}年{m}月{d}日') }} - {{ parseTime(item.endTime, '{y}年{m}月{d}日') }}</el-descriptions-item>
                </el-descriptions>
            </div>
        </template>
        <template v-else>
            <el-empty description="暂无数据！"></el-empty>
        </template>
    </div>
</template>

<script>
import { getMemberJobListApi } from '@/api/member';

export default {
    props: {
        userId: {
            type: String
        }
    },

    data() {
        return {
            dataList: [],
            loading: false
        };
    },

    mounted() {
        this.loading = true;
        this.getData();
    },

    methods: {
        getData() {
            getMemberJobListApi({ userId: this.userId }).then(res => {
                this.loading = false;
                if (res.data && res.data.length) {
                    this.dataList = res.data;
                }
            }).catch(() => {
                this.loading = false;
            });
        }   
    }
};
</script>

<style lang="scss" scoped>
.basic-form {
    ::v-deep.el-form-item {
        margin-bottom: 0;
    }
}

.cus-uploader {
    ::v-deep.el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &:hover {
            border-color: #409EFF;
        }
    }

    .uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .thumb {
        width: 178px;
        height: 178px;
        display: block;
    }
}
</style>