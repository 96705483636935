<template>
    <Layout title="二维码">
        <el-card class="custom-card__outer" shadow="never">
            <div class="el-card__header-flex" slot="header">
                <el-form :model="queryForm" inline size="small">
                    <el-form-item>
                        <el-input v-model="queryForm.name" placeholder="请输入名称" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleQuery">查询</el-button>
                    </el-form-item>
                </el-form>

                <el-button type="primary" size="small" icon="el-icon-plus" @click="handleAdd">添加二维码</el-button>
            </div>

            <el-table v-loading="loading" :data="tableData" style="width: 100%" size="large">
                <el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
                <el-table-column prop="name" label="二维码名称" min-width="150"></el-table-column>
                <el-table-column label="二维码" width="150" align="center">
                    <template slot-scope="scope">
                        <img :src="scope.row.imageUrl" width="80" height="80" @click="handlePreview(scope.row)" style="cursor:pointer" />
                    </template>
                </el-table-column>
                <el-table-column prop="courseName" label="关联课程" min-width="180">
                    <template slot-scope="scope">
                        <template v-if="scope.row.courseList && scope.row.courseList.length">
                            <el-tag type="primary" v-for="item in scope.row.courseList" :key="item.courseId" style="margin-right:10px;">
                                {{ item.courseName }}
                            </el-tag>
                        </template>
                        <template v-else>暂无</template>
                    </template>
                </el-table-column>
                <el-table-column label="活动有效期" min-width="150">                    
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.effectiveType === 0">长期有效</el-tag>
                        <el-link type="primary" v-else>
                            {{ parseTime(scope.row.effectiveStartTime, '{y}/{m}/{d} {h}:{i}:{s}') }} - {{ parseTime(scope.row.effectiveEndTime, '{y}/{m}/{d} {h}:{i}:{s}') }}
                        </el-link>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间">
                    <template slot-scope="scope">
                        {{ parseTime(scope.row.createTime) }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="90" align="center">
                    <template slot-scope="scope">
                        <el-button 
                            type="danger" 
                            icon="el-icon-delete" 
                            size="mini" 
                            @click.native="handleDelete(scope.row)"
                        >删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="el-card__footer">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :total="totalCount"
                ></el-pagination>
            </div>
        </el-card>

        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="296px">
            <img width="256" height="256" :src="dialogImageUrl" alt="">
        </el-dialog>

         <!-- 题目 -->
         <ModifyQRCode v-if="isLoadQRCodeDrawer" @updateFn="getData" />
    </Layout>
</template>

<script>
import ModifyQRCode from './components/ModifyQRCode.vue';
import { api_getQRCodeList, api_deleteQRCode } from '@/api/lottery';

export default {
    data() {
        return {
            loading: true,

            queryForm: {
                name: '',
                pageSize: 10,
                currentPage: 1
            },
            
            tableData: [],
            totalCount: 1,

            isLoadQRCodeDrawer: false,

            dialogImageUrl: '',
            dialogVisible: false,
            dialogTitle: '预览'
        };
    },

    components: {
        ModifyQRCode
    },

    methods: {
        getData() {
            this.loading = true;

            api_getQRCodeList(this.queryForm).then(res => {
                this.loading = false;

                if (res.code === 0) {
                    const { listData, allNum } = res.data;

                    this.tableData = listData;
                    this.totalCount = allNum;
                } else {
                    this.loading = false;
                    this.$message({
                        type: 'danger',
                        message: res.msg
                    });
                }
            });
        },

        // 预览二维码
        handlePreview(rowData) {
            const { name, imageUrl } = rowData;

            this.dialogTitle = name;
            this.dialogImageUrl = imageUrl;
            this.dialogVisible = true;
        },

        // 选择消息类型时处理事件
        handleMessageTypeChange(e) {
            console.log(e);
        },

        // 查询
        handleQuery() {
            this.getData();
        },

        // 添加
        handleAdd() {
            this.isLoadQRCodeDrawer = true;
            setTimeout(() => {
                this.$eventBus.$emit('OPEN_QRCODE_DRAWER');
            });
        },

        // 删除
        handleDelete(rowData) {
            const { qrCodeId } = rowData;

            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                api_deleteQRCode({ qrCodeId }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '操作成功！'
                    });
                    this.getData();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        }
    },

    mounted() {
        this.getData();
    }
};
</script>

<style lang="scss" scoped>
.custom-card__inner {
    .el-card__header-flex {
        ::v-deep {
            .el-button {
                margin-right: 10px;
            }
        }
    }
    .flex {
        display: flex;
        align-items: center;
    }
}
</style>
