/**
 * app.js
 * 页面全局控制
 */

import { globalSettings as setting } from '@/setting';

export default {
    namespaced: true,

    state: {
        ...setting,

        // 侧边栏是否收起（用于记录 pc 模式下最后的状态）
        sidebarCollapseLastStatus: setting.sidebarCollapse,

        // 页面标题
        title: ''
    },

    mutations: {
        // 设置网页标题
        setTitle(state, title) {
            state.title = title;
        },

        // 切换侧边栏导航展开/收起
        toggleSidebarCollapse(state) {
            state.sidebarCollapse = !state.sidebarCollapse;
            state.sidebarCollapseLastStatus = !state.sidebarCollapseLastStatus;
        },

        // 更新主题配置
        updateThemeSetting(state, data) {
            Object.assign(state, data);
        }
    },

    actions: {

    },

    getters: {

    }
};
