/**
 * 站点配置文件
 */

// 站点名称
export const siteTitle = '真尚培训';

// api请求统一前缀
export const apiBaseUrl = '';

// clientId
export const clientID = 'TS001';

// 本地存储的token名称
export const localTokenKey = '__token';

// http请求中的token名
export const requestHeadersTokenKey = 'adminx-token';

/**
 * 需要放到vuex中的全局配置项
 */
export const globalSettings = {

    // 是否显示头部
    showHeader: false,

    // 是否始终显示主侧边栏，设置为 false 且主侧边栏里只有一个导航时，主侧边栏会自动隐藏
    alwaysShowMainSidebar: false,

    // 是否开启侧边栏展开收起按钮
    enableSidebarCollapse: true,

    // 侧边栏是否收起
    sidebarCollapse: false,

    // 是否显示底部版权信息，同时在路由 meta 对象里可以单独设置某个路由是否显示底部版权信息
    showCopyright: true,

    // 版权信息配置，格式为：Copyright © [dates] <company>
    copyrightDates: '2022',
    copyrightCompany: 'xxx信息技术有限公司',
    copyrightWebsite: 'https://beian.miit.gov.cn/#/Integrated/index',
    copyrightIcp: '蜀ICP备2022005034号-1',

    // 是否开启导航搜索
    enableNavSearch: true,

    // 是否开启全屏
    enableFullscreen: true,

    // 是否开启页面刷新
    enablePageReload: true,

    // 是否开启载入进度条
    enableProgress: true,

    // 是否开启动态标题
    enableDynamicTitle: false,

    // 是否开启控制台
    enableDashboard: true,

    // 是否开启扁平化路由，开启后三级以及三级以上的嵌套路由均为被处理成二级，但侧边栏导航的层级效果不变
    enableFlatRoutes: false,

    // 控制台名称
    dashboardTitle: '控制台',
    
    // 是否开启主题配置（建议在生产环境关闭）
    enableThemeSetting: true
};
