<template>
    <Layout title="文章分类">  
        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-search"></i>
                <span class="title">筛选条件</span>
            </template>
            <el-form :model="queryForm" inline ref="queryFormRef" size="small">
                <el-form-item label="关键字" prop="keywords">
                    <el-input v-model="queryForm.keywords" placeholder="请输入关键字" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="handleQuery">确定</el-button>
                    <el-button type="default" icon="el-icon-refresh-left" @click="handleReset('queryFormRef')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-s-grid"></i>
                <span class="title">数据列表</span>
                <el-button-group>
                    <el-button type="success" icon="el-icon-plus" @click="handleCreate" size="small">新建文章分类</el-button>
                </el-button-group>
            </template>

            <el-table 
                :data="tableData" 
                v-loading="loading"
                row-key="id"
                :tree-props="{children: 'children', order: 'order'}"
            >          
                <el-table-column prop="catName" label="分类名称"></el-table-column> 
                <el-table-column fixed="right" label="操作" width="180" align="center">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button type="primary" icon="el-icon-edit" @click.native="handleEdit(scope.row)" size="small">编辑</el-button>
                            <el-button type="danger" icon="el-icon-delete" @click.native="handleRemove(scope.row)" size="small">删除</el-button>    
                        </el-button-group>                                           
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <el-drawer
            :title="drawerTitle"
            custom-class="custom-drawer"
            :visible.sync="drawerVisible"
            direction="rtl"
            :append-to-body="true"
            :wrapperClosable="false"
            size="600px"
        >
            <div class="resource-drawer__wrapper">
                <el-form ref="drawerFormRef" :model="drawerForm" size="large" label-width="120px">
                    <el-form-item 
                        label="分类类型" 
                        prop="catTypeId"
                        :rules="[{ required: true, message: '请选择分类类型', trigger: 'change' }]"
                    >
                        <el-select v-model="drawerForm.catTypeId" @change="handleCatTypeChange">
                            <el-option label="请选择" :value="0" />
                            <el-option label="一级分类" :value="1" />
                            <el-option label="二级分类" :value="2" />
                        </el-select>
                    </el-form-item>
                    <el-form-item 
                        label="所属分类" 
                        prop="parentCatId" 
                        v-if="showParent"
                        :rules="[
                            { required: true, message: '请选择所属分类', trigger: 'change' }
                        ]"
                    >
                        <el-select v-model="drawerForm.parentCatId">
                            <el-option label="请选择" :value="0" />
                            <el-option 
                                v-for="item in tableData"
                                :key="item.id"
                                :label="item.catName" 
                                :value="item.id" 
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item 
                        label="分类名称" 
                        prop="name"
                        :rules="[{ required: true, message: '请输入分类名称', trigger: 'blur' }]"
                    >
                        <el-input v-model="drawerForm.name" placeholder="请填写分类名称" />
                    </el-form-item>
                    <el-form-item 
                        label="排序" 
                        prop="dataSort"
                        :rules="[{ required: true, message: '请输入分类排序', trigger: 'blur' }]"
                    >
                        <el-input type="number" v-model="drawerForm.dataSort" placeholder="请填写分类排序" />
                    </el-form-item>
                </el-form>
            </div>
            <div class="el-drawer__footer">
                <el-button type="default" size="large" @click="onCancel('drawerFormRef')">取消</el-button>
                <el-button 
                    type="primary" 
                    size="large" 
                    @click="onConfirm('drawerFormRef')" 
                    :disabled="drawerConfirmDisabled"
                    :loading="drawerConfirmLoading"
                >确定</el-button>
            </div>
        </el-drawer>
    </Layout>
</template>

<script>
import { 
    getArticleTypeListApi,
    addArticleTypeApi,
    editArticleTypeApi,
    deleteArticleTypeApi
} from '@/api/article';

export default {
    data() {
        return {
            queryForm: {
                keywords: ''
            },

            loading: false,

            tableData: [],
            totalPage: 1,

            drawerVisible: false,
            drawerTitle: '',
            catType: 0,
            drawerForm: {
                catTypeId: 0,
                parentCatId: 0,
                name: '',
                dataSort: 1
            },
            showParent: false,
            drawerConfirmDisabled: false,
            drawerConfirmLoading: false
        };
    },

    methods: {
        // 获取数据
        getData() {
            this.loading = true;

            getArticleTypeListApi(this.queryForm).then(res => {
                this.tableData = res.data;

                this.loading = false;
            }).catch(() => {
                this.loading = false;
            });
        },

        // 分页
        handleCurrentPageChange(e) {
            this.queryForm.currentPage = e;
            this.getData();
        },

        // 搜索
        handleQuery() {
            this.getData();
        },

        // 重置
        handleReset(formName) {
            this.$refs[formName].resetFields();
        },

        // 新建
        handleCreate() {
            this.drawerVisible = true;
            this.drawerTitle = '新建文章分类';
        },

        // 编辑
        handleEdit(rowData) {
            this.drawerTitle = '编辑文章分类';
            console.log(rowData);
            if (rowData.children) { // 一级分类
                const { catName, id } = rowData;
                this.drawerForm = {
                    catId: id,
                    catTypeId: 1,
                    name: catName,
                    parentCatId: 0,
                    dataSort: 1
                };
                this.showParent = false;
            } else { // 二级分类
                const { id, catName, catParentId, order } = rowData;
                this.drawerForm = {
                    catId: id,
                    catTypeId: 2,
                    name: catName,
                    parentCatId: catParentId,
                    dataSort: order
                };
                this.showParent = true;
            }
            this.drawerVisible = true;
        },

        // 删除
        handleRemove(rowData) {
            const { id } = rowData;

            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteArticleTypeApi({ catId: id }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    }); 
                    this.getData();
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },

        // 选择类型
        handleCatTypeChange(index) {
            if (index === 2) {
                this.showParent = true;
            } else {
                this.showParent = false;
            }
        },

        // 关闭drawer
        onCancel(formName) {
            this.$refs[formName].resetFields();
            this.drawerVisible = false;
        },

        onConfirm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const { parentCatId, name, dataSort } = this.drawerForm;
                    this.drawerConfirmDisabled = true;
                    this.drawerConfirmLoading = true;
   
                    const payload = {
                        parentCatId, 
                        name, 
                        dataSort: Number(dataSort)
                    };
                    if (this.drawerForm.catId) {
                        payload.catId = this.drawerForm.catId;
                        editArticleTypeApi(payload).then(res => {
                            this.drawerConfirmDisabled = false;
                            this.drawerConfirmLoading = false;
                            this.$message({
                                type: 'success',
                                message: '保存成功！'
                            });

                            this.onCancel(formName);
                            this.getData();
                        }).catch(() => {
                            this.drawerConfirmDisabled = false;
                            this.drawerConfirmLoading = false;
                        });
                    } else {
                        addArticleTypeApi(payload).then(res => {
                            this.drawerConfirmDisabled = false;
                            this.drawerConfirmLoading = false;
                            this.$message({
                                type: 'success',
                                message: '保存成功！'
                            });

                            this.onCancel(formName);
                            this.getData();
                        }).catch(() => {
                            this.drawerConfirmDisabled = false;
                            this.drawerConfirmLoading = false;
                        });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    },

    mounted() {
        this.getData();
    }
};
</script>
