<template>
    <div id="appAside">
        <div 
            class="app-aside__main"
            v-if="!$store.state.app.showHeader && ($store.state.permission.routes.length > 1 || $store.state.app.alwaysShowMainSidebar)"
        >
            <Logo :show-title="false" />
            <div class="nav">
                <template v-for="(item, index) in $store.state.permission.routes">
                    <div 
                        class="item"
                        v-if="item.children && item.children.length !== 0" 
                        :key="index" 
                        :class="{'active': index == $store.state.permission.headerActived}" 
                        :title="item.meta && item.meta.title" 
                        @click="$store.commit('permission/switchHeaderActived', index)"
                    >
                        <svg-icon v-if="item.meta && item.meta.icon" :name="item.meta && item.meta.icon" />
                        <span>{{ item.meta && item.meta.title }}</span>
                    </div>
                </template>
            </div>
        </div>

        <div 
            class="app-aside__sub"
            :class="{
                'is-collapse': $store.state.app.sidebarCollapse,
                'dark': $store.state.permission.routes.length <= 1
            }" 
            @scroll="onSidebarScroll"
            :style="{'background': $store.state.app.showHeader ? variables.g_sub_sidebar_bg_dark : ''}"
        >
            <Logo 
                :show-logo="$store.state.permission.routes.length <= 1 && !$store.state.app.alwaysShowMainSidebar" 
                :class="{ 
                    'sidebar-logo-bg': $store.state.permission.routes.length <= 1,
                    'shadow': sidebarScrollTop 
                }"
            />
            <el-menu 
                :background-color="($store.state.app.showHeader || $store.state.permission.routes.length <= 1) ? variables.g_sub_sidebar_bg_dark : variables.g_sub_sidebar_bg" 
                :text-color="($store.state.app.showHeader || $store.state.permission.routes.length <= 1) ? variables.g_sub_sidebar_menu_color_light : variables.g_sub_sidebar_menu_color" 
                :active-text-color="variables.g_sub_sidebar_menu_active_color" 
                unique-opened 
                :default-active="$route.meta.activeMenu || $route.path" 
                :collapse="$store.state.app.sidebarCollapse" 
                :collapse-transition="false" 
                :class="[
                    $store.state.permission.routes.length > 1 && $store.state.app.sidebarCollapse ? 'is-collapse-without-logo' : '',
                    ($store.state.app.showHeader || $store.state.permission.routes.length <= 1) ? 'el-menu__dark' : 'el-menu__light'
                ]"
            >
                <transition-group name="sidebar">
                    <template v-for="route in $store.getters['permission/sidebarRoutes']">
                        <SidebarItem 
                            v-if="route.meta.sidebar !== false" 
                            :key="route.path" 
                            :item="route" 
                            :base-path="route.path" 
                        />
                    </template>
                </transition-group>
            </el-menu>
        </div>
    </div>
</template>

<script>
import Logo from '../components/Logo';
import SidebarItem from '../components/SidebarItem';

import scssVariables from '@/assets/styles/resources/variables.scss';

export default {
    name: 'LayoutSide',

    components: {
        Logo,
        SidebarItem
    },

    computed: {
        variables() {
            return scssVariables;
        }
    },

    data() {
        return {
            sidebarScrollTop: 0
        };
    },

    methods: {
        onSidebarScroll(e) {
            this.sidebarScrollTop = e.target.scrollTop;
        }
    }
};
</script>

<style lang="scss" scoped>
#appAside {
    position: fixed;
    z-index: 1010;
    top: 0;
    bottom: 0;
    display: flex;
    width: var(--real-sidebar-width);
}
.app-aside__main,
.app-aside__sub {
    transition: 0.3s;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: contain;    
    scrollbar-width: none;  // firefox隐藏滚动条    
    &::-webkit-scrollbar {  // chrome隐藏滚动条
        display: none;
    }
}
.app-aside__main {
    width: $g-main-sidebar-width;
    background-color: $g-main-sidebar-bg;
    color: #fff;
    .app-logo {
        transition: 0.3s;
        background-color: $g-main-sidebar-bg;
    }
    .nav {
        width: inherit;
        padding-top: $g-sidebar-logo-height;
        .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 60px;
            padding: 0 5px;
            cursor: pointer;
            transition: 0.3s;
            &.active,
            &:hover {
                background-color: $g-main-sidebar-menu-active-bg;
            }
            .svg-icon {
                margin: 0 auto;
                font-size: 20px;
            }
            span {
                text-align: center;
                font-size: 14px;
                @include text-overflow;
            }
        }
    }
}
.app-aside__sub {
    width: $g-sub-sidebar-width;
    background-color: $g-sub-sidebar-bg;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    box-shadow: 10px 0 10px -10px darken($g-sub-sidebar-bg, 20);
    // 深色模式
    &.dark {
        background-color: $g-header-bg;
    }
    &.is-collapse {
        width: 64px;
        .app-logo {
            &:not(.sidebar-logo-bg) {
                display: none;
            }
            ::v-deep span {
                display: none;
            }
        }
    }
    .app-logo {
        background: $g-sub-sidebar-bg;
        transition: box-shadow 0.2s, background-color 0.3s, color 0.3s;
        &:not(.sidebar-logo-bg) {
            ::v-deep span {
                color: $g-sub-sidebar-menu-color;
            }
        }
        &.sidebar-logo-bg {
            background: $g-main-sidebar-bg;
        }
        &.shadow {
            box-shadow: 0 10px 10px -10px darken($g-sub-sidebar-bg, 20);
        }
    }
    .el-menu {
        border-right: 0;
        padding-top: $g-sidebar-logo-height;
        transition: border-color 0.3s, background-color 0.3s, color 0.3s;
        &:not(.el-menu--collapse) {
            width: inherit;
        }
        &.is-collapse-without-logo {
            padding-top: 0;
        }
        &.el-menu--collapse {
            ::v-deep .svg-icon {
                margin-right: 0;
            }
            ::v-deep .el-menu-item,
            ::v-deep .el-submenu__title {
                span {
                    display: none;
                }
                i {
                    right: 7px;
                    margin-top: -5px;
                }
            }
        }
    }
}
.app-aside__main + .app-aside__sub {
    left: $g-main-sidebar-width;
}

// 侧边栏动画
.sidebar-enter-active {
    transition: all 0.2s;
}
.sidebar-enter,
.sidebar-leave-active {
    opacity: 0;
    transform: translateY(20px);
}
.sidebar-leave-active {
    position: absolute;
}
</style>
