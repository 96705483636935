<template>
    <div>
        <template v-if="dataList && dataList.length">
            <div v-for="item in dataList" :key="item.recId" style="margin-bottom:20px;">
                <el-descriptions :column="2" border>
                    <el-descriptions-item label="证书">
                        <img src="item.certificateUrl" width="120" height="80" />
                    </el-descriptions-item>
                </el-descriptions>
            </div>
        </template>
        <template v-else>
            <el-empty description="暂无数据！"></el-empty>
        </template>
    </div>
</template>

<script>
import { getMemberCertificateDataApi } from '@/api/member';

export default {
    props: {
        userId: {
            type: String
        }
    },

    data() {
        return {
            loading: false,
            dataList: []
        };
    },

    methods: {
        getCertificateData() {
            getMemberCertificateDataApi({ userId: this.userId }).then(res => {
                this.loading = false;
                if (res.data && res.data.length) {
                    this.dataList = res.data;
                }
            }).catch(() => {
                this.loading = false;
            });
        }
    },

    mounted() {
        this.loading = true;
        this.getCertificateData();
    }
};
</script>

<style lang="scss" scoped>
.record-form {
    ::v-deep.el-form-item {
        margin-bottom: 0;
    }
}
</style>
