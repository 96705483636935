<template>
    <el-drawer
        title="课件信息"
        :visible.sync="dialogVisible"
        size="40%"
        :append-to-body="true"
        :modal-append-to-body="true"
        :close-on-press-escape="false"
        :wrapperClosable="false"
        class="custom-drawer course-attachment"
    >
        <el-table :data="dataList" v-loading="loading">       
            <el-table-column type="index" label="序号" width="50"></el-table-column>        
            <el-table-column prop="name" label="课件名称" align="center" width="130"></el-table-column>  
            <el-table-column prop="classHour" label="课时" align="center" width="60"></el-table-column>
            <el-table-column prop="attachmentUrl" label="课件地址" align="center"></el-table-column>  
            <el-table-column fixed="right" label="操作" width="120" align="center">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-button type="primary" icon="el-icon-edit" size="mini" @click.native="handleEdit(scope.row)"></el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini" @click.native="handleDelete(scope.row)"></el-button>
                    </el-button-group>                                             
                </template>
            </el-table-column>
        </el-table>

        <div class="course-attachment__group">
            <el-form :model="form" :rules="rules" ref="formRef">
                <el-form-item class="course-attachment__cell" prop="name">
                    <div slot="label" class="label">
                        <span class="char">*</span>
                        <span class="text">课件名称</span>
                    </div>
                    <div class="content">
                        <el-input v-model="form.name" placeholder="请填写课件名称" />
                    </div>
                </el-form-item>
                <el-form-item class="course-attachment__cell" prop="classHour">
                    <div slot="label" class="label">
                        <span class="char">*</span>
                        <span class="text">课时</span>
                    </div>
                    <div class="content">
                        <el-input v-model="form.classHour" placeholder="请填写课时" />
                    </div>
                </el-form-item>
                <el-form-item class="course-attachment__cell">                    
                    <div slot="label" class="label">排序</div>
                    <div class="content">
                        <el-input v-model="form.orderNum" placeholder="请填写排序号" />
                    </div>
                </el-form-item>
                <el-form-item class="course-attachment__cell">
                    <div slot="label" class="label">
                        <span class="char">*</span>
                        <span class="text">附件</span>
                    </div>
                    <div class="content">
                        <el-upload
                            class="uploader"
                            action
                            :limit="1"
                            :on-exceed="handleExceed"
                            :file-list="fileList"
                            :before-upload="beforeAvatarUpload"
                            :http-request="httpUpload"
                        >
                            <el-button size="small" type="primary">点击上传</el-button>
                        </el-upload>
                    </div>
                </el-form-item>
            </el-form>
        </div>

        <div class="el-drawer__footer">
            <el-button @click="handleCancel">取 消</el-button>
            <el-button type="primary" :loading="confirmLoading" :disabled="confirmDisabled" @click="handleConfirm('formRef')">确 定</el-button>
        </div>
    </el-drawer>
</template>

<script>
import { 
    getCourseAttachmentListApi,
    getCourseAttachmentUploadParam, 
    editCourseAttachmentListApi,
    saveAttachmentApi,
    deleteCourseAttachmentListApi
} from '@/api/course';

export default {
    name: 'MemberModify',

    data() {
        return {
            dialogVisible: true,

            loading: false,
            dataList: [{}],

            coursewareId: null,
            fileList: [],
            form: {
                courseId: null,
                name: '', 
                classHour: '',
                attachmentUrl: '',
                orderNum: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入课程名称', trigger: 'blur' }
                ],
                classHour: [
                    { required: true, message: '请输入课时', trigger: 'blur' }
                ],
            },

            confirmLoading: false,
            confirmDisabled: false
        };
    },

    mounted() {
        this.$eventBus.$on('OPEN_COURSE_ATTACHMENT_DIALOG', rowData => {
            this.form.courseId = rowData.courseId;

            this.openDialog();

            this.getData();
        });
    },

    methods: {
        // 获取课件列表数据
        getData() {
            this.loading = true;
            getCourseAttachmentListApi({ courseId: this.form.courseId }).then(res => {
                this.loading = false;
                if (res.data && res.data.length) {
                    this.dataList = res.data;
                } else {
                    this.dataList = [];
                }
            }).catch(() => {
                this.loading = false;
            });
        },

        openDialog() {
            this.dialogVisible = true;
        },

        closeDialog() {
            this.dialogVisible = false;
            this.form = {
                courseId: null,
                name: '', 
                classHour: '',
                attachmentUrl: '',
                orderNum: '',
            };
            this.fileList = [];
            this.coursewareId = null;
        },

        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },

        // 图片上传之前
        beforeAvatarUpload(rawFile) {
            // console.log('rawFile: ', rawFile);
            const imgTypes = ['application/pdf', 'audio/mp3', 'audio/mpeg', 'video/mp4'];

            if (!imgTypes.includes(rawFile.type)) {
                // ElMessage.error('Avatar picture must be JPG format!')
                this.$message({
                    type: 'danger',
                    message: '请上传pdf、mp3、mp4格式的文件'
                });

                return false;
            }
            return true;
        },

        // 自定义上传图片
        httpUpload(params) {
            this.loadingIns = this.$loading({
                text: '文件上传中...',
                background: 'rgba(0, 0, 0, .2)'
            });

            getCourseAttachmentUploadParam({ fileName: params.file.name }).then(res => {
                // 获取参数成功后，调用腾讯云接口
                const { UploadUrl, Host, Authorization, BaseLookUrl } = res.data;

                fetch(UploadUrl, {
                    method: 'PUT',
                    body: params.file,
                    headers: {
                        'Host': Host,
                        'Authorization': Authorization
                    }
                }).catch(res => {
                    return res.json();
                }).then(res => {
                    const { url } = res;
                    this.fileList[0] = {
                        name: '',
                        url: BaseLookUrl
                    };
                    this.loadingIns.close();
                });
            }).catch(e => {
                this.loadingIns.close();
            });
        },

        // 编辑课件
        handleEdit(rowData) {
            // editCourseAttachmentListApi().then(res => )
            const { coursewareId, name, classHour, orderNum, attachmentUrl } = rowData;
            this.coursewareId = coursewareId;
            this.form = {
                name: name,
                classHour: classHour,
                orderNum: orderNum
            };   
            this.fileList = [{ name: attachmentUrl, url: attachmentUrl }];    
        },

        // 删除课件
        handleDelete(rowData) {
            this.$confirm('删除后附件将同步删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const { coursewareId } = rowData;
                deleteCourseAttachmentListApi({ coursewareId }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    });
                    this.getData();
                });

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },

        // 取消
        handleCancel() {
            this.closeDialog();
        },

        // 确定
        handleConfirm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.confirmLoading = true;
                    this.confirmDisabled = true;

                    const { courseId, name, classHour, orderNum } = this.form;

                    const requestData = {
                        courseId,
                        name, 
                        classHour: Number(classHour), 
                        orderNum: Number(orderNum), 
                        attachmentUrl: this.fileList[0].url
                    };

                    if (this.coursewareId) { // 编辑
                        requestData.coursewareId = this.coursewareId;
                        editCourseAttachmentListApi(requestData).then(res => {
                            this.confirmLoading = false;
                            this.confirmDisabled = false;

                            this.$message({
                                type: 'success',
                                message: '操作成功！'
                            });

                            this.closeDialog();
                        }).catch(() => {
                            this.confirmLoading = false;
                            this.confirmDisabled = false;
                        });
                    } else { // 添加
                        saveAttachmentApi(requestData).then(res => {
                            this.confirmLoading = false;
                            this.confirmDisabled = false;

                            this.$message({
                                type: 'success',
                                message: '操作成功！'
                            });

                            this.closeDialog();
                        }).catch(() => {
                            this.confirmLoading = false;
                            this.confirmDisabled = false;
                        });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.course-attachment {
    &__group {
        border: dashed 1px #409EFF;
        padding: 15px;
        margin-top: 20px;
    }
    &__cell {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .label {
            width: 80px;
            text-align: right;
            font-size: 14px;
            margin-right: 12px;
            display: flex;
            align-items: center;
            .char {
                color: red;
            }
        }
        .content {
            flex: 1;
        }
    }

    ::v-deep .el-form-item__label {
        &::before {
            display: none;
        }        
    }
}

.uploader {
    display: flex;
    align-items: center;

    ::v-deep .el-upload-list {
        margin-left: 10px;
        flex: 1;
    }
    ::v-deep .el-upload-list__item {
        margin-top: 0;
    }
}
</style>
