/**
 * 课程管理 接口
 * author: LeiHao
 * createTime: 2023/07/06
 */

import $http from '@/plugins/axios';

// 获取发票列表
export const getInvoiceListApi = (params) => {
    return $http({
        url: '/admin/getInvoiceList.html', 
        method: 'GET',
        params
    });
};

// 添加发票
export const addInvoiceApi = (data) => {
    return $http({
        url: '/admin/addInvoiceImage.html', 
        method: 'POST',
        data
    });
};

// 创建发票
export const createInvoiceApi = (params) => {
    return $http({
        url: '/admin/createInvoice.html', 
        method: 'GET',
        params
    });
};

// 获取开票失败列表
export const getFailListApi = (params) => {
    return $http({
        url: '/admin/getErrorInvoiceList.html', 
        method: 'GET',
        params
    });
};

// 获取开票失败列表
export const getLogsListApi = (params) => {
    return $http({
        url: '/admin/getInvoiceLogList.html', 
        method: 'GET',
        params
    });
};

// 上传图片
export const uploadInvoiceImageApi = (params) => {
    return $http({
        url: '/admin/getImageUploadParam.html', 
        method: 'GET',
        params
    });
};

// 上传pdf
export const uploadInvoicePDFApi = (params) => {
    return $http({
        url: '/admin/getPdfUploadParam.html', 
        method: 'GET',
        params
    });
};