/**
 * @ 路由配置
 * @ 课程管理
 * @ author: LeiHao
 * @ createTime: 2023/06/18
 */
import Layout from '@/layout/Index';
 
export default {
    path: '/exam',
    component: Layout,
    name: 'exam',
    meta: {
        title: '考试管理',
        icon: 'sidebar-exam',
        permissionCode: 'exam'
    },
    children: [
        {
            path: '',
            component: () => import('@/views/exam/Index'),
            name: 'exam-Index',
            meta: { 
                title: '考题列表', 
                permissionCode: 'exam:Index'
            }
        },
        /* {
            path: 'userExam',
            component: () => import('@/views/exam/UserExam'),
            name: 'exam-userExam',
            meta: { 
                title: '用户考试列表', 
                permissionCode: 'exam:userExam'
            }
        }, */
        {
            path: 'useRevaluating',
            component: () => import('@/views/exam/UserRevaluating'),
            name: 'exam-userRevaluating',
            meta: { 
                title: '用户评测列表', 
                permissionCode: 'exam:userRevaluating'
            }
        }
    ]
};
