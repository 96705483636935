/**
 * @ 全局引入element ui组件库
 */

import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.prototype.$ELEMENT = { size: 'medium', zIndex: 3000 };
