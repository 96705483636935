/**
 * 订单管理 api 定义
 */

import $http from '@/plugins/axios';

// 获取订单列表
export const getOrderListApi = (params) => {
    return $http({
        url: '/admin/getOrderList.html',
        method: 'GET',
        params
    });
};

// 获取订单详情
export const getOrderDetailApi = (params) => {
    return $http({
        url: '/admin/getOrderInfo.html',
        method: 'GET',
        params
    });
};

// 获取操作日志
export const getOrderLogApi = (params) => {
    return $http({
        url: '/admin/getOrderLog.html',
        method: 'GET',
        params
    });
};