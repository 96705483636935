<template>
    <div>
        <el-card class="custom-card__inner" shadow="never">
            <template #header>
                <span class="title">证书列表</span>
                <el-button type="success" icon="el-icon-plus" size="small" @click="handleCreate">添加证书</el-button>
            </template>

            <el-table :data="tableData" v-loading="loading">
                <el-table-column type="index" label="序号" width="50"></el-table-column>               
                <el-table-column prop="name" label="行业分类" width="100" align="center"></el-table-column> 
                <el-table-column prop="gender" label="证书类型" width="80" align="center"></el-table-column> 
                <el-table-column prop="mobile" label="证书名称" width="120" align="center"></el-table-column> 
                <el-table-column prop="nick" label="证书编号" width="80" align="center"></el-table-column> 
                <el-table-column prop="birthday" label="培训单位" width="150" align="center"></el-table-column>
                <el-table-column prop="birthday" label="发证单位" width="150" align="center"></el-table-column> 
                <el-table-column prop="birthday" label="培训时间" width="120" align="center"></el-table-column> 
                <el-table-column prop="birthday" label="第一次复训" width="180" align="center"></el-table-column> 
                <el-table-column prop="birthday" label="第二次复训" width="120" align="center"></el-table-column> 
                <el-table-column prop="mobile" label="证书到期日期" width="60" align="center"></el-table-column> 
                <el-table-column prop="birthday" label="来源" width="120" align="center"></el-table-column> 
                <el-table-column prop="mobile" label="证书照片" width="60" align="center"></el-table-column>       
                <el-table-column prop="createTime" label="添加时间" width="180" align="center"></el-table-column>    
                <el-table-column fixed="right" label="操作" width="100" align="center">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button type="primary" icon="el-icon-edit" @click.native="handleEdit(scope.row)" size="small">编辑</el-button>
                        </el-button-group>                                             
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <el-dialog
            title="证书信息"
            :visible.sync="dialogVisible"
            width="65%"
            class="custom-dialog"
        >
            <el-form :model="form" :rules="rules" ref="formRef" class="record-form">        
                <el-descriptions class="margin-top" :column="2" border>
                    <el-descriptions-item label="行业类型">
                        <el-form-item>
                            <el-input v-model="form.companyTypes" />
                        </el-form-item>                    
                    </el-descriptions-item>
                    <el-descriptions-item label="证书名称">
                        <el-form-item>
                            <el-input v-model="form.companyName" />
                        </el-form-item>  
                    </el-descriptions-item>
                    <el-descriptions-item label="证书类型">
                        <el-form-item>
                            <el-select v-model="form.sex">
                                <el-option label="男" :value="1" />
                                <el-option label="女" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-descriptions-item>
                    <el-descriptions-item label="证书编号">
                        <el-form-item>
                            <el-input v-model="form.idNo" />
                        </el-form-item> 
                    </el-descriptions-item> 
                    <el-descriptions-item label="培训单位">
                        <el-form-item>
                            <el-input v-model="form.companyAddress" />
                        </el-form-item> 
                    </el-descriptions-item> 
                    <el-descriptions-item label="发证单位">
                        <el-form-item>
                            <el-input v-model="form.companyAddress" />
                        </el-form-item> 
                    </el-descriptions-item> 
                    <el-descriptions-item label="培训时间">
                        <el-date-picker
                            v-model="form.entryTime"
                            type="date"
                            placeholder="选择日期" 
                        />
                    </el-descriptions-item>  
                    <el-descriptions-item label="第一次复训">
                        <el-date-picker
                            v-model="form.entryTime"
                            type="date"
                            placeholder="选择日期" 
                        />
                    </el-descriptions-item>  
                    <el-descriptions-item label="第二次复训">
                        <el-date-picker
                            v-model="form.entryTime"
                            type="date"
                            placeholder="选择日期" 
                        />
                    </el-descriptions-item> 
                    <el-descriptions-item label="证书到期日期">
                        <el-date-picker
                            v-model="form.entryTime"
                            type="date"
                            placeholder="选择日期" 
                        />
                    </el-descriptions-item>   
                    <el-descriptions-item label="证书来源" :span="2">
                        <el-form-item>
                            <el-select v-model="form.politicalStatus">
                                <el-option label="中共党员" :value="1" />
                                <el-option label="其他" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-descriptions-item>
                    <el-descriptions-item label="证书照片" :span="2">
                        <el-form-item>
                            <el-upload action="#" list-type="picture-card" :auto-upload="false">
                                <i slot="default" class="el-icon-plus"></i>
                                <div slot="file" slot-scope="{file}">
                                    <img
                                        class="el-upload-list__item-thumbnail"
                                        :src="file.url" alt=""
                                    >
                                    <span class="el-upload-list__item-actions">
                                        <span
                                            class="el-upload-list__item-preview"
                                            @click="handlePictureCardPreview(file)"
                                            >
                                            <i class="el-icon-zoom-in"></i>
                                        </span>
                                        <span
                                            v-if="!disabled"
                                            class="el-upload-list__item-delete"
                                            @click="handleRemove(file)"
                                        >
                                            <i class="el-icon-delete"></i>
                                        </span>
                                    </span>
                                </div>
                            </el-upload>
                        </el-form-item>
                    </el-descriptions-item>                 
                </el-descriptions>
            </el-form>
            
            <span slot="footer" class="dialog-footer">              
                <el-button type="primary" @click="handleConfirm('formRef')">确 定</el-button>
                <el-button @click="handleCancel('formRef')">取 消</el-button>
            </span>
        </el-dialog>

        <!-- 图片预览 -->
        <el-dialog :visible.sync="viewVisible">
            <img width="100%" :src="viewImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            tableData: [],
            totalPage: 1,

            dialogVisible: false,
            form: {
                name: '张天虎',
                sex: 1,
                companyName: '四川劳研科技有限公司',
                companyAddress: '四川劳研科技有限公司',
                classNo: '0201',
                orgNo: '91510400MA6211753G',
                companyTypes: ['运输', '仓储', '科研', '农林', '公共服务业'],
                contact: '18982330585',
                position: '',
                entryTime: 2,
                orderNum: 10,
                intoTime: '1999-12-11',
                idNo: '513401197209110837',
                securityAppraiser: false,
                registeredSafetyEngineer: true,
                politicalStatus: 1,
                avatar: require('../../../assets/images/logo.png'),
                idNoPic1: '',
                idNoPic2: ''
            },

            rules: {

            },
            

            viewImageUrl: '',
            viewVisible: false,
            disabled: false
        };
    },

    methods: {
        // 获取数据
        getData() {
            this.tableData = [{
                nick: 'aaa',
                mobile: '1380000000',
                name: '张三',
                company: '四川省xxx信息技术有限公司',
                gender: '男',
                birthday: '1980-03-25',
                status: 1,
                createTime: '2022-12-12'
            }];
        },

        // 分页
        handleCurrentPageChange(e) {
            this.getData({ pager: e });
        },

        // 添加
        handleCreate() {
            this.openDialog();
        },

        // 编辑
        handleEdit(rowData) {
            this.openDialog(rowData);
        },

        // 撤销
        handleReset(rowData) {
            this.$confirm('此操作将永久撤销该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '操作成功'
                }); 
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },

        openDialog() {
            this.dialogVisible = true;
        },

        closeDialog() {
            this.dialogVisible = false;
        },

        // 证书图片
        handleRemove(file) {
            console.log(file);
        },
        handlePictureCardPreview(file) {
            this.viewImageUrl = file.url;
            this.viewVisible = true;
        },

        // 取消
        handleCancel(formName) {
            this.$refs[formName].resetFields();
            this.closeDialog();
        },

        // 确定
        handleConfirm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.confirmLoading = true;
                    this.confirmDisabled = true;

                    this.closeDialog();
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    },

    mounted() {
        this.getData();
    }
};
</script>

<style lang="scss" scoped>
.record-form {
    ::v-deep.el-form-item {
        margin-bottom: 0;
    }
}
</style>
