<template>
    <Layout title="报课统计">        
        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-search"></i>
                <span class="title">搜索条件</span>
            </template>
            <el-form :model="queryForm" inline ref="queryFormRef">
                <el-form-item label="关键字" prop="name">
                    <el-input v-model="queryForm.name" />
                </el-form-item>
                <el-form-item label="时间范围" prop="name">
                    <el-date-picker
                        v-model="queryForm.name"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleQuery">确定</el-button>
                    <el-button type="default" @click="handleReset('queryFormRef')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-s-data"></i>
                <span class="title">数据统计</span>
            </template>

            这里放一个图表：总收入，总税费，报名人数
        </el-card>

        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-s-grid"></i>
                <span class="title">数据列表</span>
            </template>

            <el-table :data="tableData" v-loading="loading">
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column prop="text" label="课程名称"></el-table-column>                
                <el-table-column label="课程类型"></el-table-column>
                <el-table-column label="报名人数"></el-table-column>
                <el-table-column label="总收"></el-table-column>
                <el-table-column label="税费"></el-table-column>
                <el-table-column label="订单总数"></el-table-column>
                <el-table-column label="在线缴费人数"></el-table-column>
                <el-table-column label="其他缴费人数"></el-table-column>
                <el-table-column label="开票总数"></el-table-column>
                <el-table-column label="退款笔数"></el-table-column>
                <el-table-column label="退款金额"></el-table-column>
                <el-table-column prop="addTime" label="创建时间"></el-table-column>         
                <el-table-column fixed="right" label="操作" width="150" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click.native="handleEdit(scope.row)">编辑</el-button>
                        <el-button type="text" @click.native="handleRemove(scope.row)">删除</el-button>                                               
                    </template>
                </el-table-column>
            </el-table>

            <div class="el-card__footer">
                <div></div>
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :page-count="totalPage"
                    @current-change="handleCurrentPageChange"
                ></el-pagination> 
            </div>
        </el-card>
    </Layout>
</template>

<script>
export default {
    data() {
        return {
            queryForm: {
                name: ''
            },

            loading: false,

            tableData: [],
            totalPage: 1
        };
    },

    methods: {
        // 获取数据
        getData() {

        },

        // 搜索
        handleQuery() {

        },

        // 重置
        handleReset(formName) {
            console.log(formName);
            this.$refs[formName].resetFields();
        },

        // 分页
        handleCurrentPageChange(e) {
            this.getData({ pager: e });
        },

        // 编辑
        handleEit(rowData) {
           
        },

        // 删除
        handleRemove(rowData) {
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除成功'
                }); 
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        }
    },

    mounted() {
        this.getData();
    }
};
</script>

<style lang="scss" scoped>
.custom-card__outer {
    .custom-toolbar {
        display: flex;
        align-items: center;
        ::v-deep .el-button {
            margin-right: 20px;
            margin-left: 0;
        }
        .info {
            font-size: 30px;
            color: #f56c6c;
        }
    }
}
</style>
