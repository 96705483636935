<template>
    <Layout title="学员列表">  
        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-search"></i>
                <span class="title">筛选条件</span>
            </template>
            <el-form :model="queryForm" inline ref="queryFormRef">
                <el-form-item label="姓名" prop="mobile">
                    <el-input v-model="queryForm.mobile" />
                </el-form-item>
                <el-form-item label="联系方式" prop="nick">
                    <el-input v-model="queryForm.nick" />
                </el-form-item>
                <el-form-item label="性别" prop="gender">
                    <el-select v-model="queryForm.gender" placeholder="请选择性别">
                        <el-option label="全部" value="" />
                        <el-option label="男" value="1" />
                        <el-option label="女" value="2" />
                    </el-select>
                </el-form-item>
                <el-form-item label="身份证号码" prop="nick">
                    <el-input v-model="queryForm.nick" />
                </el-form-item>
                <el-form-item label="单位或区域" prop="nick">
                    <el-input v-model="queryForm.nick" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="handleQuery">确定</el-button>
                    <el-button type="default" icon="el-icon-refresh-left" @click="handleReset('queryFormRef')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-s-grid"></i>
                <span class="title">数据列表</span>
                <el-button-group>
                    <el-button type="success" icon="el-icon-chat-square">发送短信</el-button>
                </el-button-group>
            </template>

            <el-table :data="tableData" v-loading="loading">
                <el-table-column type="selection" width="50"></el-table-column>               
                <el-table-column prop="name" label="姓名" width="100" align="center"></el-table-column> 
                <el-table-column prop="gender" label="性别" width="80" align="center"></el-table-column> 
                <el-table-column prop="nick" label="教育程度" width="80" align="center"></el-table-column> 
                <el-table-column prop="mobile" label="联系方式" width="120" align="center"></el-table-column>  
                <el-table-column prop="mobile" label="身份证号码" width="180" align="center"></el-table-column>                 
                <el-table-column prop="company" label="所在单位" width="200" align="center"></el-table-column> 
                <el-table-column prop="mobile" label="职务" width="60" align="center"></el-table-column>  
                <el-table-column prop="birthday" label="单位地址" width="180" align="center"></el-table-column> 
                <el-table-column prop="birthday" label="组织机构编码" width="120" align="center"></el-table-column> 
                <el-table-column prop="birthday" label="公司类型" width="150" align="center"></el-table-column> 
                <el-table-column prop="birthday" label="入职时间" width="120" align="center"></el-table-column> 
                <el-table-column prop="birthday" label="入行时间" width="120" align="center"></el-table-column> 
                <el-table-column prop="birthday" label="政治面貌" width="80" align="center"></el-table-column> 
                <el-table-column prop="birthday" label="注安师" width="80" align="center"></el-table-column> 
                <el-table-column prop="birthday" label="安评师" width="80" align="center"></el-table-column> 
                <el-table-column prop="birthday" label="成功报名数量" width="150" align="center"></el-table-column> 
                <el-table-column prop="createTime" label="创建时间" width="180" align="center"></el-table-column>      
                <el-table-column prop="createTime" label="最后更新时间" width="180" align="center"></el-table-column>    
                <el-table-column fixed="right" label="操作" width="120" align="center">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button type="primary" icon="el-icon-view" @click.native="handleView(scope.row)">查看详情</el-button>
                        </el-button-group>                                             
                    </template>
                </el-table-column>
            </el-table>

            <div class="el-card__footer">
                <div></div>
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :page-count="totalPage"
                    @current-change="handleCurrentPageChange"
                ></el-pagination> 
            </div>
        </el-card>
    </Layout>
</template>

<script>
export default {
    data() {
        return {
            queryForm: {
                name: ''
            },

            loading: false,

            tableData: [],
            totalPage: 1,

            isLoadModify: false
        };
    },

    methods: {
        // 获取数据
        getData() {
            this.tableData = [{
                nick: 'aaa',
                mobile: '1380000000',
                name: '张三',
                company: '四川省xxx信息技术有限公司',
                gender: '男',
                birthday: '1980-03-25',
                status: 1,
                createTime: '2022-12-12'
            }];
        },

        // 搜索
        handleQuery() {

        },

        // 重置
        handleReset(formName) {
            console.log(formName);
            this.$refs[formName].resetFields();
        },

        // 分页
        handleCurrentPageChange(e) {
            this.getData({ pager: e });
        },

        // 查看详情
        handleView(rowData) {
            const { id } = rowData;
            this.$router.push({
                path: '/student/detail',
                query: {
                    id: id || 1
                }
            });
        }
    },

    mounted() {
        this.getData();
    }
};
</script>

<style lang="scss" scoped>
.custom-card__outer {
    .custom-toolbar {
        display: flex;
        align-items: center;
        ::v-deep .el-button {
            margin-right: 20px;
            margin-left: 0;
        }
        .info {
            font-size: 30px;
            color: #f56c6c;
        }
    }
}
</style>
