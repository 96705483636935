import { render, staticRenderFns } from "./ModifyPrizeItem.vue?vue&type=template&id=ec7e39f4&scoped=true&"
import script from "./ModifyPrizeItem.vue?vue&type=script&lang=js&"
export * from "./ModifyPrizeItem.vue?vue&type=script&lang=js&"
import style0 from "./ModifyPrizeItem.vue?vue&type=style&index=0&id=ec7e39f4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec7e39f4",
  null
  
)

export default component.exports