<template>
    <Layout title="开票失败列表">    
        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-s-grid"></i>
                <span class="title">数据列表</span>
            </template>

            <el-table :data="tableData" v-loading="loading">
                <el-table-column label="发票ID" prop="invoiceId"></el-table-column>
                <el-table-column label="发票图片" align="center">
                    <template slot-scope="scope">
                        <img :src="scope.row.image" width="100" style="cursor:pointer" @click="handlePreview(scope.row.image)" />
                    </template>
                </el-table-column>                
                <el-table-column label="订单编号" prop="orderSn" align="center" width="150"></el-table-column>
                <el-table-column label="订单状态" prop="orderPayStatusString" align="center"></el-table-column>
                <el-table-column label="开票状态" prop="statusString" align="center"></el-table-column>
                <el-table-column label="开票方式" prop="createTypeString" align="center"></el-table-column>
                <el-table-column label="发票类型" prop="typeIdString" align="center"></el-table-column>
                <el-table-column label="系统开票错误次数" prop="errorNum" align="center"></el-table-column>
                <el-table-column label="第三方开票系统id" prop="thirdInvoiceId" align="center"></el-table-column>
                <el-table-column label="系统开票错误原因" prop="openErrorReason" align="center"></el-table-column>
                <el-table-column prop="openTime" label="开票时间" align="center" width="150"></el-table-column>         
                <el-table-column fixed="right" label="操作" width="220" align="center">
                    <template slot-scope="scope">     
                        <el-button-group>
                            <el-button type="primary" size="mini" @click.native="handleAddInvoicePic(scope.row)">手动开票</el-button>
                            <template v-if="scope.row.payStatus === 2 && scope.row.status === 0 && scope.row.isNeed === 1">                                
                                <el-button 
                                    type="success" 
                                    size="mini" 
                                    @click.native="handleCreateInvoice(scope.row)"
                                >系统开票</el-button>
                            </template>
                            <el-button 
                                size="mini" 
                                @click.native="handleViewLog(scope.row)"
                                v-if="scope.row.isNeed === 1"
                            >开票日志</el-button>
                        </el-button-group>                                                    
                    </template>
                </el-table-column>
            </el-table>

            <div class="el-card__footer">
                <div></div>
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :page-count="totalPage"
                    @current-change="handleCurrentPageChange"
                ></el-pagination> 
            </div>
        </el-card>

        <Modify v-if="loadInvoiceDialog" @refreshData="getData" />

        <!-- 图片预览 -->
        <el-dialog :visible.sync="previewDialogVisible">
            <img width="100%" :src="previewUrl" alt="" />
        </el-dialog>
    </Layout>
</template>

<script>
import Modify from './Modify.vue';
import { getFailListApi, createInvoiceApi } from '@/api/invoice';

export default {
    components: {
        Modify
    },

    data() {
        return {
            queryForm: {
                pageSize: 10,
                currentPage: 1
            },

            loading: false,

            tableData: [],
            totalPage: 1,

            loadInvoiceDialog: false,

            previewDialogVisible: false,
            previewUrl: ''
        };
    },

    methods: {
        // 获取数据
        getData() {
            getFailListApi(this.queryForm).then(res => {
                if (res.data) {
                    const { allNum, listData } = res.data;
                    this.tableData = listData;
                    this.totalPage = Math.ceil(allNum / this.queryForm.pageSize);
                }
            });
        },

        // 分页
        handleCurrentPageChange(e) {
            this.queryForm.currentPage = e;
            this.getData();
        },

        // 预览发票
        handlePreview(url) {
            this.previewUrl = url;
            this.previewDialogVisible = true;
        },

        // 添加发票
        handleAddInvoicePic(rowData) {
            this.loadInvoiceDialog = true;
            setTimeout(() => {
                this.$eventBus.$emit('OPEN_INVOICE_DIALOG', rowData);
            });
        },

        // 创建发票
        handleCreateInvoice(rowData) {
            const { invoiceId } = rowData;
            createInvoiceApi({ invoiceId }).then(res => {
                this.$message({
                    type: 'success',
                    message: '操作成功！'
                });

                this.getData();
            });
        },

        // 查看开盘日志
        handleViewLog(rowData) {
            const { invoiceId } = rowData;
            this.$router.push({
                path: '/invoice/logs',
                query: {
                    invoiceId
                }
            });
        }
    },

    mounted() {
        this.getData();
    }
};
</script>

<style lang="scss" scoped>
.custom-card__outer {
    .custom-toolbar {
        display: flex;
        align-items: center;
        ::v-deep .el-button {
            margin-right: 20px;
            margin-left: 0;
        }
        .info {
            font-size: 30px;
            color: #f56c6c;
        }
    }
}
</style>
