<template>
    <div class="app-header">
        <div class="app-header__master">
            <Logo />

            <div class="navigation" v-if="$store.state.permission.routes.length > 1">
                <template v-for="(item, index) in routes">
                    <div 
                        class="item"
                        v-if="item.children && item.children.length !== 0" 
                        :key="index" 
                        :class="{'active': index === activeRouter}" 
                        @click="swtichRouter(index)"
                    >
                        <svg-icon v-if="item.meta && item.meta.icon" :name="item.meta && item.meta.icon" />
                        <span v-if="item.meta && item.meta.title">{{ item.meta && item.meta.title }}</span>
                    </div>
                </template>
            </div>
        </div>

        <Toolbar />
    </div>
</template>

<script>
import Logo from '../components/Logo';
import Toolbar from './Toolbar';

export default {
    name: 'LayoutHeader',

    components: {
        Logo,
        Toolbar
    },

    computed: {
        routes() {
            return this.$store.state.permission.routes;
        },

        activeRouter() {
            return this.$store.state.permission.headerActived;
        }
    },

    methods: {
        swtichRouter(index) {
            this.$store.commit('permission/switchHeaderActived', index);
        }
    }
};
</script>

<style lang="scss" scoped>
.app-header {
    width: $g-header-width;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__master {
        display: flex;
        align-items: center;
    }

    .navigation {
        display: flex;
        margin: 0 50px;
        flex: 1;
        .item {
            margin: 0 10px;
            padding: 10px;
            border-radius: 10px;
            cursor: pointer;
            transition: all 0.3s;
            display: flex;
            align-items: center;
            &.active,
            &:hover {
                background-color: $g-header-menu-active-bg;
            }
            .svg-icon {
                font-size: 22px;
                & + span {
                    margin-left: 5px;
                    font-size: 18px;
                    line-height: 100%;
                }
            }
        }
    }
}
</style>
