<template>
    <Layout title="订单详情">    
        <template #extend>
            <el-button @click="handleReturn">返回上一页</el-button>
        </template>    

        <el-card class="custom-card__outer" v-loading="loading">
            <div class="order-group">
                <div class="order-group__header">订单基本信息</div>
                <el-descriptions class="margin-top" :column="3" border>
                    <el-descriptions-item label="订单编号">{{ orderData.orderSn }}</el-descriptions-item>
                    <el-descriptions-item label="订单金额">{{ orderData.totalAmount }}</el-descriptions-item>
                    <el-descriptions-item label="付款状态">
                        {{ ['全部', '已付款', '未付款', '取消'][Number(orderData.payStatus)] }}
                    </el-descriptions-item>
                    <el-descriptions-item label="订单类型">{{ orderData.typeId === 1 ? '课件订单' : '积分订单' }}</el-descriptions-item>
                    <el-descriptions-item label="下单时间">{{ orderData.addTimeString }}</el-descriptions-item>
                </el-descriptions>
            </div>

            <div class="order-group">
                <div class="order-group__header">商品信息</div>
                <el-table :data="courseList" v-loading="loading" border>       
                    <el-table-column type="index" label="序号" width="50"></el-table-column>  
                    <el-table-column prop="goodsName" label="商品名称" align="center">
                        <template slot-scope="scope">
                            <img :src="scope.row.goodsCover" width="60" height="60" />
                        </template>
                    </el-table-column>        
                    <el-table-column prop="goodsName" label="商品名称" align="center"></el-table-column>  
                    <el-table-column prop="goodsNum" label="商品数量" align="center"></el-table-column>   
                    <el-table-column prop="price" label="价格" align="center"></el-table-column>     
                    <el-table-column fixed="right" label="操作" width="120" align="center" v-if="orderData.orderTypeId === 0">
                        <template slot-scope="scope">
                            <el-link type="primary" :href="`/order/?id=${scope.row.recId}`">查看<i class="el-icon-view el-icon--right"></i> </el-link>                                         
                        </template>
                    </el-table-column>    
                </el-table>
            </div>

            <div class="order-group">
                <div class="order-group__header">发票信息</div>
                <el-descriptions class="margin-top" :column="3" border>
                    <el-descriptions-item label="发票抬头">{{ invoiceData.title }}</el-descriptions-item>
                    <el-descriptions-item label="发票类型">{{ ['普票', '专票'][invoiceData.typeId] }}</el-descriptions-item>
                    <el-descriptions-item label="抬头类型">
                        {{ ['个人', '公司'][invoiceData.titleTypeId] }}
                    </el-descriptions-item>
                    <el-descriptions-item label="税号" v-if="invoiceData.dutyParagraph">{{ invoiceData.dutyParagraph }}</el-descriptions-item>
                </el-descriptions>
            </div>

            <div class="order-group">
                <div class="order-group__header">付款信息</div>
                <el-table :data="payData" border>       
                    <el-table-column type="index" label="序号" width="50"></el-table-column>        
                    <el-table-column prop="orderId" label="订单ID" align="center"></el-table-column>  
                    <el-table-column prop="formString" label="支付状态" align="center"></el-table-column>  
                    <el-table-column prop="payUniqueKey" label="支付Key" align="center"></el-table-column> 
                    <el-table-column prop="createTime" label="支付时间" align="center" width="180"></el-table-column>      
                </el-table>
            </div>

            <div class="order-group">
                <div class="order-group__header">操作日志</div>
                <el-table :data="logList" v-loading="logLoading" border>
                    <el-table-column type="index" label="序号" width="50"></el-table-column>               
                    <el-table-column prop="userName" label="用户名" align="center"></el-table-column> 
                    <el-table-column prop="content" label="操作内容" align="center"></el-table-column>                  
                    <el-table-column prop="createTimeString" label="操作时间" width="180" align="center"></el-table-column>    
                </el-table>
                <div class="order-group__footer">
                    <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :page-count="logTotalPage"
                        @current-change="handleLogCurrentPageChange"
                    ></el-pagination> 
                </div>
            </div>
        </el-card>

    </Layout>
</template>

<script>
import { getOrderDetailApi, getOrderLogApi } from '@/api/order';

export default {
    data() {
        return {
            orderSn: '',
            activeName: 'first',

            loading: false,
            orderData: {},

            courseList: [],
            invoiceData: {},
            
            payData: [],

            logList: [],
            logLoading: false,
            logTotalPage: 1,
            logPageSize: 10,
            logCurrentPage: 1
        };
    },

    created() {
        new Promise(resolve => {
            const { query }  = this.$route;
            if (query.orderSn) {
                this.orderSn = query.orderSn;
                resolve();
            } else {
                this.$alert('无效的订单号！', '警告', {
                    confirmButtonText: '确定',
                    callback() {
                        this.$router.push('/order');
                    }
                });
            }
        }).then(res => {
            this.getOrderInfo();            
        });
    },

    methods: {
        handleReturn() {
            this.$router.go(-1);
        },

        handleTabClick() {

        },

        // 获取订单信息
        getOrderInfo() {
            this.loading = true;
            getOrderDetailApi({ orderSn: this.orderSn }).then(res => {
                this.loading = false;
                if (res.data) {
                    const { orderGoodsList, invoiceInfo, orderPayInfo, orderId } = res.data;

                    this.orderData = res.data;
                    this.courseList = orderGoodsList;
                    this.invoiceData = invoiceInfo;
                    this.payData = orderPayInfo;

                    this.getLogList(orderId);
                }
            }).catch(() => {
                this.loading = false;
            });
        },

        // 获取操作日志
        getLogList(orderId) {
            this.logLoading = true;
            getOrderLogApi({ 
                orderId, 
                currentPage: this.logCurrentPage, 
                pageSize: this.logPageSize 
            }).then(res => {
                this.logLoading = false;
                if (res.data) {
                    const { AllNum, ListData } = res.data;

                    this.logList = ListData;
                    this.logTotalPage = Math.ceil(AllNum / this.logPageSize);
                }
            }).catch(() => {
                this.logLoading = false;
            });
        },

        // 日志分页
        handleLogCurrentPageChange(e) {
            this.logCurrentPage = e;
            this.getLogList();
        }
    }
};
</script>

<style lang="scss" scoped>
.order-group {
    margin-bottom: 20px;
    &__header {
        font-size: 15px;
        font-weight: 600;
        color: #333;
        margin-bottom: 10px;
    }
    &__footer {
        margin-top: 15px;
        display: flex;
        justify-content: flex-end;
    }
}
</style>
