<template>
    <Layout title="考题设置">    
        <template #extend>
            <el-button @click="handleReturn">返回上一页</el-button>
        </template>    

        <el-card class="custom-card__outer">
            <el-form :model="form" :rules="rules" ref="formRef">
                <el-form-item label="考题分类" prop="questionCatId">
                    <el-select v-model="form.questionCatId">
                        <el-option 
                            v-for="item in examCatList"
                            :key="item.id"
                            :label="item.name" 
                            :value="item.id" 
                        />
                    </el-select>
                </el-form-item>

                <el-form-item label="单选题" class="custom-form__item">
                    <el-descriptions direction="vertical" :column="3" border>
                        <el-descriptions-item label="题目数量">
                            <el-input-number v-model="form.radioNum" controls-position="right" :min="0" :max="100"></el-input-number>
                        </el-descriptions-item>
                        <el-descriptions-item label="每题分数">
                            <el-input-number v-model="form.radioScore" controls-position="right" :min="1" :max="100"></el-input-number>
                        </el-descriptions-item>
                        <el-descriptions-item label="题目总分">
                            <el-input v-model="singleChoiceTotal" placeholder="" readonly>
                                <template slot="append">分</template>
                            </el-input>
                        </el-descriptions-item>
                    </el-descriptions>
                </el-form-item>
                
                <el-form-item label="多选题" class="custom-form__item">
                    <el-descriptions direction="vertical" :column="3" border>
                        <el-descriptions-item label="题目数量">
                            <el-input-number v-model="form.multipleChoiceNum" controls-position="right" :min="0" :max="100"></el-input-number>
                        </el-descriptions-item>
                        <el-descriptions-item label="每题分数">
                            <el-input-number v-model="form.multipleChoiceScore" controls-position="right" :min="1" :max="100"></el-input-number>
                        </el-descriptions-item>
                        <el-descriptions-item label="题目总分">
                            <el-input v-model="multipleChoiceTotal" placeholder="" readonly>
                                <template slot="append">分</template>
                            </el-input>
                        </el-descriptions-item>
                    </el-descriptions>
                </el-form-item>

                <el-form-item label="判断题" class="custom-form__item">
                    <el-descriptions direction="vertical" :column="3" border>
                        <el-descriptions-item label="题目数量">
                            <el-input-number v-model="form.trueFalseNum" controls-position="right" :min="0" :max="100"></el-input-number>
                        </el-descriptions-item>
                        <el-descriptions-item label="每题分数">
                            <el-input-number v-model="form.trueFalseScore" controls-position="right" :min="1" :max="100"></el-input-number>
                        </el-descriptions-item>
                        <el-descriptions-item label="题目总分">
                            <el-input v-model="judgeTopicTotal" placeholder="" readonly>
                                <template slot="append">分</template>
                            </el-input>
                        </el-descriptions-item>
                    </el-descriptions>
                </el-form-item>

                <el-row :gutter="20">
                    <el-col :span="12">  
                        <el-form-item label="考题总分" prop="examTotalScore">
                            <el-input v-model="examTotalScore" placeholder="" readonly>
                                <template slot="append">分</template>
                            </el-input>
                            <el-alert title="考题总分为各题目总分之和，结果未系统自动计算，不可修改！" type="info" :closable="false" />
                        </el-form-item>                      
                        <el-form-item label="考试时长" prop="limitMinutes">
                            <el-input v-model="form.limitMinutes" placeholder="请填写考试时长">
                                <template slot="append">分钟</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="及格分数" prop="passingScore">
                            <el-input v-model="form.passingScore" placeholder="请填写及格分数">
                                <template slot="append">分</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <FixedActionBar>
            <el-button type="default" @click="handleCancel('formRef')">取消</el-button>
            <el-button type="primary" :loading="loading" :disabled="disabled" @click="handleConfirm('formRef')">确定</el-button>
        </FixedActionBar>
    </Layout>
</template>

<script>
import { 
    getExamQuestionInfoApi,    
    getOptionsApi,
    updateExamQuestionApi
} from '@/api/course';
import { getExamCatApi } from '@/api/exam';

export default {
    data() {
        return {
            dialogVisible: true,

            options: {},

            examCatList: [],

            form: {
                courseId: null,
                examConfigId: null,

                questionCatId: '', // 考题分类

                // 单选
                radioNum: 10, // 题目数量
                radioScore: 1, // 单个题目分数

                // 多选
                multipleChoiceNum: 20,
                multipleChoiceScore: 2,

                // 判断
                trueFalseNum: 20,
                trueFalseScore: 3,

                limitMinutes: '', // 考试时长
                passingScore: '' // 合格分数
            },

            rules: {
                questionCatId: [
                    { required: true, message: '请选择考题分类', trigger: 'change' },
                ],
                limitMinutes: [
                    { required: true, message: '请填写考试所需时长', trigger: 'blur' }
                ],
                passingScore: [
                    { required: true, message: '请填写考试合格分数', trigger: 'blur' }
                ]
            },

            loading: false,
            disabled: false
        };
    },

    computed: {
        singleChoiceTotal() {
            const { radioNum, radioScore } = this.form;
            if (radioNum > 0 && radioScore > 0) {
                return radioNum * radioScore;
            }

            return 0;
        },

        multipleChoiceTotal() {
            const { multipleChoiceNum, multipleChoiceScore } = this.form;
            if (multipleChoiceNum > 0 && multipleChoiceScore > 0) {
                return multipleChoiceNum * multipleChoiceScore;
            }

            return 0;
        },

        judgeTopicTotal() {
            const { trueFalseNum, trueFalseScore } = this.form;
            if (trueFalseNum > 0 && trueFalseScore > 0) {
                return trueFalseNum * trueFalseScore;
            }

            return 0;
        },

        examTotalScore() {
            return this.singleChoiceTotal + this.multipleChoiceTotal + this.judgeTopicTotal;
        }
    },

    created() {
        new Promise(resolve => {
            const { query } = this.$route;

            if (query.courseId) {
                this.form.courseId = Number(query.courseId);

                resolve();
            }
        }).then(() => {
            this.getData();             
        });

        this.getOptions();

        this.getExamCatList();
    },

    methods: {
        // 获取课程详情数据 
        getData() {
            getExamQuestionInfoApi({ courseId: this.form.courseId }).then(res => {
                if (res.data) {
                    const {
                        examConfigId,
                        questionCatId,
                        radioNum,
                        radioScore,
                        multipleChoiceNum,
                        multipleChoiceScore,
                        trueFalseNum,
                        trueFalseScore,
                        limitMinutes,
                        passingScore
                    } = res.data;

                    this.form = {
                        courseId: this.form.courseId,
                        examConfigId,
                        questionCatId,
                        radioNum,
                        radioScore,
                        multipleChoiceNum,
                        multipleChoiceScore,
                        trueFalseNum,
                        trueFalseScore,
                        limitMinutes,
                        passingScore
                    };
                }
            });
        },

        // 获取考题分类
        getExamCatList() {
            getExamCatApi().then(res => {
                if (res.data) {
                    const resData = res.data;
                    for (let k in resData) {
                        if (k === 0) {
                            this.examCatList.push({
                                id: '0',
                                name: '请选择'
                            });  
                        }
                        this.examCatList.push({
                            id: Number(k),
                            name: resData[k]
                        });
                    }
                }
            });
        },

        // 获取培训类型
        getOptions() {
            getOptionsApi().then(res => {
                if (res.data) {
                    this.options = res.data;
                }
            });
        },

        handleReturn() {
            this.$router.go(-1);
        },

        // 取消
        handleCancel(formName) {
            this.$refs[formName].resetFields();
        },

        // 确定
        handleConfirm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.confirmLoading = true;
                    this.confirmDisabled = true;

                    const { passingScore, limitMinutes, questionCatId } = this.form;
                    const payload = {
                        ...this.form,
                        passingScore: Number(passingScore),
                        limitMinutes: Number(limitMinutes),
                        questionCatId: Number(questionCatId)
                    };
 
                    updateExamQuestionApi(payload).then(res => {
                        this.$message({
                            type: 'success',
                            message: '操作成功！'
                        });

                        this.handleCancel(formName);
                        this.$router.go(-1);
                    });
                    
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.custom-card__outer {
    .el-alert {
        margin-top: 10px;
        line-height: initial;
    }
}
.custom-form__item {
    display: flex;
    flex-direction: column;
    ::v-deep.el-form-item__label {
        text-align: left;
    }
}
.uploader {    
    padding-top: 36px;
    ::v-deep.el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &:hover {
            border-color: #409EFF;
        }
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
}
</style>
