<template>
    <el-drawer
        :title="drawerTitle"
        :visible.sync="drawerVisible"
        :size="600"
        :append-to-body="true"
        :modal-append-to-body="true"
        :close-on-press-escape="false"
        :wrapperClosable="false"
        :destroy-on-close="true"
        class="custom-drawer question-drawer"
    >
        <el-form :model="questionForm" :rules="rules" ref="questionFormRef">
            <el-form-item label="题目名称" prop="title">
                <el-input v-model="questionForm.title" />
            </el-form-item>
            <el-form-item label="题目类型" prop="typeId">
                <el-select v-model="questionForm.typeId" placeholder="请选择题目类型">
                    <el-option label="单选" :value="0" />
                    <el-option label="多选" :value="1" />
                    <el-option label="判断" :value="2" />
                </el-select>
            </el-form-item>
            <el-form-item label="题目分类" prop="catId">
                <el-select v-model="questionForm.catId" placeholder="请选择题目分类">
                    <el-option 
                        v-for="item in examCatList"
                        :key="item.id"
                        :label="item.name" 
                        :value="item.id" 
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="是否发布" prop="status">
                <el-switch
                    v-model="questionForm.status"
                    active-text="是"
                    inactive-text="否"
                    :active-value="1"
                    :inactive-value="0"
                ></el-switch>
            </el-form-item>
        </el-form>

        <div class="el-drawer__footer">
            <el-button @click="handleCancel('questionFormRef')">取 消</el-button>
            <el-button type="primary" :loading="confirmLoading" :disabled="confirmDisabled" @click="handleConfirm('questionFormRef')">确 定</el-button>
        </div>
    </el-drawer>
</template>

<script>
import { 
    addExamQuestionApi,
    editExamQuestionApi 
} from '@/api/exam';

export default {
    name: 'QuestionDrawer',

    props: {
        examCatList: {
            type: Array,
            default() {
                return [];
            }
        },

        updateFn: {
            type: Function
        }
    },

    data() {
        return {
            drawerTitle: '添加题目',
            drawerVisible: true,

            questionId: null,
            questionForm: {
                title: '',
                catId: '',
                typeId: '',
                status: 0
            },
            rules: {
                title: [
                    { required: true, message: '请输入题目名称', trigger: 'blur' }
                ],
                catId: [
                    { required: true, message: '请选择题目分类', trigger: 'change' }
                ],
                typeId: [
                    { required: true, message: '请选择题目类型', trigger: 'change' }
                ]
            },

            confirmLoading: false,
            confirmDisabled: false
        };
    },

    mounted() {
        this.$eventBus.$on('OPEN_QUESTION_DRAWER', rowData => {
            this.drawerTitle = rowData ? '编辑题目' : '添加题目';
            if (rowData) {
                const { questionId, title, typeId, catId, status } = rowData;
                this.questionId = questionId;
                this.questionForm = {
                    title, 
                    typeId, 
                    catId,
                    status
                };
            } else {
                this.questionForm = {
                    title: '',
                    catId: '',
                    typeId: '',
                    status: 0
                };
            }

            this.openDialog();
        });
    },

    methods: {
        openDialog() {            
            this.drawerVisible = true;
        },

        closeDialog() {
            this.drawerVisible = false;
        },

        // 取消
        handleCancel(formName) {
            this.$refs[formName].resetFields();
            this.closeDialog();
        },

        // 确定
        handleConfirm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.confirmLoading = true;
                    this.confirmDisabled = true;

                    const postData = this.questionForm;

                    if (this.questionId) {
                        postData.questionId = this.questionId;
                        editExamQuestionApi(postData).then(() => {
                            this.confirmLoading = false;
                            this.confirmDisabled = false;

                            this.$message({
                                type: 'success',
                                message: '操作成功！'
                            });

                            this.$emit('updateFn');
                        }).catch(() => {
                            this.confirmLoading = false;
                            this.confirmDisabled = false;
                        });
                    } else {
                        addExamQuestionApi(postData).then(res => {
                            this.confirmLoading = false;
                            this.confirmDisabled = false;

                            this.$message({
                                type: 'success',
                                message: '操作成功！'
                            });

                            this.$emit('updateFn');
                        }).catch(() => {
                            this.confirmLoading = false;
                            this.confirmDisabled = false;
                        });
                    }

                    this.closeDialog(formName);
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.question-drawer {
    ::v-deep.el-form-item__label {
        width: 100%;
        text-align: left;
    }
}
</style>
