/**
 * @ 路由配置
 * @ 抽奖管理
 * @ author: LeiHao
 * @ createTime: 2024/06/13
 */
import Layout from '@/layout/Index';
 
export default {
    path: '/lottery',
    component: Layout,
    name: 'lottery',
    meta: {
        title: '抽奖管理',
        icon: 'sidebar-lottery',
        permissionCode: 'lottery'
    },
    children: [
        {
            path: '/prize',
            component: () => import('@/views/lottery/Prize'),
            name: 'lottery-prize',
            meta: { 
                title: '抽奖设置', 
                permissionCode: 'lottery:prize'
            }
        },
        {
            path: '/record',
            component: () => import('@/views/lottery/Record'),
            name: 'lottery-record',
            meta: { 
                title: '抽奖记录', 
                permissionCode: 'lottery:record'
            }
        }
    ]
};
