<template>
    <router-link 
        v-slot="{ navigate }" 
        custom 
        :to="to" 
        class="app-logo"
        :title="title" 
        tag="div"
    >
        <div @click="navigate">
            <img v-if="showLogo" :src="logo" class="logo" width="40" height="40" />
            <span class="name" v-if="showTitle">{{ title }}</span>
        </div>
    </router-link>
</template>

<script>
import { siteTitle } from '@/setting';

export default {
    name: 'Logo',

    props: {
        showLogo: {
            type: Boolean,
            default: true
        },

        showTitle: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            title: siteTitle,
            logo: require('@/assets/images/logo.png')
        };
    },

    computed: {
        to() {
            const rtn = {};

            if (this.$store.state.app.enableDashboard) {
                rtn.name = 'dashboard';
            }

            return rtn;
        }
    }
};
</script>

<style lang="scss" scoped>
.app-logo {
    position: fixed;
    z-index: 1000;
    top: 0;
    width: inherit;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $g-sidebar-logo-height;
    text-align: center;
    overflow: hidden;
    text-decoration: none;
    cursor: pointer;
    .logo {
        width: 40px;
        height: 40px;
        & + span {
            margin-left: 10px;
        }
    }
    .name {
        display: block;
        font-weight: bold;
        color: #fff;
        @include text-overflow;
    }
}
</style>
