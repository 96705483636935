<template>
    <div>
        <el-table :data="tableData" v-loading="loading">
            <el-table-column type="index" label="序号" width="50"></el-table-column>               
            <el-table-column prop="name" label="姓名" width="100" align="center"></el-table-column> 
            <el-table-column prop="gender" label="性别" width="80" align="center"></el-table-column> 
            <el-table-column prop="mobile" label="手机号码" width="120" align="center"></el-table-column> 
            <el-table-column prop="nick" label="班级名称" width="80" align="center"></el-table-column> 
            <el-table-column prop="birthday" label="公司名称" width="150" align="center"></el-table-column>
            <el-table-column prop="birthday" label="公司类型" width="150" align="center"></el-table-column> 
            <el-table-column prop="birthday" label="组织机构编码" width="120" align="center"></el-table-column> 
            <el-table-column prop="birthday" label="所报课程" width="180" align="center"></el-table-column> 
            <el-table-column prop="birthday" label="报名时间" width="120" align="center"></el-table-column> 
            <el-table-column prop="mobile" label="职务" width="60" align="center"></el-table-column> 
            <el-table-column prop="birthday" label="就职时间" width="120" align="center"></el-table-column> 
            <el-table-column prop="mobile" label="学历" width="60" align="center"></el-table-column> 
            <el-table-column prop="birthday" label="入行日期" width="120" align="center"></el-table-column>              
            <el-table-column prop="mobile" label="身份证号码" width="180" align="center"></el-table-column>    
            <el-table-column prop="birthday" label="注安师" width="80" align="center"></el-table-column> 
            <el-table-column prop="birthday" label="安评师" width="80" align="center"></el-table-column> 
            <el-table-column prop="mobile" label="订单号" width="180" align="center"></el-table-column>
            <el-table-column prop="mobile" label="其他支付" width="150" align="center"></el-table-column>
            <el-table-column prop="birthday" label="付款日期" width="120" align="center"></el-table-column>
            <el-table-column prop="birthday" label="付款状态" width="120" align="center"></el-table-column>
            <el-table-column prop="birthday" label="付款方式" width="120" align="center"></el-table-column>
            <el-table-column prop="birthday" label="政治面貌" width="80" align="center"></el-table-column>
            <el-table-column prop="company" label="职称" width="120" align="center"></el-table-column>              
            <el-table-column prop="birthday" label="培训类型" width="180" align="center"></el-table-column>      
            <el-table-column prop="createTime" label="最后更新时间" width="180" align="center"></el-table-column>    
            <el-table-column fixed="right" label="操作" width="180" align="center">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-button type="primary" icon="el-icon-edit" @click.native="handleEdit(scope.row)" size="small">编辑</el-button>
                        <el-button type="danger" icon="el-icon-delete" @click.native="handleReset(scope.row)" size="small">撤销</el-button>  
                    </el-button-group>                                             
                </template>
            </el-table-column>
        </el-table>

        <div class="el-card__footer">
            <div></div>
            <el-pagination
                background
                layout="total, prev, pager, next"
                :page-count="totalPage"
                @current-change="handleCurrentPageChange"
            ></el-pagination> 
        </div>

        <el-dialog
            title="编辑订单信息"
            :visible.sync="dialogVisible"
            width="65%"
            class="custom-dialog"
        >
            <el-form :model="form" :rules="rules" ref="formRef" class="record-form">        
                <el-descriptions class="margin-top" :column="2" border>
                    <el-descriptions-item label="报名手机号">                
                        <el-form-item>
                            <el-input v-model="form.contact" />
                        </el-form-item>  
                    </el-descriptions-item>
                    <el-descriptions-item label="姓名">
                        <el-form-item>
                            <el-input v-model="form.name" />
                        </el-form-item>
                    </el-descriptions-item>
                    <el-descriptions-item label="性别">
                        <el-form-item>
                            <el-select v-model="form.sex">
                                <el-option label="男" :value="1" />
                                <el-option label="女" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-descriptions-item>
                    <el-descriptions-item label="政治面貌">
                        <el-form-item>
                            <el-select v-model="form.politicalStatus">
                                <el-option label="中共党员" :value="1" />
                                <el-option label="其他" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-descriptions-item>
                    <el-descriptions-item label="身份证号">
                        <el-form-item>
                            <el-input v-model="form.idNo" />
                        </el-form-item> 
                    </el-descriptions-item>                    
                    <el-descriptions-item label="学历">                
                        <el-form-item>
                            <el-select v-model="form.education">
                                <el-option label="研究生" :value="1" />
                                <el-option label="大学本科" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-descriptions-item>   
                    <el-descriptions-item label="职务">
                        <el-form-item>
                            <el-input v-model="form.position" />
                        </el-form-item> 
                    </el-descriptions-item> 
                    <el-descriptions-item label="职称">                
                        <el-form-item>
                            <el-select v-model="form.education">
                                <el-option label="研究生" :value="1" />
                                <el-option label="大学本科" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-descriptions-item>
                    <el-descriptions-item label="公司名称" :span="2">
                        <el-form-item>
                            <el-input v-model="form.companyName" />
                        </el-form-item>  
                    </el-descriptions-item>
                    <el-descriptions-item label="公司地址" :span="3">
                        <el-form-item>
                            <el-input v-model="form.companyAddress" />
                        </el-form-item> 
                    </el-descriptions-item>
                    <el-descriptions-item label="公司类型" :span="2">
                        <el-form-item>
                            <el-input v-model="form.companyTypes" />
                        </el-form-item>                    
                    </el-descriptions-item>
                    <el-descriptions-item label="组织机构代码">
                        <el-form-item>
                            <el-input v-model="form.orgNo" />
                        </el-form-item>                 
                    </el-descriptions-item> 
                    <el-descriptions-item label="入职时间">
                        <el-date-picker
                            v-model="form.entryTime"
                            type="date"
                            placeholder="选择日期" 
                        />
                    </el-descriptions-item>  
                    <el-descriptions-item label="入行时间">
                        <el-date-picker
                            v-model="form.intoTime"
                            type="date"
                            placeholder="选择日期" 
                        />
                    </el-descriptions-item>                               
                    <el-descriptions-item label="是否安评师">
                        <el-switch
                            v-model="form.securityAppraiser"
                            active-text="否"
                            inactive-text="是"
                        />
                    </el-descriptions-item>
                    <el-descriptions-item label="是否注安师">
                        <el-switch
                            v-model="form.registeredSafetyEngineer"
                            active-text="否"
                            inactive-text="是"
                        />
                    </el-descriptions-item>  
                    <el-descriptions-item label="培训类型">                
                        <el-form-item>
                            <el-select v-model="form.education">
                                <el-option label="研究生" :value="1" />
                                <el-option label="大学本科" :value="2" />
                            </el-select>
                        </el-form-item>
                    </el-descriptions-item>                      
                </el-descriptions>
            </el-form>
            
            <span slot="footer" class="dialog-footer">
                <el-button type="warning">资料不合格提醒</el-button>                
                <el-button type="primary" @click="handleConfirm('formRef')">确 定</el-button>
                <el-button @click="handleCancel('formRef')">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            tableData: [],
            totalPage: 1,

            dialogVisible: false,
            form: {
                name: '张天虎',
                sex: 1,
                companyName: '四川劳研科技有限公司',
                companyAddress: '四川劳研科技有限公司',
                classNo: '0201',
                orgNo: '91510400MA6211753G',
                companyTypes: ['运输', '仓储', '科研', '农林', '公共服务业'],
                contact: '18982330585',
                position: '',
                entryTime: 2,
                orderNum: 10,
                intoTime: '1999-12-11',
                idNo: '513401197209110837',
                securityAppraiser: false,
                registeredSafetyEngineer: true,
                politicalStatus: 1,
                avatar: require('../../../assets/images/logo.png'),
                idNoPic1: '',
                idNoPic2: ''
            },

            rules: {

            },
        };
    },

    methods: {
        // 获取数据
        getData() {
            this.tableData = [{
                nick: 'aaa',
                mobile: '1380000000',
                name: '张三',
                company: '四川省xxx信息技术有限公司',
                gender: '男',
                birthday: '1980-03-25',
                status: 1,
                createTime: '2022-12-12'
            }];
        },

        // 分页
        handleCurrentPageChange(e) {
            this.getData({ pager: e });
        },

        // 编辑
        handleEdit(rowData) {
            this.openDialog();
        },

        // 撤销
        handleReset(rowData) {
            this.$confirm('此操作将永久撤销该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '操作成功'
                }); 
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },

        openDialog() {
            this.dialogVisible = true;
        },

        closeDialog() {
            this.dialogVisible = false;
        },

        // 取消
        handleCancel(formName) {
            this.$refs[formName].resetFields();
            this.closeDialog();
        },

        // 确定
        handleConfirm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.confirmLoading = true;
                    this.confirmDisabled = true;

                    this.closeDialog();
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    },

    mounted() {
        this.getData();
    }
};
</script>

<style lang="scss" scoped>
.record-form {
    ::v-deep.el-form-item {
        margin-bottom: 0;
    }
}
</style>
