<template>
    <Layout title="抽奖记录">
        <el-card class="custom-card__outer" shadow="never">
            <div class="el-card__header-flex" slot="header">
                <el-form :model="queryForm" inline size="small">
                    <el-form-item label="奖品名称">
                        <el-input v-model="queryForm.prizeName" placeholder="请输入奖品名称" />
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select v-model="queryForm.isWin" placeholder="请选择状态">
                            <el-option label="全部" :value="-1" />
                            <el-option label="已中奖" :value="1" />
                            <el-option label="未中奖" :value="0" />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleQuery">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-table v-loading="loading" :data="tableData" style="width: 100%" size="large">
                <el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
                <el-table-column prop="userName" label="中奖人名称" align="center"></el-table-column>
                <el-table-column prop="prizeName" label="奖品名称" align="center"></el-table-column>
                <el-table-column prop="money" label="奖品金额" align="center"></el-table-column>
                <el-table-column prop="handleTime" label="是否兑现" align="center">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.isReceive === 1 ? 'success' : 'warning'">
                            {{ scope.row.isReceive === 1 ? '已兑现' : '未兑现' }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="中奖时间" width="180" align="center">
                    <template slot-scope="scope">
                        {{ parseTime(scope.row.createTime) }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="120" align="center">
                    <template slot-scope="scope">
                        <el-button 
                            :disabled="scope.row.isReceive !== false"
                            type="primary" 
                            icon="el-icon-view" 
                            size="mini" 
                            @click.native="handleView(scope.row)"
                        >查看详情</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <div class="el-card__footer">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :total="totalCount"
                ></el-pagination>
            </div>
        </el-card>

        <!-- 查看课程信息弹窗 -->
        <el-dialog
            title="抽奖详情"
            :visible.sync="dialogVisible"
            width="65%"
            class="custom-dialog"
        >
            <div style="min-height:200px" v-loading="infoLoading">
                <el-descriptions :column="2" border>
                    <el-descriptions-item label="奖品名称">{{ prizeData.prizeName }}</el-descriptions-item>
                    <el-descriptions-item label="奖品金额">{{ prizeData.money }}</el-descriptions-item>
                    <el-descriptions-item label="是否中奖">
                        <el-tag type="success">已中奖</el-tag>    
                    </el-descriptions-item>
                    <el-descriptions-item label="领取时间">{{ parseTime(prizeData.receiveTime * 1000) }}</el-descriptions-item>
                </el-descriptions>
            </div>
        </el-dialog>
    </Layout>
</template>

<script>
import { api_getPrizeRecordList, api_getPrizeInfo } from '@/api/lottery';
import { parseTime } from '@/utils';

export default {
    data() {
        return {
            loading: true,

            queryForm: {
                name: '',
                pageSize: 10,
                currentPage: 1,
                isWin: -1
            },
            
            tableData: [],
            totalCount: 1,

            dialogVisible: false,
            infoLoading: false,
            prizeData: {}
        };
    },

    methods: {
        getData(params) {
            this.loading = true;

            api_getPrizeRecordList(this.queryForm).then(res => {
                this.loading = false;

                if (res.code === 0) {
                    const { listData, allNum } = res.data;

                    this.tableData = listData;
                    this.totalCount = allNum;
                } else {
                    this.loading = false;
                    this.$message({
                        type: 'danger',
                        message: res.msg
                    });
                }
            });
        },

        // 选择消息类型时处理事件
        handleMessageTypeChange(e) {
            console.log(e);
        },

        // 查询
        handleQuery() {
            this.getData();
        },

        // 查看
        handleView(rowData) {
            this.dialogVisible = true;

            this.infoLoading = true;
            const { prizeWinId } = rowData;
            api_getPrizeInfo({ prizeWinId }).then(res => {
                this.infoLoading = false;
                if (res.data) {
                    this.prizeData = res.data;
                }
            }).catch(() => {
                this.infoLoading = false;
            });            
        }
    },

    mounted() {
        this.getData();
    }
};
</script>

<style lang="scss" scoped>
.custom-card__inner {
    .el-card__header-flex {
        ::v-deep {
            .el-button {
                margin-right: 10px;
            }
        }
    }
    .flex {
        display: flex;
        align-items: center;
    }
}
</style>
