<template>
    <Layout title="用户评测列表">        
        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-search"></i>
                <span class="title">搜索条件</span>
            </template>
            <el-form :model="queryForm" inline ref="queryFormRef" size="small">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="queryForm.name" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleQuery">确定</el-button>
                    <el-button type="default" @click="handleReset('queryFormRef')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-s-grid"></i>
                <span class="title">数据列表</span>
            </template>

            <el-table :data="tableData" v-loading="loading">
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column prop="title" label="题目名称"></el-table-column>      
                <el-table-column prop="questionCatString" label="题目分类" align="center"></el-table-column>          
                <el-table-column prop="radioNum" label="单选题" align="center"></el-table-column> 
                <el-table-column prop="multipleChoiceNum" label="多选题" align="center"></el-table-column> 
                <el-table-column prop="trueFalseNum" label="判断题" align="center"></el-table-column> 
                <el-table-column label="得分/总分" align="center">
                    <template slot-scope="scope">
                        <span style="color:#F56C6C">{{ scope.row.score }}</span>/{{ scope.row.totalScore }}
                    </template>
                </el-table-column> 
                <el-table-column label="状态" align="center">
                    <template slot-scope="scope">
                        <span></span>
                        <el-tag :type="`['info', 'primary', 'success', 'danger'][scope.row.status]`">{{ scope.row.statusString }}</el-tag>
                    </template>
                </el-table-column> 
                <el-table-column prop="userName" label="用户名" align="center"></el-table-column> 
                <el-table-column prop="spendPoints" label="消耗积分" align="center"></el-table-column>
                <el-table-column label="创建时间" align="center">
                    <template slot-scope="scope">
                        {{ parseTime(scope.row.createTime) }}
                    </template>
                </el-table-column>         
            </el-table>

            <div class="el-card__footer">
                <div></div>
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :page-count="totalPage"
                    @current-change="handleCurrentPageChange"
                ></el-pagination> 
            </div>
        </el-card>

    </Layout>
</template>

<script>
import { getExamCatApi, getUserRevaluationListApi } from '@/api/exam';

export default {
    data() {
        return {
            queryForm: {
                pageSize: 10,
                currentPage: 1,
                name: ''
            },

            loading: false,

            tableData: [],
            totalPage: 1,

            examCatList: []
        };
    },

    computed: {
        catList() {
            return this.examCatList;
        }
    },

    methods: {
        // 获取数据
        getData() {
            this.loading = true;
            getUserRevaluationListApi(this.queryForm).then(res => {
                this.loading = false;
                if (res.data) {
                    const { allNum, listData } = res.data;

                    this.tableData = listData;
                    this.totalPage = Math.ceil(allNum / this.queryForm.pageSize);
                }
            }).catch(() => {
                this.loading = false;
            });
        },

        // 获取考题分类
        getExamCatList() {
            getExamCatApi().then(res => {
                if (res.data) {
                    const resData = res.data;
                    for (let k in resData) {
                        if (resData[k]) {
                            this.examCatList.push({
                                id: Number(k),
                                name: resData[k]
                            });
                        }
                    }
                }
            });
        },

        // 分页
        handleCurrentPageChange(e) {
            this.queryForm.currentPage = e;
            this.getData();
        },

        // 搜索
        handleQuery() {

        },

        // 重置
        handleReset(formName) {
            console.log(formName);
            this.$refs[formName].resetFields();
        },

        // 编辑
        handleEdit(rowData) {
            this.isLoadQuestionDrawer = true;
            setTimeout(() => {
                this.$eventBus.$emit('OPEN_QUESTION_DRAWER', rowData);
            });
        },

        // 删除
        handleRemove(rowData) {
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除成功'
                }); 
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        }
    },

    mounted() {
        this.getData();
        this.getExamCatList();
    }
};
</script>

<style lang="scss" scoped>
.custom-card__outer {
    .custom-toolbar {
        display: flex;
        align-items: center;
        ::v-deep .el-button {
            margin-right: 20px;
            margin-left: 0;
        }
        .info {
            font-size: 30px;
            color: #f56c6c;
        }
    }
}
</style>
