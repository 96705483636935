<template>
    <Layout title="开票日志">    
        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-search"></i>
                <span class="title">搜索条件</span>
            </template>
            <el-form :model="queryForm" inline ref="queryFormRef" size="small">
                <el-form-item label="发票ID" prop="invoiceId">
                    <el-input v-model="queryForm.invoiceId" clearable />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleQuery">确定</el-button>
                    <el-button type="default" @click="handleReset('queryFormRef')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>
            
        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-s-grid"></i>
                <span class="title">数据列表</span>
            </template>

            <el-table :data="tableData" v-loading="loading">
                <el-table-column type="index" label="序号" width="50"></el-table-column>                
                <el-table-column label="发票ID" prop="invoiceId" align="center"></el-table-column>
                <el-table-column label="时间" prop="logTime" align="center"></el-table-column>
                <el-table-column label="操作" prop="actionString" align="center"></el-table-column>
                <el-table-column label="结果" prop="resultString" align="center"></el-table-column>
                <el-table-column label="失败原因" prop="OpenErrorReason" align="center"></el-table-column>
            </el-table>

            <div class="el-card__footer">
                <div></div>
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :page-count="totalPage"
                    @current-change="handleCurrentPageChange"
                ></el-pagination> 
            </div>
        </el-card>
    </Layout>
</template>

<script>
import { getLogsListApi } from '@/api/invoice';

export default {
    data() {
        return {
            queryForm: {
                pageSize: 10,
                currentPage: 1,
                invoiceId: ''
            },

            loading: false,

            tableData: [],
            totalPage: 1
        };
    },

    created() {
        const { query } = this.$route;
        if (query.invoiceId) {
            this.queryForm.invoiceId = query.invoiceId;
        }
    },

    methods: {
        // 获取数据
        getData() {
            getLogsListApi(this.queryForm).then(res => {
                if (res.data) {
                    const { allNum, listData } = res.data;
                    this.tableData = listData;
                    this.totalPage = Math.ceil(allNum / this.queryForm.pageSize);
                } else {
                    this.tableData = [];
                    this.totalPage = 1;
                }
            });
        },

        // 分页
        handleCurrentPageChange(e) {
            this.queryForm.currentPage = e;
            this.getData();
        },

        // 搜索
        handleQuery() {
            this.getData();
        },

        // 重置
        handleReset(formName) {
            console.log(formName);
            this.$refs[formName].resetFields();
        },
    },

    mounted() {
        this.getData();
    }
};
</script>

<style lang="scss" scoped>
.custom-card__outer {
    .custom-toolbar {
        display: flex;
        align-items: center;
        ::v-deep .el-button {
            margin-right: 20px;
            margin-left: 0;
        }
        .info {
            font-size: 30px;
            color: #f56c6c;
        }
    }
}
</style>
