var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"appAside"}},[(!_vm.$store.state.app.showHeader && (_vm.$store.state.permission.routes.length > 1 || _vm.$store.state.app.alwaysShowMainSidebar))?_c('div',{staticClass:"app-aside__main"},[_c('Logo',{attrs:{"show-title":false}}),_c('div',{staticClass:"nav"},[_vm._l((_vm.$store.state.permission.routes),function(item,index){return [(item.children && item.children.length !== 0)?_c('div',{key:index,staticClass:"item",class:{'active': index == _vm.$store.state.permission.headerActived},attrs:{"title":item.meta && item.meta.title},on:{"click":function($event){return _vm.$store.commit('permission/switchHeaderActived', index)}}},[(item.meta && item.meta.icon)?_c('svg-icon',{attrs:{"name":item.meta && item.meta.icon}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.meta && item.meta.title))])],1):_vm._e()]})],2)],1):_vm._e(),_c('div',{staticClass:"app-aside__sub",class:{
            'is-collapse': _vm.$store.state.app.sidebarCollapse,
            'dark': _vm.$store.state.permission.routes.length <= 1
        },style:({'background': _vm.$store.state.app.showHeader ? _vm.variables.g_sub_sidebar_bg_dark : ''}),on:{"scroll":_vm.onSidebarScroll}},[_c('Logo',{class:{ 
                'sidebar-logo-bg': _vm.$store.state.permission.routes.length <= 1,
                'shadow': _vm.sidebarScrollTop 
            },attrs:{"show-logo":_vm.$store.state.permission.routes.length <= 1 && !_vm.$store.state.app.alwaysShowMainSidebar}}),_c('el-menu',{class:[
                _vm.$store.state.permission.routes.length > 1 && _vm.$store.state.app.sidebarCollapse ? 'is-collapse-without-logo' : '',
                (_vm.$store.state.app.showHeader || _vm.$store.state.permission.routes.length <= 1) ? 'el-menu__dark' : 'el-menu__light'
            ],attrs:{"background-color":(_vm.$store.state.app.showHeader || _vm.$store.state.permission.routes.length <= 1) ? _vm.variables.g_sub_sidebar_bg_dark : _vm.variables.g_sub_sidebar_bg,"text-color":(_vm.$store.state.app.showHeader || _vm.$store.state.permission.routes.length <= 1) ? _vm.variables.g_sub_sidebar_menu_color_light : _vm.variables.g_sub_sidebar_menu_color,"active-text-color":_vm.variables.g_sub_sidebar_menu_active_color,"unique-opened":"","default-active":_vm.$route.meta.activeMenu || _vm.$route.path,"collapse":_vm.$store.state.app.sidebarCollapse,"collapse-transition":false}},[_c('transition-group',{attrs:{"name":"sidebar"}},[_vm._l((_vm.$store.getters['permission/sidebarRoutes']),function(route){return [(route.meta.sidebar !== false)?_c('SidebarItem',{key:route.path,attrs:{"item":route,"base-path":route.path}}):_vm._e()]})],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }