<template>
    <div>
        <el-form :model="form" :rules="rules" ref="formRef" class="basic-form">        
            <el-descriptions class="margin-top" :column="3" border>
                <el-descriptions-item label="姓名">
                    <el-form-item>
                        <el-input v-model="form.name" />
                    </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="性别">
                    <el-form-item>
                        <el-select v-model="form.sex">
                            <el-option label="男" :value="1" />
                            <el-option label="女" :value="2" />
                        </el-select>
                    </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="身份证号">
                    <el-form-item>
                        <el-input v-model="form.idNo" />
                    </el-form-item> 
                </el-descriptions-item>
                <el-descriptions-item label="联系方式">                
                    <el-form-item>
                        <el-input v-model="form.contact" />
                    </el-form-item>  
                </el-descriptions-item>
                <el-descriptions-item label="学历">                
                    <el-form-item>
                        <el-select v-model="form.education">
                            <el-option label="研究生" :value="1" />
                            <el-option label="大学本科" :value="2" />
                        </el-select>
                    </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="政治面貌">
                    <el-form-item>
                        <el-select v-model="form.politicalStatus">
                            <el-option label="中共党员" :value="1" />
                            <el-option label="其他" :value="2" />
                        </el-select>
                    </el-form-item>
                </el-descriptions-item>
                <el-descriptions-item label="入行时间">
                    <el-date-picker
                        v-model="form.intoTime"
                        type="date"
                        placeholder="选择日期" 
                    />
                </el-descriptions-item> 
                <el-descriptions-item label="公司名称" :span="2">
                    <el-form-item>
                        <el-input v-model="form.companyName" />
                    </el-form-item>  
                </el-descriptions-item>
                <el-descriptions-item label="公司地址" :span="3">
                    <el-form-item>
                        <el-input v-model="form.companyAddress" />
                    </el-form-item> 
                </el-descriptions-item>
                <el-descriptions-item label="公司类型" :span="2">
                    <el-form-item>
                        <el-input v-model="form.companyTypes" />
                    </el-form-item>                    
                </el-descriptions-item>
                <el-descriptions-item label="组织机构代码">
                    <el-form-item>
                        <el-input v-model="form.orgNo" />
                    </el-form-item>                 
                </el-descriptions-item>
                <el-descriptions-item label="分类编号">
                    <el-form-item>
                        <el-input v-model="form.classNo" />
                    </el-form-item> 
                </el-descriptions-item>                       
                <el-descriptions-item label="职位">
                    <el-form-item>
                        <el-input v-model="form.position" />
                    </el-form-item> 
                </el-descriptions-item>
                <el-descriptions-item label="入职时间">
                    <el-date-picker
                        v-model="form.entryTime"
                        type="date"
                        placeholder="选择日期" 
                    />
                </el-descriptions-item>            
                <el-descriptions-item label="报名订单数">
                    <el-form-item>
                        <el-input v-model="form.orderNum" />
                    </el-form-item> 
                </el-descriptions-item>                       
                <el-descriptions-item label="是否安评师">
                    <el-switch
                        v-model="form.securityAppraiser"
                        active-text="否"
                        inactive-text="是"
                    />
                </el-descriptions-item>
                <el-descriptions-item label="是否注安师">
                    <el-switch
                        v-model="form.registeredSafetyEngineer"
                        active-text="否"
                        inactive-text="是"
                    />
                </el-descriptions-item>    
                <el-descriptions-item label="头像">
                    <el-upload
                        class="cus-uploader"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                    >
                        <img v-if="form.avatar" :src="form.avatar" class="thumb">
                        <i v-else class="el-icon-plus uploader-icon"></i>
                    </el-upload>
                </el-descriptions-item>
                <el-descriptions-item label="身份证头像面">
                    <el-upload
                        class="cus-uploader"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                    >
                        <img v-if="form.idNoPic1" :src="form.idNoPic1" class="thumb">
                        <i v-else class="el-icon-plus uploader-icon"></i>
                    </el-upload>
                </el-descriptions-item>
                <el-descriptions-item label="身份证国微面">
                    <el-upload
                        class="cus-uploader id-no"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                    >
                        <img v-if="form.idNoPic2" :src="form.idNoPic2" class="thumb">
                        <i v-else class="el-icon-plus uploader-icon"></i>
                    </el-upload>
                </el-descriptions-item>
            </el-descriptions>
        </el-form>

        <FixedActionBar>
            <el-button type="default" @click="handleCancel('formRef')">取消</el-button>
            <el-button type="primary" :loading="loading" :disabled="disabled" @click="handleConfirm('formRef')">确定</el-button>
        </FixedActionBar>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,

            form: {
                name: '张天虎',
                sex: 1,
                companyName: '四川劳研科技有限公司',
                companyAddress: '四川劳研科技有限公司',
                classNo: '0201',
                orgNo: '91510400MA6211753G',
                companyTypes: ['运输', '仓储', '科研', '农林', '公共服务业'],
                contact: '18982330585',
                position: '',
                entryTime: 2,
                orderNum: 10,
                intoTime: '1999-12-11',
                idNo: '513401197209110837',
                securityAppraiser: false,
                registeredSafetyEngineer: true,
                politicalStatus: 1,
                avatar: require('../../../assets/images/logo.png'),
                idNoPic1: '',
                idNoPic2: ''
            },

            rules: {

            },

            disabled: false
        };
    },

    mounted() {
        
    },

    methods: {
        // 头像
        handleAvatarSuccess(res, file) {
            form.avatar = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },

        // 取消
        handleCancel(formName) {
            this.$refs[formName].resetFields();
            this.closeDialog();
        },

        // 确定
        handleConfirm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.confirmLoading = true;
                    this.confirmDisabled = true;

                    this.closeDialog();
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.basic-form {
    ::v-deep.el-form-item {
        margin-bottom: 0;
    }
}

.cus-uploader {
    ::v-deep.el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &:hover {
            border-color: #409EFF;
        }
    }

    .uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .thumb {
        width: 178px;
        height: 178px;
        display: block;
    }
}
</style>