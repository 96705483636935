<template>
    <Layout title="最新政策">    
        <template #extend>
            <el-button @click="handleReturn">返回上一页</el-button>
        </template>    

        <el-card class="custom-card__outer">
            <el-form :model="form" :rules="rules" ref="formRef">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="标题" prop="mobile">
                            <el-input v-model="form.mobile" placeholder="请填写手机号码" />
                        </el-form-item>
                        <el-form-item label="简介" prop="name">
                            <el-input type="textarea" rows="4" v-model="form.name" placeholder="请填写姓名" />
                        </el-form-item>
                        <el-form-item label="预览文字" prop="nick">
                            <el-input v-model="form.mobile" placeholder="请输入 0 或 正整数" />
                        </el-form-item>
                        <el-form-item label="积分兑换价格" prop="nick">
                            <el-input v-model="form.mobile" placeholder="请输入 0 或 正整数" />
                        </el-form-item> 
                    </el-col>
                    <el-col :span="12">
                        <el-form-item>
                            <el-upload class="drag-upload" drag
                                action="https://jsonplaceholder.typicode.com/posts/"
                                multiple
                            >
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">将封面图拖到此处，或<em>点击上传</em></div>
                                <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                            </el-upload>
                        </el-form-item> 
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="文章详情" prop="birthday">
                            <el-input type="textarea" v-model="form.birthday" rows="6" placeholder="请填写个人简介" />
                        </el-form-item> 
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <FixedActionBar>
            <el-button type="default" @click="handleCancel('formRef')">取消</el-button>
            <el-button type="primary" :loading="loading" :disabled="disabled" @click="handleConfirm('formRef')">确定</el-button>
        </FixedActionBar>
    </Layout>
</template>

<script>
export default {
    data() {
        return {
            dialogVisible: true,

            form: {

            },

            rules: {

            },

            loading: false,
            disabled: false
        };
    },

    mounted() {
        
    },

    methods: {
        handleReturn() {
            this.$router.go(-1);
        },

        // 取消
        handleCancel(formName) {
            this.$refs[formName].resetFields();
            this.closeDialog();
        },

        // 确定
        handleConfirm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.confirmLoading = true;
                    this.confirmDisabled = true;

                    this.closeDialog();
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.drag-upload {    
    padding-top: 36px;
    ::v-deep.el-upload {
        display: block;
    }
    ::v-deep.el-upload-dragger {
        width: 100%;
        height: 200px;
    }
}
</style>
