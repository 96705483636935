<template>
    <el-drawer
        title="答案管理"
        :visible.sync="drawerVisible"
        :size="600"
        :append-to-body="true"
        :modal-append-to-body="true"
        :close-on-press-escape="false"
        :wrapperClosable="false"
        class="custom-drawer course-answer"
    >
        <div>
            <el-alert type="success" :closable="false">
                <template slot="title">
                    <div style="font-weight:600">{{ courseData.title }}</div>
                </template>
            </el-alert>
        </div>

        <div class="course-answer">
            <div 
                class="course-answer__cell"
                v-for="(item, index) in answerList"
                :key="index"
            >
                <div class="label">{{ index + 1 }}</div>
                <div class="content">
                    <el-input v-model="item.title" placeholder="请填写课件名称" />
                </div>
                <div class="correct">
                    <el-checkbox v-model="item.isTrue" @change="onIsTrueChange($event, index)">正确答案</el-checkbox>
                </div>
                <div class="remove">
                    <el-button type="text" class="el-icon-delete" @click="handleRemoveItem(index)"></el-button>
                </div>
            </div>
        </div>

        <div>
            <el-button type="text" icon="el-icon-plus" @click="handleAdd">添加答案</el-button>
        </div>

        <div class="el-drawer__footer">
            <el-button @click="handleCancel">取 消</el-button>
            <el-button type="primary" :loading="confirmLoading" :disabled="confirmDisabled" @click="handleConfirm">确 定</el-button>
        </div>
    </el-drawer>
</template>

<script>
import { getExamAnswerListApi, saveExamAnswerApi } from '@/api/exam';

export default {
    name: 'AnswerDrawer',

    props: {
        updateFn: {
            type: Function
        }
    },

    data() {
        return {
            drawerVisible: true,

            courseData: {},

            currentAnswerIndex: 0,
            answerList: [
                { answerId: 0, title: '', isTrue: false }
            ],

            confirmLoading: false,
            confirmDisabled: false
        };
    },

    mounted() {
        this.$eventBus.$on('OPEN_ANSWER_DRAWER', rowData => {
            this.courseData = rowData;

            this.openDialog();
        });
    },

    methods: {
        openDialog() {
            this.drawerVisible = true;
            this.getExamAnswerList();
        },

        closeDialog() {
            this.drawerVisible = false;
        },

        // 获取答案列表
        getExamAnswerList() {
            getExamAnswerListApi({ questionId: this.courseData.questionId }).then(res => {
                if (res.data && res.data.length) {
                    this.answerList = res.data.map(item => {
                        return {
                            ...item,
                            isTrue: item.isTrue === 1
                        };
                    });
                }
            });
        },

        // 添加答案
        handleAdd() {
            this.answerList.push({ 
                answerId: 0,
                title: '', 
                isTrue: ''
            });
        },
        
        // 设置是否是正确答案
        onIsTrueChange(e, index) {
            this.answerList = this.answerList.map((item, key) => {
                return {
                    ...item,
                    isTrue: index === key ? true : false
                };
            });
        },

        // 删除答案
        handleRemoveItem(index) {
            this.$confirm('删除后附件将同步删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.answerList = this.answerList.filter((item, key) => key !== index);
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },

        // 取消
        handleCancel() {
            this.closeDialog();
        },

        // 确定
        handleConfirm() {
            this.confirmLoading = true;
            this.confirmDisabled = true;

            const answerList = this.answerList.map(item => {
                const { answerId, title, isTrue } = item;
                const obj = {
                    answerId,
                    title, 
                    isTrue: isTrue ? 1 : 0
                };
                return obj;
            });

            const postData = Object.assign({}, {
                questionId: this.courseData.questionId,
                answerList
            });

            console.log(postData);
            
            saveExamAnswerApi(postData).then(res => {
                this.confirmLoading = false;
                this.confirmDisabled = false;

                this.$message({
                    type: 'success',
                    message: '操作成功！'
                });

                this.$emit('updateFn');
            }).catch(() => {
                this.confirmLoading = false;
                this.confirmDisabled = false;
            });

            this.closeDialog();
        }
    }
};
</script>

<style lang="scss" scoped>
.course-answer {
    margin-top: 20px;
    &__cell {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .label {
            font-size: 14px;
        }
        .content {
            flex: 1;
            margin-left: 10px;
            margin-right: 10px;
        }
        .correct {
            font-size: 13px;
            margin-right: 10px;
        }
    }
}
</style>
