/**
 * @ 路由配置
 * @ 文章管理
 * @ author: LeiHao
 * @ createTime: 2023/06/26
 */
import Layout from '@/layout/Index';
 
export default {
    path: '/article',
    component: Layout,
    name: 'article',
    meta: {
        title: '文章管理',
        icon: 'sidebar-article',
        permissionCode: 'article'
    },
    children: [{
        path: 'category',
        component: () => import('@/views/article/Category'),
        name: 'article-category',
        meta: { 
            title: '文章分类', 
            permissionCode: 'article:category'
        }
    }, {
        path: 'list',
        component: () => import('@/views/article/Index'),
        name: 'article-index',
        meta: { 
            title: '文章列表', 
            permissionCode: 'article:index'
        }
    }, {
        path: 'modify',
        component: () => import('@/views/article/Modify'),
        name: 'article-modify',
        meta: { 
            title: '文章编辑', 
            permissionCode: 'article:modify',
            sidebar: false,
            activeMenu: '/article'
        }
    }]
};
