/**
 * 抽奖管理 api 定义
 */

import $http from '@/plugins/axios';

// 获取中奖列表
export const api_getPrizeRecordList = (params) => {
    return $http({
        url: '/admin/getPrizeRecordList.html',
        method: 'GET',
        params
    });
};

// 查看中奖信息
export const api_getPrizeInfo = (params) => {
    return $http({
        url: '/admin/getPrizeWinInfo.html',
        method: 'GET',
        params
    });
};


/** ============================ 二维码 ============================ */
// 获取二维码列表
export const api_getQRCodeList = (params) => {
    return $http({
        url: '/admin/getQrCodeList.html',
        method: 'GET',
        params
    });
};

// 创建二维码
export const api_createQRCode = (data) => {    
    return $http({
        url: '/admin/createByCourseQrCode.html',
        method: 'POST',
        data
    });
};

// 删除二维码
export const api_deleteQRCode = (params) => {    
    return $http({
        url: '/admin/cancelQrCode.html',
        method: 'GET',
        params
    });
};


/** ============================ 抽奖 ============================ */
// 获取 抽奖活动 列表
export const api_getPrizeList = (params) => {
    return $http({
        url: '/admin/getPrizeList.html',
        method: 'GET',
        params
    });
};

// 添加抽奖活动
export const api_addPrize = (data) => {    
    return $http({
        url: '/admin/addPrize.html',
        method: 'POST',
        data
    });
};

// 删除抽奖活动
export const api_deletePrize = (params) => {    
    return $http({
        url: '/admin/cancelPrize.html',
        method: 'GET',
        params
    });
};

// 编辑抽奖活动
export const api_updatePrize = (data) => {
    return $http({
        url: '/admin/editPrize.html',
        method: 'POST',
        data
    });
};


// 获取 某个抽奖活动 的 奖项 列表
export const api_getPrizeItemList = (params) => {
    return $http({
        url: '/admin/getPrizeSetting.html',
        method: 'GET',
        params
    });
};

// 设置奖项
export const api_setPrize = (data) => {
    return $http({
        url: '/admin/addPrizeSetting.html',
        method: 'POST',
        data
    });
};

// 编辑奖项
export const api_updatePrizeSetting = (data) => {
    return $http({
        url: '/admin/editPrizeSetting.html',
        method: 'POST',
        data
    });
};

// 删除奖项
export const api_deletePrizeSetting = (params) => {
    return $http({
        url: '/admin/cancelPrizeSetting.html',
        method: 'GET',
        params
    });
};

// 获取公司列表
export const api_getCompanyList = (params) => {
    return $http({
        url: '/admin/getCompanyList.html',
        method: 'GET',
        params
    });
};