<template>
    <div id="pageLayout">
        <div class="page-layout__header">
            <div>
                <div class="title">{{title}}</div>
                <div class="content" v-if="content">
                    <slot name="content">{{ content }}</slot>
                </div>
            </div>
            <div>
                <slot name="extend"></slot>
            </div>
        </div>

        <div class="page-layout__body">
            <el-main>
                <slot>

                </slot>
            </el-main>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Layout',

    props: {
        title: {
            type: String,
            default() {
                return '';
            }
        },

        content: {
            type: String,
            default() {
                return '';
            }
        },

        // 是否显示右侧模块
        rightBlock: {
            type: Boolean,
            default() {
                return false;
            }
        },

        // 传递参数
        parameter: {
            type: Object,
            default() {
                return {};
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.page-layout {
    &__header {
        background: #fff;
        padding: 15px 20px;
        border-bottom: 1px solid #e8eaec;
        display: flex;
        justify-content: space-between;
        align-items: center;
        letter-spacing: 2px;
        .title {
            font-size: 22px;
        }
        .content {
            color: #999;
            font-size: 12px;
            margin-top: 10px;
        }
    }

    &__body {
        display: flex;
        
        .charts {
            width: 320px;
            padding: 20px 20px 20px 0;
            box-sizing: content-box;
        }
    }
}
</style>
