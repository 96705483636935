<template>
    <div>
        <el-descriptions :column="2" border>
            <el-descriptions-item label="头像" :span="2">
                <img :src="basicData.avatar" width="80" height="80" />   
            </el-descriptions-item>
            <el-descriptions-item label="昵称">{{ basicData.userName }}</el-descriptions-item>
            <el-descriptions-item label="真实姓名">{{ basicData.realName }}</el-descriptions-item>
            <el-descriptions-item label="性别">{{ basicData.genderString }}</el-descriptions-item>
            <el-descriptions-item label="出生日期">{{ basicData.birthday }}</el-descriptions-item>
            <el-descriptions-item label="身份证号码">{{ basicData.idCard }}</el-descriptions-item>
            <el-descriptions-item label="身份">{{ basicData.roleName }}</el-descriptions-item>
            <el-descriptions-item label="电话号码">{{ basicData.tel }}</el-descriptions-item>
            <el-descriptions-item label="邮箱">{{ basicData.email }}</el-descriptions-item>
            <el-descriptions-item label="所在城市">{{ basicData.cityName }}</el-descriptions-item>
            <el-descriptions-item label="参加工作时间">{{ basicData.startJobTime }}</el-descriptions-item>
            <el-descriptions-item label="状态">{{ basicData.statusString }}</el-descriptions-item>
            <el-descriptions-item label="最后登录时间">{{ basicData.lastLoginTime }}</el-descriptions-item>
        </el-descriptions>
    </div>
</template>

<script>
import { getMemberBasicDataApi } from '@/api/member';

export default {
    props: {
        userId: {
            type: String
        }
    },

    data() {
        return {
            loading: false,

            basicData: {}
        };
    },

    mounted() {
        this.loading = true;
        this.getBasicData();
    },

    methods: {
        getBasicData() {
            getMemberBasicDataApi({ userId: this.userId }).then(res => {
                this.loading = false;
                if (res.data) {
                    this.basicData = res.data;
                }
            }).catch(() => {
                this.loading = false;
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.basic-form {
    ::v-deep.el-form-item {
        margin-bottom: 0;
    }
}

.cus-uploader {
    ::v-deep.el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &:hover {
            border-color: #409EFF;
        }
    }

    .uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .thumb {
        width: 178px;
        height: 178px;
        display: block;
    }
}
</style>