<template>
    <el-drawer
        :title="drawerTitle"
        :visible.sync="drawerVisible"
        :size="600"
        :append-to-body="true"
        :modal-append-to-body="true"
        :close-on-press-escape="false"
        :wrapperClosable="false"
        :destroy-on-close="true"
        class="custom-drawer question-drawer"
    >
        <el-form :model="form" :rules="rules" ref="formRef">
            <el-form-item label="抽奖主题名称" prop="prizeName">
                <el-input v-model="form.prizeName" />
            </el-form-item>
            <el-form-item label="活动有效期" prop="effectiveType">
                <el-radio-group v-model="form.effectiveType" @change="handleEffectiveTypeChange">
                    <el-radio :label="0">长期有效</el-radio>
                    <el-radio :label="1">指定时间段</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="活动时间段" required>
                <el-row :gutter="20">
                    <el-col :span="10">
                        <el-form-item prop="effectiveStartTime">
                            <el-date-picker
                                v-model="form.effectiveStartTime"
                                type="datetime"
                                placeholder="选择开始时间"
                                :disabled="form.effectiveType === 0"
                                value-format="timestamp"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="1">-</el-col>
                    <el-col :span="10">
                        <el-form-item prop="effectiveEndTime">
                            <el-date-picker
                                v-model="form.effectiveEndTime"
                                type="datetime"
                                placeholder="选择结束时间"
                                :disabled="form.effectiveType === 0"
                                value-format="timestamp"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="中奖概率" prop="probability">
                <el-input-number v-model="form.probability" :min="1" :max="100" />
            </el-form-item>
            <el-form-item label="备注信息">
                <el-input v-model="form.note" type="textarea" />
            </el-form-item>
        </el-form>

        <div class="el-drawer__footer">
            <el-button @click="handleCancel('formRef')">取 消</el-button>
            <el-button type="primary" :loading="submitLoading" :disabled="submitDisabled" @click="handleConfirm('formRef')">确 定</el-button>
        </div>
    </el-drawer>
</template>

<script>
import { api_addPrize, api_updatePrize } from '@/api/lottery';

export default {
    name: 'ModifyPrize',
    
    props: {
        updateFn: {
            type: Function
        }
    },

    data() {
        return {
            drawerTitle: '添加抽奖主题',
            drawerVisible: true,
            form: {
                prizeName: '',
                effectiveType: 0,
                effectiveEndTime: '', 
                effectiveStartTime: '',
                probability: 30,
                note: ''
            },
            rules: {
                prizeName: [{ required: true, message: '请输入奖项名称', trigger: 'blur' }],
                effectiveType: [{ required: true, message: '请选择有效期类型', trigger: 'change' }],
                probability: [{ required: true, message: '请填写中奖概率', trigger: 'change' }]
            },

            submitLoading: false,
            submitDisabled: false
        };
    },

    mounted() {
        this.$eventBus.$on('OPEN_PRIZE_DRAWER', rowData => {
            this.drawerTitle = rowData ? '编辑抽奖主题' : '添加抽奖主题';
            if (rowData) {
                const { prizeId, prizeName, effectiveType, effectiveEndTime, effectiveStartTime, probability, note } = rowData;
                this.form = {
                    prizeId,
                    prizeName,
                    effectiveType,
                    effectiveEndTime: effectiveEndTime * 1000, 
                    effectiveStartTime: effectiveStartTime * 1000,
                    probability,
                    note
                };
            } else {
                this.form = { 
                    prizeName: '',
                    effectiveType: 0,
                    effectiveEndTime: '', 
                    effectiveStartTime: '',
                    probability: 30,
                    note: ''
                };
            }

            this.openDialog();
        });
    },

    methods: {
        openDialog() {            
            this.drawerVisible = true;
        },

        closeDialog() {
            this.drawerVisible = false;
        },

        handleEffectiveTypeChange(val) {
            if (val === 0) {
                this.rules['effectiveEndTime'] = [];
                this.rules['effectiveStartTime'] = [];
            } else {
                this.rules['effectiveEndTime'] = [{ required: true, message: '请选择开始时间', trigger: 'change' }];
                this.rules['effectiveStartTime'] = [{ required: true, message: '请选择结束时间', trigger: 'change' }];
            }
        },

        // 取消
        handleCancel(formName) {
            this.$refs[formName].resetFields();
            this.closeDialog();
        },

        // 确定
        handleConfirm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitLoading = true;
                    this.submitDisabled = true;

                    const payload = this.form;
                    const { effectiveType, effectiveEndTime, effectiveStartTime } = this.form;
                    if (effectiveType === 0) {
                        payload.effectiveEndTime = 0;
                        payload.effectiveStartTime = 0;
                    } else {
                        payload.effectiveEndTime = effectiveEndTime / 1000;
                        payload.effectiveStartTime = effectiveStartTime / 1000;
                    }

                    if (this.form.prizeId) {
                        api_updatePrize(payload).then(() => {
                            this.submitLoading = false;
                            this.submitDisabled = false;

                            this.$message({
                                type: 'success',
                                message: '操作成功！'
                            });

                            this.$emit('updateFn');
                        }).catch(() => {
                            this.submitLoading = false;
                            this.submitDisabled = false;
                        });
                    } else {
                        api_addPrize(payload).then(res => {
                            this.submitLoading = false;
                            this.submitDisabled = false;

                            this.$message({
                                type: 'success',
                                message: '操作成功！'
                            });

                            this.$emit('updateFn');
                        }).catch(() => {
                            this.submitLoading = false;
                            this.submitDisabled = false;
                        });
                    }

                    this.closeDialog(formName);
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.question-drawer {
    ::v-deep.el-form-item__label {
        width: 100%;
        text-align: left;
    }
}
</style>
