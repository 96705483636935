<template>
    <Layout title="数据仪表板">
        <!-- <el-row :gutter="20" class="dashboard-grid">
            <el-col :span="6" v-for="(item, index) in gridData" :key="index">
                <div class="dashboard-grid__cell">
                    <div class="figure">
                        <svg-icon :name="item.icon" :style="{'color': item.color}" />
                    </div>
                    <div class="content">
                        <div class="val">
                            <span class="num">{{ item.value }}</span>
                            <span class="unit">{{ item.unit }}</span>
                        </div>
                        <div class="key">{{ item.name }}</div>
                    </div>
                </div>
            </el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :span="14">                
                <div class="dashboard-map">
                    <div id="jSiteMap" style="height: 560px"></div>
                </div>
            </el-col>
            <el-col :span="10">
                <div class="dashboard-chart">
                    <div id="jIncomeChart" style="height:260px;"></div>
                </div>

                <div class="dashboard-chart" style="margin-top:20px;">
                    <div id="" style="height:240px;" />
                </div>
            </el-col>
        </el-row> -->
    </Layout>
</template>

<script>
import * as echarts from 'echarts';
import { chinaJson } from './map';

export default {
    name: 'dashboard',

    data() {
        return {
            gridData: [
                { name: '站点总数', value: 20, unit: '个', icon: 'ds-site', color: '#e19af3' },
                { name: '设备总数', value: 150, unit: '台', icon: 'ds-device', color: '#60f367' },
                { name: '用户数量', value: 300, unit: '人', icon: 'ds-user', color: '#6076f3' },
                { name: '总收入', value: 500000, unit: '元', icon: 'ds-income', color: '#f58c38' }
            ]
        };
    },

    methods: {
        // 初始化地图
        __initMapChart() {
            // 从服务器获取的数据
            const data = [
                { name: '天津', value: 20 },
                { name: '广东', value: 15 },
                { name: '武汉', value: 30 },
                { name: '重庆', value: 40 },
                { name: '四川', value: 120 },
                { name: '上海', value: 60 }
            ];

            // 从地图数据中获取对应省份的坐标数据
            const geoCoordMap = {};
            chinaJson.features.forEach(item => {
                const { name, cp } = item.properties;
                geoCoordMap[name] = cp;
            });

            // 构造地图上散点数据
            const convertData = data => {
                const res = [];
                for (let i = 0; i < data.length; i++) {
                    const geoCoord = geoCoordMap[data[i].name];
                    if (geoCoord) {
                        res.push({
                            name: data[i].name,
                            value: geoCoord.concat(data[i].value)
                        });
                    }
                }
                return res;
            };
            
            // 按照从大到小排序
            data.sort(function(o1, o2) {
                if (isNaN(o1.value) || o1.value == null) return -1;
                if (isNaN(o2.value) || o2.value == null) return 1;
                return o1.value - o2.value;
            });

            echarts.registerMap('china', chinaJson);

            this.siteMap = echarts.init(document.getElementById('jSiteMap'));

            const option = {
                title: {
                    text: '站点设备分布图',
                    textStyle: {
                        fontSize: 20
                    },
                    x: 'left'
                },
                tooltip: {
                    show: true,
                    formatter: function(params) {
                        return params.name + '：' + params.data.value + ' 台';
                    }
                },
                grid: {
                    right: 10,
                    top: 80,
                    bottom: 30,
                    width: '20%'
                },
                xAxis: {
                    type: 'value',
                    scale: true,
                    position: 'top',
                    splitNumber: 1,
                    boundaryGap: false,
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        margin: 2,
                        textStyle: {
                            color: '#aaa'
                        }
                    }
                },
                yAxis: {
                    type: 'category',
                    nameGap: 16,
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#ddd'
                        }
                    },
                    axisTick: {
                        show: false,
                        lineStyle: {
                            color: '#ddd'
                        }
                    },
                    axisLabel: {
                        interval: 0,
                        textStyle: {
                            color: '#999'
                        }
                    },
                    data: data.map(item => item.name)
                },
                geo: {
                    roam: false,
                    map: 'china',
                    left: 'left',
                    right: '280',
                    layoutSize: '80%',
                    label: {
                        normal: {
                            show: true
                        },
                        emphasis: {
                            show: true
                        }
                    },
                    itemStyle: {
                        normal: {
                            borderColor: 'rgba(147, 235, 248, 1)',
                            borderWidth: 1,
                            areaColor: {
                                type: 'radial',
                                x: 0.5,
                                y: 0.5,
                                r: 0.8,
                                colorStops: [{
                                    offset: 0,
                                    color: 'rgba(147, 235, 248, 0)' // 0% 处的颜色
                                }, {
                                    offset: 1,
                                    color: 'rgba(147, 235, 248, .5)' // 100% 处的颜色
                                }],
                                globalCoord: false // 缺省为 false
                            },
                            shadowColor: 'rgba(128, 217, 248, 1)',
                            // shadowColor: 'rgba(255, 255, 255, 1)',
                            shadowOffsetX: -2,
                            shadowOffsetY: 2,
                            shadowBlur: 10
                        },
                        emphasis: {
                            areaColor: '#389BB7',
                            borderWidth: 0
                        }
                    }
                },
                series: [{
                    type: 'bar',
                    roam: false,
                    barMaxWidth: 20,
                    itemStyle: {
                        normal: {
                            color: '#40a9ed'
                        },
                        emphasis: {
                            color: '#3596c0'
                        }
                    },
                    label: {
                        normal: {
                            show: true,
                            position: 'right',
                            offset: [10, 1]
                        },
                        emphasis: {
                            show: true,
                            position: 'right',
                            offset: [10, 1]
                        }
                    },
                    data: data
                }, {
                    type: 'effectScatter',
                    coordinateSystem: 'geo',
                    geoIndex: 0,
                    symbolSize: function(val) {
                        return val[2] / 6;
                    },
                    showEffectOn: 'render',
                    rippleEffect: {
                        scale: 3,
                        brushType: 'stroke'
                    },
                    hoverAnimation: true,
                    label: {
                        normal: {
                            formatter: '{b}',
                            position: 'right',
                            show: false
                        },
                        emphasis: {
                            show: true
                        }
                    },
                    itemStyle: {
                        normal: {
                            color: '#f9b207'
                        }
                    },
                    data: convertData(data)
                }]
            };

            this.siteMap.setOption(option);
        },

        // 初始化站点收益
        __initIncomeChart() {
            const chartData = [{
                name: '成都站点一',
                value: 10
            }, {
                name: '成都站点二',
                value: 20
            }, {
                name: '成都站点三',
                value: 23
            }, {
                name: '成都站点四',
                value: 50
            }, {
                name: '成都站点五',
                value: 40
            }];

            chartData.sort((a, b) => {
                return b.value - a.value;
            });

            this.incomeChart = echarts.init(document.getElementById('jIncomeChart'));

            const top10CityList = chartData.map(item => item.name);
            const top10CityData = chartData.map(item => item.value);

            const color = ['#ff9500', '#02d8f9', '#027fff'];
            const color1 = ['#ffb349', '#70e9fc', '#4aa4ff'];

            const lineT = [];
            const lineY = [];
            for (let i = 0; i < top10CityList.length; i++) {
                let x = i;
                if (x > 1) {
                    x = 2;
                }
                const data = {
                    name: top10CityList[i],
                    color: color[x],
                    value: top10CityData[i],
                    barGap: '-100%',
                    itemStyle: {
                        normal: {
                            show: true,
                            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                offset: 0,
                                color: color[x]
                            }, {
                                offset: 1,
                                color: color1[x]
                            }], false),
                            barBorderRadius: 10
                        },
                        emphasis: {
                            shadowBlur: 15,
                            shadowColor: 'rgba(0, 0, 0, 0.1)'
                        }
                    }
                };
                const data1 = {
                    value: top10CityData[0]
                };
                lineY.push(data);
                lineT.push(data1);
            }

            const option = {
                backgroundColor: 'transparent',
                title: {
                    text: '站点收益TOP5',
                    textStyle: {
                        fontSize: 20
                    },
                    x: 'left'
                },
                tooltip: {
                    show: false,
                    trigger: 'item',
                    formatter: (p) => {
                        if (p.seriesName === 'total') {
                            return '';
                        }
                        return `${p.name}<br/>${p.value}`;
                    }
                },
                grid: {
                    borderWidth: 0,
                    top: '20%',
                    left: '1.5%',
                    right: '2%',
                    bottom: '4%'
                },
                color: color,

                xAxis: {
                    type: 'value',
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    }
                },

                yAxis: [{
                    type: 'category',
                    inverse: true,
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                        inside: false
                    },
                    data: top10CityList
                }, {
                    type: 'category',
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: true,
                        inside: false,
                        verticalAlign: 'bottom',
                        align: 'right',
                        // lineHeight: '40',
                        padding: [0, 25, 6, -50], // 文字与柱状图的距离
                        textStyle: {
                            color: '#333',
                            fontSize: '12',
                            fontFamily: 'PangMenZhengDao'
                        },
                        formatter: function(val) {
                            return `收益：${val} 万元`;
                        }
                    },
                    splitArea: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    },
                    data: top10CityData
                }],

                series: [{
                    name: 'total',
                    type: 'bar',
                    zlevel: 1,
                    barGap: '-100%',
                    barWidth: '6px',
                    itemStyle: {
                        color: '#f0f0f0',
                        barBorderRadius: 10
                    },
                    data: lineT,
                    legendHoverLink: false
                }, {
                    name: 'bar',
                    type: 'bar',
                    zlevel: 2,
                    barWidth: '6px',
                    data: lineY,
                    label: {
                        normal: {
                            color: '#b3ccf8',                            
                            show: true,
                            distance: 20,
                            position: [0, '-16px'],
                            textStyle: {
                                fontSize: 16
                            },
                            formatter: function(a) {
                                let num = '';
                                let str = '';
                                if (a.dataIndex + 1 < 10) {
                                    num = '0' + (a.dataIndex + 1);
                                } else {
                                    num = (a.dataIndex + 1);
                                }
                                if (a.dataIndex === 0) {
                                    str = `{color1|${num}} {color4|${a.name}}`;
                                } else if (a.dataIndex === 1) {
                                    str = `{color2|${num}} {color4|${a.name}}`;
                                } else {
                                    str = `{color3|${num}} {color4|${a.name}}`;
                                }
                                return str;
                            },
                            rich: {
                                color1: {
                                    color: '#ff9500',
                                    fontFamily: 'PangMenZhengDao',
                                    fontWeight: 700
                                },
                                color2: {
                                    color: '#02d8f9',
                                    fontFamily: 'PangMenZhengDao',
                                    fontWeight: 700
                                },
                                color3: {
                                    color: '#027fff',
                                    fontFamily: 'PangMenZhengDao',
                                    fontWeight: 700
                                },
                                color4: {
                                    color: '#333',
                                    fontFamily: 'PangMenZhengDao'
                                }
                            }
                        }
                    }
                }],
                animationEasing: 'cubicOut'
            };

            this.incomeChart.setOption(option);
        }
    },

    mounted() {
        // this.__initMapChart();

        // this.__initIncomeChart();
    }
};
</script>

<style lang="scss" scoped>
.dashboard-grid {
    margin-bottom: 20px;
    &__cell {
        background-color: #fff;
        border-radius: 10px;
        padding: 20px 30px;
        display: flex;
        align-items: center;
        box-shadow: 0 0 5px 1px rgba(200, 200, 200, .5);
        cursor: pointer;
        .figure {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: #f2f2f2;
            margin-right: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            .svg-icon {
                font-size: 30px;
            }
        }
        .content {
            color: #333;
            .key {
                font-size: 15px;   
                margin-top: 6px;             
            }
            .val {
                .num {
                    font-size: 24px;
                    font-weight: bold;
                    margin-right: 5px;
                }
                .unit {
                    font-size: 14px;
                }
            }
        }
    }
}
.dashboard-map {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 5px 1px rgba(200, 200, 200, .5);
    border-radius: 8px;
}
.dashboard-chart {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 5px 1px rgba(200, 200, 200, .5);
    border-radius: 8px;
}
</style>
