<template>
    <Layout title="文章列表">  
        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-search"></i>
                <span class="title">筛选条件</span>
            </template>
            <el-form :model="queryForm" inline ref="queryFormRef" size="small">
                <el-form-item label="关键字" prop="keywords">
                    <el-input v-model="queryForm.keywords" placeholder="标题或简介" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="handleQuery">确定</el-button>
                    <el-button type="default" icon="el-icon-refresh-left" @click="handleReset('queryFormRef')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-s-grid"></i>
                <span class="title">数据列表</span>
                <el-button-group>
                    <el-button type="success" icon="el-icon-plus" @click="handleCreate" size="small">创建文章</el-button>
                </el-button-group>
            </template>

            <el-table :data="tableData" v-loading="loading">
                <el-table-column type="index" width="50"></el-table-column>               
                <el-table-column prop="title" label="标题" width="200"></el-table-column> 
                <el-table-column label="封面" align="center">
                    <template slot-scope="scope">
                        <img :src="scope.row.cover" width="100" />
                    </template>
                </el-table-column>    
                <el-table-column prop="summary" label="摘要" align="center" width="400"></el-table-column>
                <el-table-column label="文章类型" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.articleParentCatString">{{ scope.row.articleParentCatString }} > </span>
                        {{ scope.row.articleCatString }}
                    </template>
                </el-table-column> 
                <el-table-column prop="createUserName" label="发布者"></el-table-column>       
                <el-table-column prop="" label="状态" align="center">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.status === 1 ? 'success' : 'warning'">{{ scope.row.status === 1 ? '已发布' : '待发布' }}</el-tag>
                    </template>
                </el-table-column> 
                <el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column>
                <el-table-column fixed="right" label="操作" width="260" align="center">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button 
                                type="success" 
                                @click.native="handlePublish(scope.row)" 
                                size="small"
                                v-if="scope.row.status === 0"
                            >发布</el-button>
                            <el-button 
                                type="default" 
                                @click.native="handleUnPublish(scope.row)" 
                                size="small"
                                v-else
                            >取消发布</el-button>
                            <el-button 
                                type="primary" 
                                icon="el-icon-edit" 
                                @click.native="handleEdit(scope.row)" 
                                size="small"
                            >编辑</el-button>
                            <el-button 
                                type="danger" 
                                icon="el-icon-delete" 
                                @click.native="handleRemove(scope.row)" 
                                size="small"
                            >删除</el-button>    
                        </el-button-group>                                           
                    </template>
                </el-table-column>
            </el-table>

            <div class="el-card__footer">
                <div></div>
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :page-count="totalPage"
                    @current-change="handleCurrentPageChange"
                ></el-pagination> 
            </div>
        </el-card>
    </Layout>
</template>

<script>
import { 
    getArticleListApi,
    deleteArticleApi,
    publishArticleApi,
    unPublishArticleApi
} from '@/api/article';

export default {
    data() {
        return {
            queryForm: {
                pageSize: 10,
                pageNum: 1,
                keywords: ''
            },

            loading: false,

            tableData: [],
            totalPage: 1
        };
    },

    methods: {
        // 获取数据
        getData() {
            this.loading = true;

            getArticleListApi(this.queryForm).then(res => {
                const { allNum, data } = res.data;

                this.tableData = data;

                this.totalPage = Math.ceil(allNum / this.form.pageSize);

                this.loading = false;
            }).catch(() => {
                this.loading = false;
            });
        },

        // 搜索
        handleQuery() {
            this.getData();
        },

        // 重置
        handleReset(formName) {
            console.log(formName);
            this.$refs[formName].resetFields();
        },

        // 分页
        handleCurrentPageChange(e) {
            this.getData({ pager: e });
        },

        // 新建
        handleCreate() {
            this.$router.push('/article/modify');
        },

        // 发布文章
        handlePublish(rowData) {
            const { id } = rowData;

            publishArticleApi({ articleId: id }).then(res => {
                this.$message({
                    type: 'success',
                    message: '发布成功'
                });
                this.getData();
            });
        },

        // 取消文章发布
        handleUnPublish(rowData) {
            const { id } = rowData;

            unPublishArticleApi({ articleId: id }).then(res => {
                this.$message({
                    type: 'success',
                    message: '取消发布成功'
                });
                this.getData();
            });
        },

        // 编辑
        handleEdit(rowData) {
            const { id } = rowData;
            this.$router.push({
                path: '/article/modify',
                query: {
                    id
                }
            });
        },

        // 删除
        handleRemove(rowData) {
            const { id } = rowData;

            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteArticleApi({ articleId: id }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    });
                    setTimeout(() => {
                        this.getData();
                    }, 1000); 
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        }
    },

    mounted() {
        this.getData();
    }
};
</script>
