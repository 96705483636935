<template>
    <div style="position:relative;border: 1px solid #ddd; margin-top: 10px">
        <Toolbar
            style="border-bottom: 1px solid #ddd"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
        />
        <Editor
            style="height: 500px; overflow-y: hidden;"
            v-model="html"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
        />
    </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import '@wangeditor/editor/dist/css/style.css';

// import { uploadImg, uploadVideo } from "@/api/upload";

export default {

    name: 'RichTextEditor',

    components: {
        Toolbar,
        Editor
    },

    props: {
        content: {
            type: String
        }
    },

    data() {
        return {
            editor: null,
            html: '<p>hello</p>',
            toolbarConfig: { },
            mode: 'default', // or 'simple'

            editorConfig: {
                placeholder: '请输入内容...',
                MENU_CONF: {
                    uploadImage: {
                        // server: `${import.meta.env.VITE_APP_BASE_API}${uploadImgUrl}`,
                        // fieldName: 'file',
                        customUpload(file, insertFn) {
                            // Content-Type: multipart/form-data;
                            let formData = new FormData();
                            formData.append("file", file);

                            const loadingHandler = this.$loading({
                                text: '图片上传中...'
                            });
                            return new Promise((resolve, reject) => {
                                uploadImg(formData).then(res => {
                                    // {
                                    //     fileName: "common/image/dbtkkx1668137368096.png"
                                    //     filePath: "common/authImage/dbtkkx1668137368096.png"
                                    //     imageUrl: "https://panda-env-test.s3.ap-northeast-1.amazonaws.com/"
                                    // }
                                    loadingHandler.close();
                                    this.$message({
                                        type: 'success',
                                        message: `${file.name}上传成功`
                                    });
                                    const { fileName, imageUrl } = res.data;
                                    // Simulate async insert image
                                    insertFn(`${imageUrl}${fileName}`, file.name);
                                    resolve('ok');
                                }).catch(e => {
                                    loadingHandler.close();
                                    this.$message({
                                        type: 'success',
                                        message: `${file.name}上传失败`
                                    });
                                    reject(e);
                                });

                            });
                        },
                    }
                }
            },

            timer: null
        };
    },

    methods: {
        onCreated(editor) {
            this.editor = Object.seal(editor);
        },

        handleChange(editor) {
            if (this.timer) clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                // console.log('change:', editor.getHtml());
                this.$emit('update:htmlContent', editor.getHtml());
            }, 500);
        },

        customPaste(editor, event, callback) {
            // console.log('ClipboardEvent 粘贴事件对象', event);

            // 自定义插入内容
            // editor.insertText('xxx');

            // 返回值（注意，vue 事件的返回值，不能用 return）
            // callback(false); // 返回 false ，阻止默认粘贴行为
            callback(true); // 返回 true ，继续默认的粘贴行为
        }
    },

    mounted() {
        setTimeout(() => {
            this.valueHtml = this.content;
        }, 500);
    },

    beforeDestroy() {
        const editor = this.editor;
        if (editor == null) return;
        editor.destroy(); // 组件销毁时，及时销毁编辑器
    }
};
</script>
  