/**
 * @ 路由配置
 * @ 积分管理
 * @ author: LeiHao
 * @ createTime: 2023/08/08
 */
import Layout from '@/layout/Index';
 
export default {
    path: '/points',
    component: Layout,
    name: 'points',
    meta: {
        title: '积分管理',
        icon: 'sidebar-points',
        permissionCode: 'points'
    },
    children: [
        {
            path: '',
            component: () => import('@/views/points/Index'),
            name: 'points-list',
            meta: { 
                title: '积分列表', 
                permissionCode: 'points:list'
            }
        }/* ,
        {
            path: '/papers',
            component: () => import('@/views/exam/Papers'),
            name: 'exam-papers',
            meta: { 
                title: '课程列表', 
                permissionCode: 'exam:papers'
            }
        } */
    ]
};
