<template>
    <Layout title="用户课程">        
        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-search"></i>
                <span class="title">搜索条件</span>
            </template>
            <el-form :model="queryForm" inline ref="queryFormRef" size="small">
                <el-form-item label="名称" prop="keyWord">
                    <el-input v-model="queryForm.keyWord" />
                </el-form-item>
                <el-form-item label="举办单位" prop="organizer">
                    <el-select v-model="queryForm.organizer">
                        <el-option
                            v-for="item in optionsData.organizerList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="培训类型" prop="training">
                    <el-select v-model="queryForm.training">
                        <el-option
                            v-for="item in optionsData.trainingTypeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="课程类型" prop="cat">
                    <el-select v-model="queryForm.cat">
                        <el-option
                            v-for="item in optionsData.catList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-select v-model="queryForm.status">
                        <el-option label="全部" value="" />
                        <el-option label="已发布" :value="1" />
                        <el-option label="未发布" :value="0" />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleQuery">确定</el-button>
                    <el-button type="default" @click="handleReset('queryFormRef')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-s-grid"></i>
                <span class="title">数据列表</span>
            </template>

            <el-table :data="tableData" v-loading="loading">
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column prop="userName" label="用户名称" align="center"></el-table-column>   
                <el-table-column prop="courseName" label="课程名称" align="center"></el-table-column>
                <el-table-column label="图片" align="center">
                    <template slot-scope="scope">
                        <img :src="scope.row.cover" width="80" height="80" />
                    </template>
                </el-table-column>
                <el-table-column prop="trainingTypeString" label="课程类型" align="center"></el-table-column> 
                <el-table-column prop="catString" label="课程分类" align="center"></el-table-column>                              
                <el-table-column prop="organizerString" label="举办单位" align="center"></el-table-column>   
                <el-table-column prop="statusString" label="状态" align="center"></el-table-column>         
                <el-table-column fixed="right" label="操作" width="90" align="center">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button type="primary" icon="el-icon-view" size="mini" @click.native="handleView(scope.row)">查看</el-button>
                        </el-button-group>                                            
                    </template>
                </el-table-column>
            </el-table>

            <div class="el-card__footer">
                <div></div>
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :page-count="totalPage"
                    @current-change="handleCurrentPageChange"
                ></el-pagination> 
            </div>
        </el-card>

        <!-- 查看课程信息弹窗 -->
        <el-dialog
            title="用户课程信息"
            :visible.sync="dialogVisible"
            width="65%"
            class="custom-dialog"
        >
            <div style="min-height:400px" v-loading="infoLoading">
                <el-descriptions :column="2" border>
                    <el-descriptions-item label="课程封面" :span="2">
                        <img :src="courseData.cover" width="80" height="80" />   
                    </el-descriptions-item>
                    <el-descriptions-item label="用户名称">{{ courseData.userName }}</el-descriptions-item>
                    <el-descriptions-item label="课程名称">{{ courseData.courseName }}</el-descriptions-item>
                    <el-descriptions-item label="课程类型">{{ courseData.trainingTypeString }}</el-descriptions-item>
                    <el-descriptions-item label="课程分类">{{ courseData.catString }}</el-descriptions-item>
                    <el-descriptions-item label="举办单位">{{ courseData.organizerString }}</el-descriptions-item>
                    <el-descriptions-item label="状态">{{ courseData.statusString }}</el-descriptions-item>
                </el-descriptions>
                <h4 style="margin:20px 0 8px;">课件信息</h4>
                <el-table :data="courseData.coursewareList" v-loading="infoLoading" border>
                    <el-table-column prop="name" label="课件名称"></el-table-column>
                    <el-table-column prop="learningSpendTime" label="课时" align="center"></el-table-column>   
                    <el-table-column label="学习进度" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.learningRate }}%
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </Layout>
</template>

<script>
import { getUserCourseListApi, getUserCourseInfoApi } from '@/api/course';

export default {
    data() {
        return {
            queryOptions: {},

            queryForm: {
                pageSize: 10,
                currentPage: 1,
                keyWord: '',
                organizer: '',
                training: '',
                cat: '',
                status: ''

            },

            loading: false,

            tableData: [],
            totalPage: 1,

            dialogVisible: false,
            infoLoading: false,
            courseData: {}
        };
    },

    computed: {
        optionsData() {
            return this.$store.state.course.optionsData;
        }
    },

    methods: {
        // 获取数据
        getData() {
            getUserCourseListApi(this.queryForm).then(res => {
                if (res.data) {
                    const { allNum, listData } = res.data;
                    this.tableData = listData;
                    this.totalPage = Math.ceil(allNum / this.queryForm.pageSize);
                }
            });
        },

        // 搜索
        handleQuery() {
            this.getData();
        },

        // 重置
        handleReset(formName) {
            console.log(formName);
            this.$refs[formName].resetFields();
        },

        // 分页
        handleCurrentPageChange(e) {
            this.queryForm.currentPage = e;
            this.getData();
        },

        // 查看
        handleView(rowData) {
            this.dialogVisible = true;

            this.infoLoading = true;
            const { userCourseId } = rowData;
            getUserCourseInfoApi({ userCourseId }).then(res => {
                this.infoLoading = false;
                if (res.data) {
                    this.courseData = res.data;
                }
            }).catch(() => {
                this.infoLoading = false;
            });            
        }
    },

    mounted() {
        this.$store.dispatch('course/getCourseOptions');

        this.getData();
    }
};
</script>

<style lang="scss" scoped>
.custom-card__outer {
    .custom-toolbar {
        display: flex;
        align-items: center;
        ::v-deep .el-button {
            margin-right: 20px;
            margin-left: 0;
        }
        .info {
            font-size: 30px;
            color: #f56c6c;
        }
    }
}
</style>
