/**
 * @ 按钮权限控制
 * @ 使用方法 <el-button v-auth="权限代码"></el-button>
 * @ 实现思路：
 *      1、将后端返回的权限列表存入vuex中
 *      2、获取按钮上的权限代码
 *      3、将按钮上传入的权限代码和vuex中保存的权限数据进行对比，如果不存在，则移除按钮元素
 */

export default (Vue) => {
    Vue.directive('auth', {
        inserted(el, binding, vnode) {
            // 从vnode.context中获取vuex存储的权限列表
            const permissions = vnode.context.$store.state.user.permissions;

            // 获取按钮上传入的权限代码
            const buttonPermission = binding.value;

            // 如果权限列表中不包括按钮中传入的权限代码，则移除这个按钮元素
            if (!permissions.includes(buttonPermission)) {
                el.parentNode.removeChild(el);
            }
        }
    });
};
