<template>
    <Layout title="积分列表">  
        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-search"></i>
                <span class="title">筛选条件</span>
            </template>
            <el-form :model="queryForm" inline ref="queryFormRef" size="small">
                <el-form-item label="关键字" prop="keywords">
                    <el-input v-model="queryForm.keywords" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="handleQuery">确定</el-button>
                    <el-button type="default" icon="el-icon-refresh-left" @click="handleReset('queryFormRef')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-s-grid"></i>
                <span class="title">数据列表</span>
            </template>

            <el-table :data="tableData" v-loading="loading">           
                <el-table-column prop="pointsId" label="ID" align="center"></el-table-column> 
                <el-table-column prop="totalPoints" label="积分" align="center"></el-table-column> 
                <el-table-column prop="userId" label="用户ID" align="center"></el-table-column> 
                <el-table-column prop="updateTime" label="更新时间" align="center"></el-table-column>  
                <el-table-column fixed="right" label="操作" width="120" align="center">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button 
                                type="primary" 
                                icon="el-icon-view" 
                                @click.native="handleView(scope.row)"
                                size="mini"
                            >查看</el-button>
                        </el-button-group>                                             
                    </template>
                </el-table-column>
            </el-table>

            <div class="el-card__footer">
                <div></div>
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :page-count="totalPage"
                    @current-change="handleCurrentPageChange"
                ></el-pagination> 
            </div>
        </el-card>

        <!-- 积分详情 -->
        <el-dialog
            title="积分详情"
            :visible.sync="dialogVisible"
            width="65%"
        >
            <el-table :data="detailTable" v-loading="loading">       
                <el-table-column prop="pointsDetailId" label="ID" width="50" align="center"></el-table-column>     
                <el-table-column prop="orderSn" label="订单号" align="center"></el-table-column> 
                <el-table-column label="积分" align="center">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.typeId === 1 ? 'success' : 'danger'">
                            {{ scope.row.typeId === 1 ? '+' : '-' }} {{ scope.row.endPoints }}
                        </el-tag>                        
                    </template>
                </el-table-column> 
                <el-table-column prop="describe" label="积分说明" align="center"></el-table-column> 
                <el-table-column prop="describe" label="积分类型" align="center">
                    <template slot-scope="scope">
                        {{ ['充值', '购买课程积分'][scope.row.typeId] }}
                    </template>
                </el-table-column> 
                <el-table-column prop="updateTime" label="创建时间" align="center">
                    <template slot-scope="scope">
                        {{ parseTime(scope.row.createTime) }}
                    </template>
                </el-table-column>  
            </el-table>

            <div style="display:flex;justify-content:flex-end;margin-top:20px;">
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :page-count="currentTotalPage"
                    @current-change="handleDialogCurrentPageChange"
                ></el-pagination> 
            </div>
        </el-dialog>
    </Layout>
</template>

<script>
import { getPointsListApi, getPointsDetailApi } from '@/api/points';

export default {
    data() {
        return {
            queryForm: {
                pageSize: 10,
                currentPage: 1,
                keywords: ''
            },

            loading: false,

            tableData: [],
            totalPage: 1,

            dialogVisible: false,
            currentTotalPage: 1,
            dialogPageSize: 10,
            dialogCurrentPage: 1,
            detailTable: [],
            currentPointsId: ''
        };
    },

    methods: {
        // 获取数据
        getData() {
            getPointsListApi(this.queryForm).then(res => {
                if (res.data) {
                    const { allNum, listData } = res.data;

                    this.tableData = listData;
                    this.totalPage = Math.ceil(allNum / this.queryForm.pageSize);
                }
            });
        },

        // 分页
        handleCurrentPageChange(e) {
            this.queryForm.currentPage = e;
            this.getData();
        },

        // 搜索
        handleQuery() {

        },

        // 重置
        handleReset(formName) {
            this.$refs[formName].resetFields();
        },

        // 查看详情
        handleView(rowData) {
            const { pointsId } = rowData;
            this.currentPointsId = pointsId;
            this.dialogVisible = true; 
            this.getDetailList();
        },

        // 获取积分详情
        getDetailList() {
            getPointsDetailApi({ userPointId: this.currentPointsId }).then(res => {
                if (res.data) {
                    const { allNum, listData } = res.data;

                    this.detailTable = listData;
                    this.dialogTotalPage = Math.ceil(allNum / this.currentPageSize);
                }
            });
        },

        // 详情列表分页
        handleDialogCurrentPageChange() {
            this.queryForm.currentPage = e;
            this.getDetailList();
        }
    },

    mounted() {
        this.getData();
    }
};
</script>

<style lang="scss" scoped>
.custom-card__outer {
    .custom-toolbar {
        display: flex;
        align-items: center;
        ::v-deep .el-button {
            margin-right: 20px;
            margin-left: 0;
        }
        .info {
            font-size: 30px;
            color: #f56c6c;
        }
    }
}
</style>
