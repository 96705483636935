/**
 * @ 路由配置
 * @ 发票管理
 * @ author: LeiHao
 * @ createTime: 2023/07/11
 */
import Layout from '@/layout/Index';
 
export default {
    path: '/invoice',
    component: Layout,
    name: 'invoice',
    meta: {
        title: '发票管理',
        icon: 'sidebar-invoice',
        permissionCode: 'invoice'
    },
    children: [
        {
            path: '',
            component: () => import('@/views/invoice/Index'),
            name: 'invoice-index',
            meta: { 
                title: '发票列表', 
                permissionCode: 'invoice:index'
            }
        },
        {
            path: 'fail',
            component: () => import('@/views/invoice/Fail'),
            name: 'invoice-fail',
            meta: { 
                title: '需要手动开票', 
                permissionCode: 'invoice:fail'
            }
        },
        {
            path: 'logs',
            component: () => import('@/views/invoice/Logs'),
            name: 'invoice-logs',
            meta: { 
                title: '开票日志', 
                permissionCode: 'invoice:logs'
            }
        },
        {
            path: '/link',
            component: Layout,
            name: 'external',
            meta: {
                title: '税务管理平台'
            },
            children: [
                {
                    path: 'http://lqzs.kflmgzs.com',
                    meta: {
                        title: '乐企智税管理平台'
                    }
                }
            ]
        }
    ]
};
