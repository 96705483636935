<template>
    <Layout title="相关标准">  
        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-search"></i>
                <span class="title">筛选条件</span>
            </template>
            <el-form :model="queryForm" inline ref="queryFormRef">
                <el-form-item label="关键字" prop="keywords">
                    <el-input v-model="queryForm.keywords" placeholder="标题或简介" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="handleQuery">确定</el-button>
                    <el-button type="default" icon="el-icon-refresh-left" @click="handleReset('queryFormRef')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-s-grid"></i>
                <span class="title">数据列表</span>
                <el-button-group>
                    <el-button type="success" icon="el-icon-plus" @click="handleCreate">添加资料</el-button>
                </el-button-group>
            </template>

            <el-table :data="tableData" v-loading="loading">
                <el-table-column type="index" width="50"></el-table-column>               
                <el-table-column prop="name" label="标题"></el-table-column> 
                <el-table-column prop="mobile" label="简介"></el-table-column>  
                <el-table-column prop="content" label="封面"></el-table-column> 
                <el-table-column prop="" label="预览字数" align="center"></el-table-column>        
                <el-table-column prop="" label="积分兑换价格" align="center"></el-table-column> 
                <el-table-column prop="" label="状态" align="center"></el-table-column> 
                <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
                <el-table-column prop="createTime" label="更新时间" align="center"></el-table-column>
                <el-table-column fixed="right" label="操作" width="180" align="center">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button type="primary" icon="el-icon-edit" @click.native="handleEdit(scope.row)" size="small">编辑</el-button>
                            <el-button type="danger" icon="el-icon-delete" @click.native="handleRemove(scope.row)" size="small">删除</el-button>    
                        </el-button-group>                                           
                    </template>
                </el-table-column>
            </el-table>

            <div class="el-card__footer">
                <div></div>
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :page-count="totalPage"
                    @current-change="handleCurrentPageChange"
                ></el-pagination> 
            </div>
        </el-card>
    </Layout>
</template>

<script>
export default {
    data() {
        return {
            queryForm: {
                keywords: ''
            },

            loading: false,

            tableData: [],
            totalPage: 1
        };
    },

    methods: {
        // 获取数据
        getData() {
            this.tableData = [{
                mobile: '1380000000',
                name: '张三',
                content: ' 安全生产方面:定制化培训,评估评审,隐患排查;   职业健康方面:管家服务,三同时,检测评价,隐患排查,评估评审;   用户留言:您的留言已发送,我们将快与您取得联系您的留言已发送,我们将快与您取得联系您的留言已发送,我们将快与您取得联系您的留言已发送,我们将快与您取得联系您的留言已发送,我们将快与您取得联系您的留言已发送,; ',
                createTime: '2022-12-12'
            }];
        },

        // 搜索
        handleQuery() {

        },

        // 重置
        handleReset(formName) {
            console.log(formName);
            this.$refs[formName].resetFields();
        },

        // 分页
        handleCurrentPageChange(e) {
            this.getData({ pager: e });
        },

        // 新建
        handleCreate() {
            this.$router.push('/datum/laws/modify');
        },

        // 编辑
        handleEit(rowData) {
            const { id } = rowData;
            this.$router.push({
                path: '/datum/laws/modify',
                query: {
                    id
                }
            });
        },

        // 删除
        handleRemove(rowData) {
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除成功'
                }); 
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        }
    },

    mounted() {
        this.getData();
    }
};
</script>
