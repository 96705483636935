/**
 * 路由鉴权
 */

import { deepClone } from '@/utils';

const filterAsyncRoutes = (userRoutes = [], asyncRoutes = []) => {
    /* const accessRoutes = [];

    userRoutes.forEach(userItem => {
        asyncRoutes.forEach(allItem => {
            if (userItem.code === allItem.meta.permissionCode) {  
                if (userItem.children && userItem.children.length > 0) {
                    allItem.children = filterAsyncRoutes(userItem.children, allItem.children);
                } else {
                    allItem.children = [];
                }
                accessRoutes.push(allItem);               
            }
        });
    });

    return accessRoutes; */
    return asyncRoutes;
};

// 将多层嵌套路由处理成平级
function flatAsyncRoutes(routes, breadcrumb, baseUrl = '') {
    const res = [];
    routes.forEach(route => {
        const tmp = { ...route };
        if (tmp.children) {
            let childrenBaseUrl = '';
            if (baseUrl === '') {
                childrenBaseUrl = tmp.path;
            } else if (tmp.path !== '') {
                childrenBaseUrl = `${baseUrl}/${tmp.path}`;
            }
            const childrenBreadcrumb = deepClone(breadcrumb);
            if (route.meta.breadcrumb !== false) {
                childrenBreadcrumb.push({
                    path: childrenBaseUrl,
                    title: route.meta.title
                });
            }
            const tmpRoute = deepClone(route);
            tmpRoute.path = childrenBaseUrl;
            tmpRoute.meta.breadcrumbNeste = childrenBreadcrumb;
            delete tmpRoute.children;
            res.push(tmpRoute);
            const childrenRoutes = flatAsyncRoutes(tmp.children, childrenBreadcrumb, childrenBaseUrl);
            childrenRoutes.map(item => {
                // 如果 path 一样则覆盖，因为子路由的 path 可能设置为空，导致和父路由一样，直接注册会提示路由重复
                if (res.some(v => v.path === item.path)) {
                    res.forEach((v, i) => {
                        if (v.path === item.path) {
                            res[i] = item;
                        }
                    });
                } else {
                    res.push(item);
                }
            });
        } else {
            if (baseUrl !== '') {
                if (tmp.path !== '') {
                    tmp.path = `${baseUrl}/${tmp.path}`;
                } else {
                    tmp.path = baseUrl;
                }
            }

            // 处理面包屑导航
            const tmpBreadcrumb = deepClone(breadcrumb);
            if (tmp.meta.breadcrumb !== false) {
                tmpBreadcrumb.push({
                    path: tmp.path,
                    title: tmp.meta.title
                });
            }
            tmp.meta.breadcrumbNeste = tmpBreadcrumb;
            res.push(tmp);
        }
    });
    return res;
}

export default {
    namespaced: true,

    state: {
        isGenerate: false,

        routes: [], 

        headerActived: 0
    },

    mutations: {
        invalidRoutes(state) {
            state.isGenerate = false;
            state.headerActived = 0;
        },

        setRoutes(state, routes) {
            state.isGenerate = true;
            const newRoutes = deepClone(routes);
            state.routes = newRoutes.filter(item => {
                return item.children.length !== 0;
            });
        },

        // 根据路由判断属于哪个头部导航
        setHeaderActived(state, path) {
            state.routes.map((item, index) => {
                if (
                    item.children && item.children.some(r => {
                        return path.indexOf(r.path + '/') === 0 || path === r.path;
                    })
                ) {
                    state.headerActived = index;
                }
            });
        },

        // 切换头部导航
        switchHeaderActived(state, index) {
            state.headerActived = index;
        }
    },

    actions: {
        // 根据权限动态生成路由
        generateRoutes({ dispatch, commit }, data) {            
            const { asyncRoutes, currentPath } = data;

            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async resolve => {
                // 获取用户菜单
                const userRoutes = await dispatch('user/getPermission', null, { root: true });
                console.log('接口返回的路由数据: ', userRoutes);

                // 调用递归函数对比用户路由
                const accessedRoutes = filterAsyncRoutes(userRoutes, asyncRoutes);
                console.log('接口路由和本地路由对比的结果: ', accessedRoutes);

                // 写入vuex
                commit('setRoutes', accessedRoutes);
                commit('setHeaderActived', currentPath);

                const routes = [];
                accessedRoutes.map(item => {
                    routes.push(...item.children);
                });

                routes.map(item => {
                    if (item.children) {
                        item.children = flatAsyncRoutes(item.children, [{
                            path: item.path,
                            title: item.meta.title
                        }]);
                    }
                });
                
                resolve(routes);
            });
        }
    },

    getters: {
        sidebarRoutes: state => {
            return state.routes.length > 0 ? state.routes[state.headerActived].children : [];
        }
    }
};
