<template>
    <Layout title="意见建议">  
        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-search"></i>
                <span class="title">筛选条件</span>
            </template>
            <el-form :model="queryForm" inline ref="queryFormRef">
                <el-form-item label="关键字" prop="keywords">
                    <el-input v-model="queryForm.keywords" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="handleQuery">确定</el-button>
                    <el-button type="default" icon="el-icon-refresh-left" @click="handleReset('queryFormRef')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="custom-card__outer">
            <template #header>
                <i class="el-icon-s-grid"></i>
                <span class="title">数据列表</span>
            </template>

            <el-table :data="tableData" v-loading="loading">
                <el-table-column type="index" width="50"></el-table-column>               
                <el-table-column prop="name" label="姓名" width="200"></el-table-column> 
                <el-table-column prop="mobile" label="手机号" width="200"></el-table-column>  
                <el-table-column prop="content" label="留言内容"></el-table-column> 
                <el-table-column prop="createTime" label="留言时间" width="200" align="center"></el-table-column>         
            </el-table>

            <div class="el-card__footer">
                <div></div>
                <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :page-count="totalPage"
                    @current-change="handleCurrentPageChange"
                ></el-pagination> 
            </div>
        </el-card>
    </Layout>
</template>

<script>
export default {
    data() {
        return {
            queryForm: {
                keywords: ''
            },

            loading: false,

            tableData: [],
            totalPage: 1
        };
    },

    methods: {
        // 获取数据
        getData() {
            this.tableData = [{
                mobile: '1380000000',
                name: '张三',
                content: ' 安全生产方面:定制化培训,评估评审,隐患排查;   职业健康方面:管家服务,三同时,检测评价,隐患排查,评估评审;   用户留言:您的留言已发送,我们将快与您取得联系您的留言已发送,我们将快与您取得联系您的留言已发送,我们将快与您取得联系您的留言已发送,我们将快与您取得联系您的留言已发送,我们将快与您取得联系您的留言已发送,; ',
                createTime: '2022-12-12'
            }];
        },

        // 搜索
        handleQuery() {

        },

        // 重置
        handleReset(formName) {
            console.log(formName);
            this.$refs[formName].resetFields();
        },

        // 分页
        handleCurrentPageChange(e) {
            this.getData({ pager: e });
        }
    },

    mounted() {
        this.getData();
    }
};
</script>
