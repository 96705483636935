<template>
    <div />
</template>

<script>
export default {
    name: 'reload',
    
    mounted() {
        this.$router.go(-1);
    }
};
</script>
