/**
 * 公共方法定义
 */

/**
 * 生成uuid
 * @param {*} path 
 * @returns 
 */
export function getUUID () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16);
    });
}

/**
 * 深度克隆
 * @param {Object} target 
 * @returns 
 */
export const deepClone = (target) => {
    // 定义一个变量
    let result;

    // 如果当前需要深拷贝的是一个对象的话
    if (typeof target === 'object') {
        // 如果是一个数组的话
        if (Array.isArray(target)) {
            result = []; // 将result赋值为一个数组，并且执行遍历
            for (const i in target) {
                // 递归克隆数组中的每一项
                result.push(deepClone(target[i]));
            }
            // 判断如果当前的值是null的话；直接赋值为null
        } else if (target === null) {
            result = null;
            // 判断如果当前的值是一个RegExp对象的话，直接赋值
        } else if (target.constructor === RegExp) {
            result = target;
        } else {
            // 否则是普通对象，直接for in循环，递归赋值对象的所有值
            result = {};
            for (const i in target) {
                result[i] = deepClone(target[i]);
            }
        }
        // 如果不是对象的话，就是基本数据类型，那么直接赋值
    } else {
        result = target;
    }
    // 返回最终结果
    return result;
};

/**
 * 该方法用于将有父子关系的数组转换成树形结构的数组
 * 接收一个具有父子关系的数组作为参数
 * 返回一个树形结构的数组
 */
export const translateListToTree = (data) => {
    // console.log('所有菜单原始数据：', data);
    // 查找根节点(没有父节点的数据)
    const parents = data.filter(item => item.parentId === 0);
    // console.log('根菜单: ', parents);

    // 查找子节点(有父节点的数据)
    const children = data.filter(item => item.parentId);
    // console.log('有父节点的数据: ', children);

    // 定义转换方法的具体实现
    const translator = (parents, children) => {
        // 遍历父节点数据
        parents.forEach((parent) => {
            // 遍历子节点数据
            children.forEach((current, index) => {
                // 此时找到父节点对应的一个子节点
                if (current.parentId === parent.menuId) {
                    // 对子节点数据进行深复制，这里只支持部分类型的数据深复制，对深复制不了解的童靴可以先去了解下深复制
                    const temp = JSON.parse(JSON.stringify(children));
                    // 让当前子节点从temp中移除，temp作为新的子节点数据，这里是为了让递归时，子节点的遍历次数更少，如果父子关系的层级越多，越有利
                    temp.splice(index, 1);
                    // 让当前子节点作为唯一的父节点，去递归查找其对应的子节点
                    translator([current], temp);
                    // 把找到子节点放入父节点的children属性中
                    typeof parent.children !== 'undefined' ? parent.children.push(current) : parent.children = [current];
                }
            });
        });
    };

    // 调用转换方法
    translator(parents, children);

    // 返回最终的结果
    return parents;
};

// 日期格式化
export function parseTime(time, pattern) {
    if (arguments.length === 0 || !time) {
        return null;
    }
    const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}';
    let date;
    if (typeof time === 'object') {
        date = time;
    } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time);
        } else if (typeof time === 'string') {
            time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000;
        }
        date = new Date(time);
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    };

    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value];
        }
        if (result.length > 0 && value < 10) {
            value = '0' + value;
        }
        return value || 0;
    });
    return time_str;
}