<template>
    <el-drawer
        title="上传发票"
        :visible.sync="dialogVisible"
        :size="600"
        :append-to-body="true"
        :modal-append-to-body="true"
        :close-on-press-escape="false"
        :wrapperClosable="false"
        class="custom-drawer invoice-dialog"
    >
         <el-form :model="form" ref="formRef">
            <el-form-item>
                <el-alert type="success" title="可以上传 图片 和 PDF 两种格式的发票，二选一，或者两种格式都上传。" :closable="false" />
            </el-form-item>
            <el-form-item label="图片">
                <el-upload
                    class="image-uploader"
                    action="#"
                    :show-file-list="false"
                    :http-request="imageUpload"
                    :before-upload="beforeImageUpload"
                >
                    <img v-if="imagePreview" :src="imagePreview" class="image" />
                    <span class="el-icon" v-else>
                        <i class="el-icon-plus"></i>
                    </span>
                </el-upload>
            </el-form-item> 
            <el-form-item label="PDF">
                <el-upload
                    class="uploader"
                    action
                    :file-list="form.pdf"
                    :before-upload="beforePDFUpload"
                    :http-request="pdfUpload"
                >
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
            </el-form-item> 
        </el-form>

        <div class="el-drawer__footer">
            <el-button @click="handleCancel">取 消</el-button>
            <el-button type="primary" :loading="confirmLoading" :disabled="confirmDisabled" @click="handleConfirm">确 定</el-button>
        </div>
    </el-drawer>
</template>

<script>
import { addInvoiceApi, uploadInvoiceImageApi, uploadInvoicePDFApi } from '../../api/invoice';

export default {
    name: 'MemberModify',

    prop: {
        refreshData: {
            type: Function
        }
    },

    data() {
        return {
            dialogVisible: true,

            form: {
                invoiceId: '',   
                image: '',
                pdf: []
            },

            imagePreview: '',

            confirmLoading: false,
            confirmDisabled: false
        };
    },

    mounted() {
        this.$eventBus.$on('OPEN_INVOICE_DIALOG', data => {
            const { image, imageBase, invoiceId, pdfBase } = data;
            this.form.invoiceId = invoiceId;
            this.form.image = imageBase;
            this.form.pdf = [{ name: pdfBase, url: pdfBase }];
            this.imagePreview = image;

            this.openDialog();
        });
    },

    methods: {
        openDialog() {
            this.dialogVisible = true;
        },

        closeDialog() {
            this.dialogVisible = false;
            this.imagePreview = '';
            this.form = {
                invoiceId: '',   
                image: '',
                pdf: []
            };
        },

        // 图片上传之前
        beforeImageUpload(rawFile) {
            const imgTypes = ['image/jpeg', 'image/png', 'image/jpg'];

            if (!imgTypes.includes(rawFile.type)) {
                // ElMessage.error('Avatar picture must be JPG format!')
                this.$message({
                    type: 'danger',
                    message: '请上传PNG、JPG图片'
                });

                return false;
            }
            return true;
        },

        // 自定义上传图片
        imageUpload(params) {
            this.loadingIns = this.$loading({
                text: '图片上传中...',
                background: 'rgba(0, 0, 0, .2)'
            });

            uploadInvoiceImageApi({ fileName: params.file.name }).then(res => {
                const { UploadUrl, Host, Authorization, LookUrl, BaseLookUrl } = res.data;
                // 获取参数成功后，调用腾讯云接口
                // https://1-1317373106.cos.ap-chengdu.myqcloud.com

                fetch(UploadUrl, {
                    method: 'PUT',
                    body: params.file,
                    headers: {
                        'Host': Host,
                        'Authorization': Authorization
                    }
                }).catch(res => {
                    this.$message({
                        type: 'success',
                        message: `上传失败`
                    });
                    this.loadingIns.close();
                }).then(res => {
                    this.imagePreview = LookUrl;
                    this.form.image = BaseLookUrl;
                    this.loadingIns.close();
                });
            }).catch(e => {
                this.loadingIns.close();
            });
        },

        // pdf上传之前
        beforePDFUpload(rawFile) {
            // console.log('rawFile: ', rawFile);
            const imgTypes = ['application/pdf'];

            if (!imgTypes.includes(rawFile.type)) {
                // ElMessage.error('Avatar picture must be JPG format!')
                this.$message({
                    type: 'danger',
                    message: '请上传pdf格式的文件'
                });

                return false;
            }
            return true;
        },

        // 自定义上传pdf
        pdfUpload(params) {
            this.loadingIns = this.$loading({
                text: '文件上传中...',
                background: 'rgba(0, 0, 0, .2)'
            });

            uploadInvoicePDFApi({ fileName: params.file.name }).then(res => {
                // 获取参数成功后，调用腾讯云接口
                const { UploadUrl, Host, Authorization, BaseLookUrl } = res.data;

                fetch(UploadUrl, {
                    method: 'PUT',
                    body: params.file,
                    headers: {
                        'Host': Host,
                        'Authorization': Authorization
                    }
                }).catch(res => {
                    return res.json();
                }).then(res => {
                    this.form.pdf = [{ name: params.file.name, url: BaseLookUrl }];
                    this.loadingIns.close();
                });
            }).catch(e => {
                this.loadingIns.close();
            });
        },

        // 取消
        handleCancel() {
            this.closeDialog();
        },

        // 确定
        handleConfirm() {
            this.confirmLoading = true;
            this.confirmDisabled = true;

            const { invoiceId, image, pdf } = this.form;
            const requestData = {
                invoiceId,
                image: encodeURI(image),
                pdf: pdf.length ? pdf[0].url : ''
            };

            addInvoiceApi(requestData).then(res => {
                this.confirmLoading = false;
                this.confirmDisabled = false;

                this.$message({
                    type: 'success',
                    message: '操作成功！'
                });
                
                this.closeDialog();
                this.$emit('refreshData');
            }).catch(() => {
                this.confirmLoading = false;
                this.confirmDisabled = false;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.image-uploader {
    ::v-deep .el-upload {
        border: 1px dashed #8c939d;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: all .3s;
        &:hover {
            border-color: #66b1ff;
        }
    }

    .el-icon {
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
    }
}
</style>
